import React, { useEffect, useState } from 'react';

import { Steps, useModelContext } from '../../useContext';

import { EventType, eventTypeStrings, OriginType, originTypeStrings } from 'domain/model.types';

import { AlgorithmClass } from 'services/intervention/intervention.types';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';

import CFSelect, { Option } from 'components/CFSelect';
import CFInput, { CFInputType } from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';

import './censoring-target.scss';

const CensoringTarget = () => {
  const { definition, setTarget, updateReadiness } = useModelContext();

  const [selectedEventType, setSelectedEventType] = useState<EventType>(EventType.Churn);
  const [selectedOriginType, setSelectedOriginType] = useState<OriginType>(OriginType.Activity);
  const [definitionValue, setDefinitionValue] = useState(0);
  const [validationDelta, setValidationDelta] = useState(0);
  const [lookbackTime, setLookbackTime] = useState(0);

  useEffect(() => {
    if (!definition || definition.class !== AlgorithmClass.Censoring) {
      return;
    }

    setTarget({
      event_description: {
        type: selectedEventType,
        definition: definitionValue,
      },
      origin_description: {
        type: selectedOriginType,
        definition: definitionValue,
      },
      look_back_time_in_days: lookbackTime,
      validation_delta: validationDelta,
    });
  }, [selectedEventType, selectedOriginType, lookbackTime, validationDelta, definitionValue]);

  useEffect(
    function updateReady() {
      const allReady = lookbackTime > 0 && validationDelta > 0 && definitionValue > 0;
      updateReadiness(allReady, Steps.Target);
    },
    [lookbackTime, validationDelta, definitionValue]
  );

  return (
    <InterventionSection
      name={Steps.Target}
      title={'Target'}
      subtitle="Select the targets for your model."
      className="censoring-target"
    >
      <CFTitledComponent title={'Definition'}>
        <CFInput
          type={CFInputType.Number}
          defaultValue={definitionValue}
          onChange={(e) => setDefinitionValue(parseInt(e.target.value))}
        />
      </CFTitledComponent>

      <CFTitledComponent title={'Event'}>
        <CFSelect
          options={Object.values(EventType).map((type) => ({
            value: type,
            label: eventTypeStrings[type],
          }))}
          onSelected={(e: Option) => setSelectedEventType(e.value as EventType)}
          isMulti={false}
          value={{ value: selectedEventType, label: eventTypeStrings[selectedEventType] }}
        />
      </CFTitledComponent>

      <CFTitledComponent title={'Origin'}>
        <CFSelect
          isMulti={false}
          value={{
            value: selectedOriginType,
            label: originTypeStrings[selectedOriginType],
          }}
          onSelected={(e: Option) => setSelectedOriginType(e.value as OriginType)}
          options={Object.values(OriginType).map((type) => ({
            value: type,
            label: originTypeStrings[type],
          }))}
        />
      </CFTitledComponent>

      <CFTitledComponent title={'Lookback time (in days)'}>
        <CFInput
          type={CFInputType.Number}
          defaultValue={0}
          onChange={(e) => setLookbackTime(parseInt(e.target.value))}
        />
      </CFTitledComponent>
      <CFTitledComponent title={'Validation delta'}>
        <CFInput
          type={CFInputType.Number}
          defaultValue={0}
          onChange={(e) => setValidationDelta(parseInt(e.target.value))}
        />
      </CFTitledComponent>
    </InterventionSection>
  );
};

export default CensoringTarget;
