import { CFRoutes } from 'routes';

export enum Tabs {
  Explorer = 'explorer',
  BackfillStatus = 'backfill status',
}

export const allowedTabs = () => {
  const tabs = [Tabs.Explorer, Tabs.BackfillStatus];
  return tabs;
};

export const tabNameToCode = (tabName: string) => tabName.split(' ').join('-');

export const monitoringByTab: Record<string, CFRoutes> = {
  [tabNameToCode(Tabs.Explorer)]: CFRoutes.traits_explorer,
  [tabNameToCode(Tabs.BackfillStatus)]: CFRoutes.traits_backfill_status,
};
