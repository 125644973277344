import {
  AssistantMessage,
  AssistantMessageType,
  ChatAPIResponse,
  ChatWithValidationReq,
  ModelType,
  QuestionType,
  SelectedOptionAPI,
  Substitutions,
} from './assistant.types';
import { today, toString as dateToString } from 'helpers/dates';

const assistantMessageToChatWithValidationReq = (
  message: string | AssistantMessage,
  parentId: number | undefined,
  type: QuestionType,
  model: ModelType
) => {
  const transformSelectedOption = (option: Substitutions): SelectedOptionAPI => ({
    ptr: option.ptr,
    search_key: option.key,
    selected: option.options[0],
  });

  let messageAPI: ChatWithValidationReq;

  if (typeof message === 'string') {
    messageAPI = {
      question: {
        type,
        parent_msg_id: parentId,
        msg: message,
        ctx: {
          today: dateToString(today()),
        },
      },
      model_type: model,
    };
  } else {
    messageAPI = {
      question: {
        type,
        parent_msg_id: message.parentId,
        msg: message.text,
        ctx: {
          today: dateToString(today()),
        },
      },
      model_type: model,
      payload: message ? message.payload : undefined,
      selected_options: message ? message.substitutions?.map(transformSelectedOption) : undefined,
      model_msgs: message ? message.meta?.modelData : undefined,
    };
  }
  return messageAPI;
};

const parseDatabaseTable = (text: string): Record<string, string>[] | undefined => {
  const rows = text.split('\n');

  if (!rows[0]) {
    return undefined;
  }
  const columns = rows[0].split('|').map((item) => item.trim());

  const rowValues = rows.slice(2);

  if (!rowValues.length) {
    return undefined;
  }

  if (!rowValues.length) {
    return [columns.reduce((acc, col) => ({ ...acc, [col]: '' }), {})];
  }

  const values = rowValues.map((row) =>
    row.split('|').reduce((acc, item, i) => ({ ...acc, [columns[i]]: item?.trim() }), {})
  );

  return values;
};

const chatWithValidationRespToAssistantMessage = (validationResp: ChatAPIResponse) => {
  const {
    msg: { id, tid, content, payload },
  } = { ...validationResp };
  const needsSubstitution = validationResp.msg.options !== undefined;

  const response: AssistantMessage = {
    id,
    tid,
    parentId: validationResp.msg.parent_id,
    mode: validationResp.msg.type,
    questionId: validationResp.question_id,
    type: needsSubstitution ? AssistantMessageType.ValidationRequest : AssistantMessageType.Answer,
    text: content.startsWith('|') ? '' : content,
    structuredText: parseDatabaseTable(content),
    timestamp: dateToString(new Date()),
    payload,
    error: payload && payload.err !== '',
    meta: {
      modelData: validationResp.msg.model_msgs,
    },
  };

  if (needsSubstitution) {
    response.substitutions = validationResp.msg.options.map((option) => ({
      ptr: option.ptr,
      key: option.search_key,
      options: option.search_results,
    }));
  }

  return response;
};

export { assistantMessageToChatWithValidationReq, chatWithValidationRespToAssistantMessage, parseDatabaseTable };
