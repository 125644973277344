import { AssistantMessage, AssistantMessageType, ModelType, QuestionType, Substitutions } from './assistant.types';
import { CFAssistantRepository } from './assistant.repo';
import { ERROR_MESSAGE } from './constants';

export default class ThreadLegacy {
  private initPromise: Promise<AssistantMessage[]> | null;
  private PAGE_SIZE = 10;
  private threadId = 0;
  private lastID = 0;
  private messages: AssistantMessage[] = [];
  private repository: CFAssistantRepository;
  private waitingResponse = false;

  constructor(repo: CFAssistantRepository) {
    this.repository = repo;
    this.initPromise = null;
  }

  async init() {
    try {
      this.initPromise = this.repository.getHistory(this.lastID, this.PAGE_SIZE, 0);

      const history = await this.initPromise;

      this.lastID = history[history.length - 1]?.id || 0;
      this.threadId = history[history.length - 1]?.tid || 0;

      this.messages = [...history];
    } catch {
      console.log('error getting history first time');
    }
  }

  async history(): Promise<[boolean, AssistantMessage[]]> {
    try {
      await this.initPromise;
    } catch {
      return [false, this.messages];
    }

    const more = this.messages.length >= this.PAGE_SIZE;

    return [more, this.messages];
  }

  async askWithValidation(
    model: ModelType,
    type: QuestionType,
    text: string,
    parentId: number | undefined,
    handleProgress: (text: string) => void
  ): Promise<AssistantMessage[]> {
    const steps: string[] = [];
    const question: AssistantMessage = {
      id: Math.random() * 1000,
      tid: this.threadId,
      parentId: parentId,
      text,
      timestamp: '',
      type: AssistantMessageType.Question,
      error: false,
      mode: type,
    };

    // validation requests are not allowed in the middle of history. If it happens
    // means that the user has made a new question without picking up
    // the correct question in a previous one
    this.messages = this.messages.filter((message) => message.type !== AssistantMessageType.ValidationRequest);

    this.messages = [question, ...this.messages];

    const handleMessageStep = (text: string) => {
      steps.push(text);
      handleProgress(text);
    };

    try {
      this.waitingResponse = true;
      const responseMessage = await this.repository.questionWithValidation(
        model,
        type,
        text,
        parentId,
        handleMessageStep
      );

      if (responseMessage.error) {
        responseMessage.steps = steps;
        responseMessage.type = AssistantMessageType.Error;
      }
      this.messages = [responseMessage, ...this.messages];
      this.messages[1].id = responseMessage.questionId || 0;
      this.messages[1].timestamp = responseMessage.timestamp;
      this.threadId = responseMessage.tid;
    } catch (err: any) {
      const errorResponse: AssistantMessage = {
        id: Math.random() * 1000,
        tid: this.threadId,
        parentId: -1,
        steps,
        text: ERROR_MESSAGE,
        timestamp: '',
        type: AssistantMessageType.Error,
        error: true,
        reason: err.message,
      };
      this.waitingResponse = false;

      this.messages = [errorResponse, ...this.messages];
    }

    this.waitingResponse = false;

    return this.messages;
  }

  async disambiguate(
    model: ModelType,
    type: QuestionType,
    message: AssistantMessage,
    key: string,
    option: string
  ): Promise<AssistantMessage[]> {
    if (this.waitingResponse) {
      return this.messages;
    }

    try {
      const disambiguationMessage = { ...message };
      disambiguationMessage.substitutions = [
        {
          ptr: (message.substitutions as Substitutions[])[0].ptr,
          key,
          options: [option],
        },
      ];

      const response = await this.repository.questionWithValidation(model, type, disambiguationMessage, undefined);

      // remove previous message with options
      this.messages = this.messages.filter((item) => item.id !== message.id);

      this.messages = [response, ...this.messages];
    } catch (err: any) {
      const errorResponse: AssistantMessage = {
        id: Math.random() * 1000,
        tid: this.threadId,
        parentId: -1,
        text: ERROR_MESSAGE,
        timestamp: '',
        type: AssistantMessageType.Error,
        error: true,
        reason: err.message,
      };

      this.messages = [errorResponse, ...this.messages];
    }

    return this.messages;
  }

  async loadMore(): Promise<[boolean, AssistantMessage[]]> {
    const history = await this.repository.getHistory(this.lastID, this.PAGE_SIZE, this.threadId);

    this.lastID = history[history.length - 1]?.id || this.lastID;

    this.messages = [...this.messages, ...history];

    const more = history.length >= this.PAGE_SIZE;

    return [more, history];
  }

  async reset() {
    await this.repository.reset(this.threadId);
    this.messages = [];
  }

  async getMessage(id: number): Promise<AssistantMessage | undefined> {
    if (id === undefined) {
      return undefined;
    }

    const message = this.messages.find((message) => message.id === id);

    return message;
  }
}
