import React from 'react';

import { AlgorithmClass } from 'domain/model.types';

import useURLModel from 'views/model/hooks/useURLModel';

import ModelCensoringDetails from './ClassCensoring/model-censoring-details';
import ModelRecommenderDetails from './ClassRecommender/model-recommender-details';
import ModelEmbeddingDetails from './ClassEmbedding/model-embedding-details';
import ModelBanditDefinition from './ClassBandit';

import CFSpinner from 'components/CFSpinner';

import { Tabs } from '../../tabs';
import ModelDetailLayout from '../layout';

import colors from 'common.scss';

import './model-definition.scss';
import ClassRestlessBandit from './ClassRestlessbandit';

const ModelDefinition = () => {
  const model = useURLModel();

  return (
    <ModelDetailLayout className="model-monitor-definition" tab={Tabs.Definition}>
      {!model && (
        <div>
          <CFSpinner size={70} color={colors.cfCyan} stroke={4} />
        </div>
      )}
      <div className="model-definition-container">
        {model && model.definition.algo_spec.class_name === AlgorithmClass.Censoring && (
          <ModelCensoringDetails model={model} />
        )}

        {model && model.definition.algo_spec.class_name === AlgorithmClass.Recommender && (
          <ModelRecommenderDetails model={model} />
        )}

        {model && model.definition.algo_spec.class_name === AlgorithmClass.Embedding && (
          <ModelEmbeddingDetails model={model} />
        )}

        {model && model.definition.algo_spec.class_name === AlgorithmClass.RestlessBandit && (
          <ClassRestlessBandit model={model} />
        )}

        {model &&
          (model.definition.algo_spec.class_name === AlgorithmClass.Bandit ||
            model.definition.algo_spec.class_name === AlgorithmClass.BanditV2) && (
            <ModelBanditDefinition model={model} />
          )}
      </div>
    </ModelDetailLayout>
  );
};

export default ModelDefinition;
