import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CFRoutes, getCurrentSection, Sections } from 'routes';

import Logo from 'assets/logo.png';

import { CFRole } from 'domain/general.types';

import { AuthAction, isAllowedTo } from 'services/authorization.service';
import { getOrganization, getUserInfo } from 'services/session/session.service';

import CFButton from 'components/buttons/CFButton';
import OrgProjPicker from 'components/OrgProjectPicker';
import CFInput from 'components/CFInput';

import UserMenu from 'connected-components/CFHeader/UserMenu';
import CFLink from 'connected-components/CFLink';
import SyncStatus from 'connected-components/SyncStatus';

import { ProjectDefinition } from 'types';

import useIncognito from './hooks/useIncognito';
import { useClickOutside } from 'hooks';

import './cf-header.scss';
import ProtectedElement from 'connected-components/ProtectedElement';

interface Props {
  currentProject?: ProjectDefinition;
  handleOrgProjChange: (orgId: number, projId: number) => void;
  handleLogout: () => void;
  completed?: boolean;
}

const DEFAULT_PROJECT_NAME = 'My project';

const headerItems = [
  { name: Sections.Analytics, to: CFRoutes.analytics, checkAllow: () => true },
  {
    name: Sections.Segmentation,
    to: CFRoutes.segmentation_cohort,
    checkAllow: () => true,
  },

  {
    name: Sections.Model,
    to: CFRoutes.model,
    checkAllow: () => isAllowedTo(AuthAction.SeeModel),
  },
  {
    name: Sections.Intervention,
    to: CFRoutes.intervention,
    checkAllow: () => isAllowedTo(AuthAction.SeeIntervention),
  },
  {
    name: Sections.Assistant,
    to: CFRoutes.assistant,
    checkAllow: () => isAllowedTo(AuthAction.SeeAssistant),
  },
];

const CFHeader = ({ currentProject, completed = true, handleOrgProjChange, handleLogout }: Props) => {
  const [showOrgProjSwitcher, setShowOrgProjSwitcher] = useState(false);
  const [orgProjSwitcherPosition, setOrgProjSwitcherPosition] = useState([0, 0]);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [userMenuPosition, setUserMenuPosition] = useState([0, 0]);
  const [editing, setEditing] = useState(false);
  const [projectName, setProjectName] = useState('');

  const projectNameRef = useRef<HTMLInputElement>(null);
  const incognito = useIncognito();

  useEffect(() => {
    if (incognito) {
      setProjectName(DEFAULT_PROJECT_NAME);
      return;
    }

    setProjectName(currentProject?.name || '<no project>');
  }, [currentProject, incognito]);

  useClickOutside(projectNameRef, () => {
    setProjectName(projectNameRef.current?.value || '');

    setEditing(false);
  });

  const handleNewProjectName = useCallback(() => {
    if (!projectNameRef.current) {
      return;
    }

    setProjectName(projectNameRef.current.value);
    setEditing(false);
  }, []);

  const handleEditProjectName = useCallback(() => {
    setEditing(true);
  }, []);

  const toggleOrgPickerSwitch = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      if (incognito) {
        return;
      }

      setShowOrgProjSwitcher((showOrgProjSwitcher) => !showOrgProjSwitcher);
      setOrgProjSwitcherPosition([evt.currentTarget.offsetLeft, evt.currentTarget.offsetTop]);
    },
    [incognito]
  );

  const toggleUserMenu = (evt: React.MouseEvent<HTMLElement>) => {
    setShowUserMenu((showUserMenu) => !showUserMenu);
    setUserMenuPosition([evt.currentTarget.offsetLeft, evt.currentTarget.offsetTop]);
  };

  const userMenuOptions = useMemo(() => {
    let commonRoutes = [
      { to: CFRoutes.settings, label: 'Settings' },
      { to: CFRoutes.integration, label: 'Integration' },
    ];

    if (isAllowedTo(AuthAction.ViewAdmin) && completed) {
      commonRoutes = [...commonRoutes, { to: CFRoutes.upload, label: 'Upload' }];
    }

    if (isAllowedTo(AuthAction.ViewAdmin) && completed) {
      commonRoutes = [...commonRoutes, { to: CFRoutes.admin, label: 'Admin' }];
    }

    if (isAllowedTo(AuthAction.Backfill) && completed) {
      commonRoutes = [...commonRoutes, { to: CFRoutes.traits_explorer, label: 'Trait definitions' }];
    }

    return commonRoutes;
  }, [completed]);

  const projectTitle = (
    <div className="project-title">
      {incognito ? <span onClick={handleEditProjectName}>{projectName}</span> : <span>{projectName}</span>}
      {currentProject && <div className="sub-text">#{currentProject?.id}</div>}
    </div>
  );

  return (
    <React.Fragment>
      {showOrgProjSwitcher && (
        <OrgProjPicker
          orgprojs={getUserInfo().available_orgprojs}
          currentOrg={parseInt(getOrganization() || '')}
          top={orgProjSwitcherPosition[1]}
          left={orgProjSwitcherPosition[0]}
          cancel={() => setShowOrgProjSwitcher(false)}
          onChange={handleOrgProjChange}
        />
      )}

      {showUserMenu && (
        <UserMenu
          user={{ fullname: getUserInfo().user.name, username: getUserInfo().user.username }}
          options={userMenuOptions}
          top={userMenuPosition[1]}
          left={userMenuPosition[0]}
          logout={handleLogout}
          cancel={() => setShowUserMenu(false)}
        />
      )}

      <div className="cf-header" id="__primerPortalRoot__">
        <div className="logo">
          <CFLink className="header-link" to={'/'}>
            <img className="logo-img" src={Logo} alt="Causal Foundry Logo" />
          </CFLink>
        </div>

        {completed && (
          <React.Fragment>
            {headerItems.map(({ name, to, checkAllow }) => {
              if (!checkAllow()) {
                return '';
              }

              return (
                <div
                  key={`header-item-${name}`}
                  className={getCurrentSection() === name ? 'header-item selected' : 'header-item'}
                >
                  <CFLink className="header-link" to={to}>
                    {name}
                  </CFLink>
                </div>
              );
            })}
          </React.Fragment>
        )}
        <div className="header-item full" />

        {completed && (
          <div className="header-item org">
            {editing ? (
              <CFInput ref={projectNameRef} onAccepted={handleNewProjectName} defaultValue={projectName} />
            ) : (
              <CFButton value={projectTitle} role={CFRole.Neutral} onClick={toggleOrgPickerSwitch} />
            )}
          </div>
        )}

        <div className="header-item" onClick={toggleUserMenu}>
          <div className="profile-avatar">{getUserInfo().user.name?.charAt(0)}</div>
        </div>

        <ProtectedElement authAction={AuthAction.SynchronizeView}>
          <div className="header-item">
            <SyncStatus />
          </div>
        </ProtectedElement>
      </div>
    </React.Fragment>
  );
};

export default CFHeader;
