import React, { ReactNode, useCallback } from 'react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import classNames from 'classnames';

import { Payload } from 'services/assistant/assistant.types';
import { FilterAPI } from 'services/cohort/cohort.types.api';

import { ToastType } from 'components/CFToast/types';
import CFButton from 'components/buttons/CFButton';

import CFPayloadErrorBoundary from './payloadErrorBoundary';

import { CFRole } from 'domain/general.types';
import { useToast } from 'hooks';

import './payload-viewer.scss';

interface Props {
  payload: Payload;
}

const PADDING = 10;

// eslint-disable-next-line
const prettyPrintPayloadLegacy = (payload: Record<string, any> | string, level = 0): ReactNode => {
  if (!payload) {
    return <div>{payload}</div>;
  }

  if (typeof payload === 'string') {
    return <div>{payload}</div>;
  }

  if (Array.isArray(payload)) {
    return payload.toString();
  }

  // filters are printed first
  const keys = Object.keys(payload).sort((a, b) => (b === 'filters' ? 1 : -1));

  return keys.map((key: string) => {
    if (key === 'filters') {
      return (
        <div key={`${key}-${level}`} style={{ paddingLeft: level * PADDING }}>
          <div className="args__title">Filters</div>
          {(payload[key] as FilterAPI[])?.map((filter) => (
            <div key={filter.ptr || filter.identifier} className="args__value">
              {filter.ptr || filter.identifier} {filter.op} {filter.val}
            </div>
          ))}
        </div>
      );
    }

    if (Array.isArray(payload[key])) {
      return (
        <div key={`${key}-${level}`} style={{ paddingLeft: level * PADDING }}>
          <div className="args__title">{key}</div>
          <div className="args__value"> {payload[key].toString()} </div>
        </div>
      );
    } else if (typeof payload[key] === 'object') {
      return [
        <div key={`object-key-${key}`} className="args__title">
          {key}
        </div>,
        prettyPrintPayloadLegacy(payload[key], level + 1),
      ];
    } else {
      return (
        <div key={`${key}-${level}`} style={{ paddingLeft: level * PADDING }}>
          <div className="args__title">{key}</div>
          <div className="args__value"> {payload[key].toString()} </div>
        </div>
      );
    }
  });
};

interface FieldRendererProps {
  name: keyof Payload;
  payload: Payload;
}

const FieldRenderer = ({ name, payload }: FieldRendererProps) => {
  let value;

  const formattedName = (name as string).split('_').join(' ');

  if (payload[name] === undefined || payload[name] === null) {
    value = '';
  } else if ((payload[name] as any).join !== undefined) {
    value = (payload[name] as string[]).join(', ');
  } else if (typeof payload[name] === 'boolean') {
    value = `${payload[name]}`;
  } else {
    value = ((payload[name] || '') as string).split('\n').map((item) => <div key={Math.random()}>{item}</div>);
  }

  return (
    <div>
      <span className="content__name">{formattedName}: </span>
      {value}
    </div>
  );
};

const PayloadViewer = ({ payload }: Props) => {
  const { addToast } = useToast();

  const handleCopy = useCallback(() => {
    navigator.clipboard
      .writeText(payload.query)
      .then(() => {
        addToast('Query copied correctly', ToastType.SUCCESS);
      })
      .catch((err) => {
        addToast('Impossible to copy to clipboard', ToastType.ERROR);

        console.error('Error al copiar el texto: ', err);
      });
  }, [payload]);

  const forbiddenFields = ['query', 'ref_chunks'];
  return (
    <div className={classNames('payload-viewer', { single: true })}>
      <CFButton onClick={handleCopy} value="" iconName={faCopy} role={CFRole.OnlyIcon} />
      <CFPayloadErrorBoundary sourceData={payload}>
        <div className="content">
          {payload.query && (
            <>
              <pre> {payload.query} </pre>
            </>
          )}

          {Object.keys(payload)
            .filter((item) => !forbiddenFields.includes(item))
            .map((field) => (
              <FieldRenderer key={field} name={field as keyof Payload} payload={payload} />
            ))}
        </div>
      </CFPayloadErrorBoundary>
    </div>
  );
};

export default PayloadViewer;
