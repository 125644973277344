import React, { useEffect, useMemo, forwardRef, useImperativeHandle, useState, useCallback } from 'react';
import classNames from 'classnames';
import debounce from 'debounce';

import { toString as dateToString, toString } from 'helpers/dates';

import { useServicesContext } from 'hooks/useServicesContext';

import { Trait, TraitCategory, TraitDataType } from 'domain/traits.types';
import { DataType, Operators } from 'domain/general.types';
import { AppModel } from 'domain/model.types';

import { FilterAPI } from 'services/cohort/cohort.types.api';
import { getUniqueValuesForTrait } from 'services/traits/trait.service';
import Filter from 'services/cohort/domain/Filter';
import { AlgorithmClass } from 'services/intervention/intervention.types';

import TraitExplorerModal from 'connected-components/traits/TraitExplorer';

import CFInput from 'components/CFInput';
import { SelectableItem } from 'components/CFSelectLegacy';
import CFSelect, { Option } from 'components/CFSelect';
import TagInputContainer from 'components/CFSelect/common/TagInputContainer';
import TraitItem from 'components/CFSelect/common/TraitItem';
import TraitInputContainer from 'components/CFSelect/common/TraitInputContainer';
import DatetimePicker from 'components/DateTime/DatetimePicker';

import { getOperators } from './operators';

import {
  areEqual,
  createTraitCode,
  getDisplayName,
  getIdentifier,
  getTraitCategory,
  getTraitName,
  isNumeric,
  isTimestamp as traitIsTimestamp,
} from 'services/traits/helpers.traits';

import './filter-builder.scss';

interface TraitWithModels extends Trait {
  selectable: boolean;
}

interface Props {
  traits: Trait[];
  defaultTrait?: Trait;
  defaultOperator?: Operators;
  defaultValue?: string;
  onFilterChanged: () => void;
  disabled?: boolean;
  allowAllModels?: boolean; // false -> use only those model linked to cohort
}

const NULL_VALUE = 'NULL';

const FilterBuilder = forwardRef<Filter, Props>(function FilterBuilder(
  {
    traits,
    defaultTrait = undefined,
    defaultOperator = Operators.Equal,
    defaultValue = undefined,
    onFilterChanged,
    disabled = false,
    allowAllModels,
  }: Props,
  ref
) {
  const { traitSessionService: traitService, modelService } = useServicesContext();

  const [selectedTrait, setSelectedTrait] = useState<Trait>(defaultTrait || traits[0]);
  const [traitWithModels, setTraitWithModels] = useState<TraitWithModels[]>([]);
  const [uniqueValues, setUniqueValues] = useState<string[]>([]);
  const [availableModels, setAvailableModels] = useState<AppModel[]>([]);
  const [showTraitSelector, setShowTraitSelector] = useState(false);
  const [model, setModel] = useState<AppModel>();

  const toSelectableItem = (value: string) => ({ label: value, value });

  let currentListOfOperators: SelectableItem[];
  if (selectedTrait) {
    currentListOfOperators = getOperators(selectedTrait?.addr.dtype as DataType).map(toSelectableItem);
  } else {
    currentListOfOperators = [];
  }

  const [operator, setOperator] = useState<Operators>(defaultOperator);

  const [value, setValue] = useState<string>(`${defaultValue === undefined ? '' : defaultValue}`);

  const [traitSearch, setTraitSearch] = useState('');
  const [valueSearch, setValueSearch] = useState('');

  useImperativeHandle(ref, () => {
    let formattedValue;
    // TODO: encapsulate component to multiple IDs. Here just ask each components about its values
    if (allowMultipleSelection) {
      formattedValue = (value.trim() || '').split(',').map((item) => item.trim());
    } else {
      formattedValue = isNumericType ? parseFloat(value) : value;
    }

    const filter = new Filter({} as FilterAPI);

    filter.ptr = selectedTrait.addr.ptr;
    filter.op = operator;
    filter.val = formattedValue;
    filter.modelId = model?.definition.id;

    return filter;
  });

  const debouncedOnFilterChanged = useCallback(debounce(onFilterChanged, 500), []);

  const isNumericType = useMemo(() => {
    return isNumeric(selectedTrait);
  }, [selectedTrait]);

  const isTimestamp = useMemo(() => {
    return traitIsTimestamp(selectedTrait);
  }, [selectedTrait]);

  const isRangeUnknown = useMemo(() => {
    if (!selectedTrait) {
      return true;
    }

    return (
      isNumericType ||
      (getTraitCategory(selectedTrait.addr) === TraitCategory.Dynamic &&
        selectedTrait?.addr.dtype === TraitDataType.Varchar)
    );
  }, [isNumericType, selectedTrait]);

  const allowMultipleSelection = useMemo(() => {
    return operator === Operators.IncludedIn || operator === Operators.NotIncludedIn;
  }, [operator]);

  const isFreeInputText = useMemo(() => {
    return (
      getTraitName(selectedTrait) === 'id' || isRangeUnknown || (uniqueValues.length > 10 && allowMultipleSelection)
    );
  }, [allowMultipleSelection, selectedTrait, isRangeUnknown, uniqueValues, operator]);

  const isDropdownInput = useMemo(() => {
    return !isFreeInputText && !isTimestamp && !allowMultipleSelection;
  }, [isFreeInputText, isTimestamp, allowMultipleSelection]);

  const isDropdownMultipleInput = useMemo(() => {
    return !isFreeInputText && !isTimestamp && allowMultipleSelection;
  }, [isFreeInputText, isTimestamp, allowMultipleSelection]);

  useEffect(() => {
    if (!selectedTrait) {
      return;
    }

    if (defaultTrait && areEqual(selectedTrait, defaultTrait)) {
      setValue(`${defaultValue}`);
      setOperator(defaultOperator);
    } else {
      setValue('');
      setOperator(currentListOfOperators[0].value as Operators);
    }
  }, [selectedTrait]);

  useEffect(() => {
    if (!isTimestamp) {
      return;
    }

    // when the just selected trait is a timestamp
    // initialize to current date
    setValue(dateToString(new Date()));
  }, [isTimestamp]);

  useEffect(() => {
    if (!selectedTrait) {
      return;
    }

    // emulate the user introduces a trait
    handleTraitChange({
      label: getDisplayName(selectedTrait),
      value: createTraitCode(selectedTrait),
    });

    setTimeout(() => debouncedOnFilterChanged(), 0);
  }, [selectedTrait]);

  useEffect(() => {
    if (!selectedTrait || allowAllModels) {
      return;
    }

    const modelIDs = traitService.getModels(createTraitCode(selectedTrait));

    Promise.all(modelIDs.map(async (id) => modelService.getById(id))).then((models) => {
      setAvailableModels(models);
    });
  }, [selectedTrait]);

  useEffect(() => {
    if (!selectedTrait || !availableModels || !availableModels.length) {
      return;
    }

    if (getTraitCategory(selectedTrait.addr) !== TraitCategory.MLT) {
      return;
    }

    setModel(availableModels[0]);
  }, [selectedTrait, availableModels]);

  useEffect(() => {
    debouncedOnFilterChanged();
  }, [model, operator, value, selectedTrait]);

  useEffect(() => {
    (async () => {
      if (!allowAllModels) {
        return;
      }

      const models = (await modelService.getModels(0, 1000, AlgorithmClass.Censoring)).data;

      setAvailableModels(models);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const traitsWithModels = traits.map((trait) => {
        // this is only needed for extra_filter. In case of creating a new
        // cohort from scratch we can use any censoring model
        // const models = traitService.getModels(createTraitCode(trait));

        //        const category = getTraitCategory(trait.addr);

        return {
          ...trait,
          selectable: true,

          //category !== TraitCategory.MLT || (category === TraitCategory.MLT && models.length !== 0),
        };
      });

      setTraitWithModels(traitsWithModels);
    })();
  }, [traits]);

  const handleTraitChange = async (item: Option) => {
    const selectedTrait = traits.find((trait) => createTraitCode(trait) === item.value);

    if (!selectedTrait) {
      return;
    }

    setSelectedTrait(selectedTrait);

    // set trait before this request, to avoid blocking the dropdown
    const uniqueValues = await getUniqueValuesForTrait(selectedTrait.addr);

    setUniqueValues(uniqueValues.map((value) => String(value)));
  };

  const handleSelectedModel = useCallback(
    (option: Option) => {
      const model = availableModels.find((model) => model.definition.id === option.value);

      if (!model) {
        return;
      }

      setModel(model);
    },
    [availableModels]
  );

  const handleOperatorChange = (item: Option) => {
    if (item.value === Operators.Is || item.value === Operators.IsNot) {
      setValue(NULL_VALUE);
    }

    setOperator(item.value as Operators);
  };

  const handleValueChange = (item: Option) => {
    if (!allowMultipleSelection) {
      setValue(item.value);

      return;
    }

    const values = value.split(',').filter((_value) => !!_value);
    const newValues = values.filter((_value) => _value !== item.value);

    if (newValues.length === values.length) {
      newValues.push(item.value);
    }

    setValue(newValues.join(','));
  };

  const handleTextValueChange = (input: any) => {
    setValue(input.target.value);
  };

  const handleNewDateValueChange = (date: Date) => {
    setValue(toString(date));
  };

  const handleCancelExplorer = useCallback(() => {
    setShowTraitSelector(false);
  }, []);

  const handleExplorerTraitSelected = useCallback((traits: Trait[]) => {
    setSelectedTrait(traits[0]);
    setShowTraitSelector(false);
  }, []);

  const handleOpenExplorer = useCallback(() => {
    setShowTraitSelector(true);
  }, []);

  if (!selectedTrait) {
    return <div></div>;
  }

  return (
    <>
      {showTraitSelector && (
        <TraitExplorerModal
          traits={traits}
          onCancel={handleCancelExplorer}
          onTraitSelected={handleExplorerTraitSelected}
          isMulti={false}
          defaultSelectedTraits={[selectedTrait]}
        />
      )}
      <div
        className={classNames('filter-builder', {
          extended: getTraitCategory(selectedTrait.addr) === TraitCategory.MLT,
        })}
      >
        <CFSelect
          disabled={disabled}
          value={{
            label: getDisplayName(selectedTrait),
            value: createTraitCode(selectedTrait),
            meta: { trait: selectedTrait },
          }}
          onSelected={handleTraitChange}
          onExpandRequest={handleOpenExplorer}
          options={traitWithModels
            .map((trait) => ({
              label: getDisplayName(trait),
              value: createTraitCode(trait),
              disabled: !trait.selectable,
              meta: { trait },
            }))
            .filter((option) => {
              return !traitSearch.trim() || option.label.toLowerCase().includes(traitSearch.toLowerCase());
            })}
          searchable
          onSearch={setTraitSearch}
          Item={TraitItem}
          InputContainer={TraitInputContainer}
        />

        {availableModels.length !== 0 && getTraitCategory(selectedTrait.addr) === TraitCategory.MLT && (
          <CFSelect
            options={availableModels.map((model) => ({
              label: `${model.definition.id} - ${model.definition.name}`,
              value: model.definition.id,
            }))}
            isMulti={false}
            value={{
              label: model
                ? `${model.definition.id} - ${model.definition.name}`
                : `${availableModels[0].definition.id} - ${availableModels[0].definition.name}`,
              value: model ? model.definition.id : availableModels[0].definition.id,
            }}
            onSelected={handleSelectedModel}
          />
        )}

        <CFSelect
          disabled={disabled}
          key={`operator-${getIdentifier(selectedTrait)}`}
          value={{ label: operator, value: operator }}
          options={currentListOfOperators}
          onSelected={handleOperatorChange}
        />

        {isFreeInputText && (
          <CFInput
            placeholder="value"
            onChange={handleTextValueChange}
            defaultValue={value}
            value={value === NULL_VALUE ? NULL_VALUE : undefined}
          />
        )}

        {isTimestamp && (
          <>
            <DatetimePicker
              initialDate={defaultValue ? new Date(defaultValue) : undefined}
              onChange={handleNewDateValueChange}
              showTime={true}
            />
          </>
        )}

        {isDropdownInput && (
          <CFSelect
            disabled={disabled}
            key={`value-${getIdentifier(selectedTrait)}`}
            value={{ label: value, value }}
            options={uniqueValues.map(toSelectableItem).filter((option) => {
              return !valueSearch.trim() || option.label.toLowerCase().includes(valueSearch.toLowerCase());
            })}
            onSelected={handleValueChange}
            searchable
            onSearch={setValueSearch}
          />
        )}

        {isDropdownMultipleInput && (
          <CFSelect
            disabled={disabled}
            key={`value-${getIdentifier(selectedTrait)}`}
            value={value
              .split(',')
              .filter((value) => !!value)
              .map((value) => ({ label: value, value }))}
            options={uniqueValues.map(toSelectableItem).filter((option) => {
              return !valueSearch.trim() || option.label.toLowerCase().includes(valueSearch.toLowerCase());
            })}
            isMulti
            onSelected={handleValueChange}
            searchable
            onSearch={setValueSearch}
            InputContainer={TagInputContainer}
          />
        )}
      </div>
    </>
  );
});

export default FilterBuilder;
