import React from 'react';
import { GoogleLogin as GoogleLoginButton, GoogleOAuthProvider } from '@react-oauth/google';

interface Props {
  onReady: (token: string) => void;
  onError: () => void;
}

const GoogleLogin = ({ onReady, onError }: Props) => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <GoogleLoginButton
        data-testid="google-login-button"
        logo_alignment="center"
        width="300px"
        onSuccess={(credentialResponse) => {
          onReady(credentialResponse.credential || '');
        }}
        onError={onError}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLogin;
