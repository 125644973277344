export enum Scopes {
  Ingest = 'ingest',
  Nudge = 'nudge',
  SDK = 'sdk',
  Bot = 'bot',
}

export interface ApiKey {
  key: string;
  oid: number;
  pid: number;
  scopes: Scopes[];
  expired_at: string;
  revoked: boolean;
  forever: boolean;
  created_by: string;
  created_at: string;
}
