import { AggLevelPtr } from 'domain/stats.types';
import { Comparable } from 'types';

const LevelMapping = {
  [AggLevelPtr.Day]: 1,
  [AggLevelPtr.Week]: 7,
  [AggLevelPtr.Month]: 30,
};

export class WindowsValue implements Comparable {
  private value: string;
  private _duration: number;

  constructor(value: string) {
    this.value = value;
    const [numericValue, unit] = [value.slice(0, -1), value.slice(-1)];
    this._duration = LevelMapping[unit as AggLevelPtr] * Number(numericValue);
  }

  get duration(): number {
    return this._duration;
  }

  toString() {
    return this.value;
  }

  localeCompare(other: WindowsValue) {
    return this.duration - other.duration;
  }
}
