import { FilterAPI, Ptr } from '../services/cohort/cohort.types.api';
import { CohortAPI } from '../services/cohort/cohort.types';

import { GroupName, InterventionId, MarkovPolicy } from '../services/intervention/intervention.types';
import { ColAddr, TraitSubject } from './traits.types';
import { InputTimeSeriesItem } from './stats.types';
import { TimeRFC3999 } from './general.types';
import { Schedule } from 'services/scheduling/schedulting.types.api';

export type ModelId = string;
export type ApiModelId = number;

export enum ModelStatus {
  Running = 'running',
  Paused = 'paused',
  Terminated = 'terminated',
  Finished = 'finished',
  Pending = 'pending',
}

export enum ModelRunStatus {
  Finished = 'finished',
  Running = 'running',
  Failed = 'failed',
}

export interface AlgoSpec {
  class_name: string;
  algo_name: string;
  params?: Record<string, any>;
  markov?: MarkovPolicy;
}

export interface CohortCond {
  cohort_id: number;
  extra_filters: FilterAPI[];
}

export interface SplitRatio {
  train: number;
  validate: number;
  test: number;
}

export interface PopulationPolicy {
  type: 'cohort' | 'sample';
  cohort: CohortCond;
  sample_id?: string;
  split_ratio?: SplitRatio;
}

export interface BanditDataSpec {
  reward: ColAddr;
  action: GroupName[];
}

export interface PtrWithWindows {
  ptr: Ptr;
  windows: number[];
}

export enum OriginType {
  Activity = 'activity',
  Purchase = 'purchase',
}

export enum EventType {
  Churn = 'churn',
  PurchaseChurn = 'purchase_churn',
  FirstPurchase = 'first_purchase',
}

export const eventTypeStrings = {
  [EventType.Churn]: 'Churn',
  [EventType.PurchaseChurn]: 'Purchase Churn',
  [EventType.FirstPurchase]: 'First Purchase',
};

export const originTypeStrings = {
  [OriginType.Activity]: 'Activity',
  [OriginType.Purchase]: 'Purchase',
};

export interface CensoringTarget {
  event_description: {
    type: EventType;
    definition: number;
  };
  origin_description: {
    type: OriginType;
    definition: number;
  };
  look_back_time_in_days?: number;
  validation_delta: number;
}

export enum Transformation {
  PPMI = 'ppmi',
  LOG1P = 'log1p',
}

export interface CensoringDataSpec extends CensoringTarget {
  dynamic_features?: PtrWithWindows[];
  transformations?: Record<Ptr, Transformation>;
  embedding_model_id?: ApiModelId;
}

export interface RecommenderDataSpec {
  user_cohort_id: number;
  user_extra_filters: FilterAPI[];
  split_ratio: SplitRatio;
  embedding_traits: ColAddr[];

  full_dur: number; // (now - full_dur , now)
  test_dur: number; // (now - test_dur, now)
}

export enum InteractionType {
  View = 'view',
  Buy = 'buy',
}

export interface EmbeddingDataSpec {
  item_subject_type: TraitSubject;
  item_policy: PopulationPolicy;
  interaction_type: InteractionType;
  look_back_time: number;
  // Dim             int
}

export interface DataDefn {
  static_features?: ColAddr[];
  dynamic_features?: ColAddr[];
  bandit?: BanditDataSpec;
  censoring?: CensoringDataSpec;
  recommender?: RecommenderDataSpec;
  embedding?: EmbeddingDataSpec;

  agg_dur?: number;
}

export enum ModelTag {
  Intervention = 'inv',
  Landing = 'landing_plot',
  ML = 'ml_trait',
}

export interface ModelDefinitionRequest {
  name: string;
  algo_spec: AlgoSpec;
  population_policy: PopulationPolicy;
  data_defn: DataDefn;
}

export interface ModelDefinition extends ModelDefinitionRequest {
  id: ModelId;
  created_at: string;
  created_by: string;
  purpose: string;
  tags: ModelTag[];
  published: boolean;
  pin_to_landing?: boolean;
}

export interface NewModelRequest {
  definition: ModelDefinitionRequest;
  schedule: Schedule;
}

export interface Model {
  definition: ModelDefinition;
  schedule: Schedule;
  cohort: CohortAPI;
  sample_info: any; // TODO: review what comes from here
}

export interface AppModel extends Model {
  pinnable: boolean;
  removable: boolean;
  pinned: boolean;
  published: boolean;
  invId?: InterventionId;
}

export interface CIndexPair {
  t: string;
  v: number;
}

export interface ScatterData {
  x: number[] | null;
  y: number[] | null;
  x_label: string;
  y_label: string;
  x_unit: string;
  y_unit: string;
  id: string[] | null;
}

export interface CensoringMetricsGroup {
  cindex: CIndexPair[];
  cindex_ipcw: CIndexPair[];
  diag: ScatterData;
  horz: ScatterData;
}

export interface ModelScatterPlot {
  x: number[];
  y: number[];
  x_label: string;
  x_unit: string;
  y_label: string;
  y_unit: string;
}

export interface CensoringMetrics {
  train_scores: CensoringScores;
  validate_scores: CensoringScores;
  test_scores: CensoringScores;
  diag: ModelScatterPlot;
  horz: ModelScatterPlot;
}

export interface TimePoint {
  t: TimeRFC3999;
  v: number;
}

export interface RecommenderMetrics {
  accuracy: Record<string, TimePoint[]>;
  precision_k: Record<string, TimePoint[]>;
  recall_k: Record<string, TimePoint[]>;
}

export interface CensoringMetricsWrap {
  censoring: CensoringMetrics;
}

export interface RecommenderMetricsWrap {
  recom: RecommenderMetrics;
}

export interface EmbeddingMetrics {
  embedding: {
    rocauc: TimePoint[];
  };
}

export type ModelMetrics = CensoringMetricsWrap | RecommenderMetricsWrap | EmbeddingMetrics;

export interface CensoringScores {
  cindex: InputTimeSeriesItem[];
  cindex_ipcw: InputTimeSeriesItem[];
}

export interface AppScatterData {
  x: number;
  y: number;
  id: string;
}

export interface AppScatterMeta {
  xLabel: string;
  yLabel: string;
  xUnit: string;
  yUnit: string;
  data: AppScatterData[];
}

export interface AppScatterGroup {
  diag: AppScatterMeta;
  horz: AppScatterMeta;
}

export interface AppMetricsData {
  cindex: Record<string, CIndexPair[]>;
  cindex_ipcw: Record<string, CIndexPair[]>;
  scatter: Record<string, AppScatterGroup>;
}

export enum AlgorithmClass {
  Bandit = 'bandit',
  BanditV2 = 'banditv2',
  Censoring = 'censoring',
  Forecasting = 'forecasting',
  Recommender = 'recommender',
  Embedding = 'embedding',
  RestlessBandit = 'restless-bandit',
}

export interface AlgoMeta {
  algo_name: string;
  alias: string;
  class_name: AlgorithmClass;
  parameters: Record<string, any>;
}

export interface PlotItem {
  x: number[];
  y: number[];
  index: string[];
  legend: string[];
}

export interface EmbeddingPlots {
  pca: PlotItem;
  tsne: PlotItem;
}
