import React, { useCallback } from 'react';

import { faCircleQuestion, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import CFTooltip from 'components/CFTooltip';

import { Children } from 'react';

import './cf-title-section.scss';

export enum TitleSize {
  Small = 'small',
  Big = 'big',
}

interface Props {
  id?: string;
  title: string;
  description?: string;
  subtitle?: string;
  inlineSubtitle?: string;
  inlineSubtitleLink?: boolean;
  nested?: boolean;
  underlined?: boolean;
  className?: string;
  size?: TitleSize;
  onClose?: () => void;
  children: JSX.Element | JSX.Element[];
}

export const SectionAction = (props: any) => {
  return <div className={classNames(props.className)}> {props.children} </div>;
};

export const InlineSubtitle = (props: any) => {
  return <>{props.children} </>;
};

const CFTitledSection = ({
  id = '',
  title,
  nested = false,
  subtitle,
  inlineSubtitle,
  description,
  children,
  underlined = false,
  size = TitleSize.Big,
  onClose,
  className,
}: Props) => {
  const arrayChildren = Children.toArray(children);

  const actions = arrayChildren.filter((child) => (child as any).type.name === SectionAction.name);
  const inlineSubtitleComponent = arrayChildren.find((child) => (child as any).type.name === InlineSubtitle.name);

  const content = arrayChildren.filter((child) => {
    const childName = (child as any).type.name;

    return childName !== SectionAction.name && childName !== InlineSubtitle.name;
  });

  const handleClose = useCallback(() => {
    onClose?.();
  }, []);

  return (
    <section id={id} className={classNames('cf-titled-section', { nested })}>
      <header className={classNames({ underlined })}>
        <div className="header-row">
          <div className={`title ${size}`}>
            {onClose && (
              <span onClick={handleClose} className="title__close">
                <FontAwesomeIcon icon={faClose} size="lg" />
              </span>
            )}
            {title}
            <span className="title__sub">{inlineSubtitle}</span>
            <span className="title__sub"> {inlineSubtitleComponent}</span>
          </div>
          {description && (
            <CFTooltip description={description}>
              <FontAwesomeIcon icon={faCircleQuestion} size="sm" />
            </CFTooltip>
          )}
          <div className="actions">{actions}</div>
        </div>
        {subtitle && (
          <div className="header-row">
            <div className="subtitle">{subtitle}</div>
          </div>
        )}
      </header>

      <div className={classNames('content', className)}>{content}</div>
    </section>
  );
};

CFTitledSection.InlineSubtitle = InlineSubtitle;
CFTitledSection.Action = SectionAction;

export default CFTitledSection;
