import React from 'react';

import { Model } from 'domain/model.types';
import Wizard from 'components/CFWizard';
import ModelDefinitionView from '../components/definition';
import { Steps } from 'views/model/creation/useContext';
import Schedule from 'connected-components/schedule';
import CFTitledSection from 'components/CFTitledSection';
import ModelCohort from '../components/cohort';
import Cohort from 'services/cohort/domain/Cohort';
import { MarkovPolicy } from 'services/intervention/intervention.types';
import MarkovStatesDefinition from 'views/intervention/interventions/monitoring/definition/markov';

interface Props {
  model: Model;
}

const ClassRestlessBandit = ({ model }: Props) => {
  return (
    <Wizard>
      <Wizard.Step name={Steps.Definition} ready={true}>
        <ModelDefinitionView />
      </Wizard.Step>

      <Wizard.Step name={Steps.Schedule} ready={true}>
        <Schedule id={model.schedule.id} label="model" />
      </Wizard.Step>

      <Wizard.Step name={Steps.UserCohort} ready={true}>
        <CFTitledSection title="Cohort" id={Steps.UserCohort}>
          <ModelCohort
            model={model}
            cohort={new Cohort(model.cohort)}
            splitRatio={model.definition.population_policy.split_ratio}
          />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={'states'} ready={true}>
        <MarkovStatesDefinition markovPolicy={model.definition.algo_spec.markov as MarkovPolicy} />
      </Wizard.Step>
    </Wizard>
  );
};

export default ClassRestlessBandit;
