import React from 'react';
import { Buffer } from 'buffer';

import { CFRole } from 'domain/general.types';

import CFButton from 'components/buttons/CFButton';
import CFInput, { CFInputType } from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';

interface Props {
  onReady: (token: string) => void;
}

const UserPasswordLogin = ({ onReady }: Props) => {
  const handleSignInWithUsername = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const formData = new FormData(evt.target as HTMLFormElement);
    const username = formData.get('username');
    const password = formData.get('password');

    const token = Buffer.from(`${username}:${password}`).toString('base64');

    onReady(token);
  };

  return (
    <div data-testid="user-password-login">
      <form onSubmit={handleSignInWithUsername} className="login-form">
        <CFTitledComponent title="Username">
          <CFInput name="username" placeholder="Enter your username" />
        </CFTitledComponent>

        <CFTitledComponent title="Password">
          <CFInput type={CFInputType.Password} name="password" placeholder="Enter your password" />
        </CFTitledComponent>

        <CFButton
          value={'Sign in'}
          onClick={() => {
            /**/
          }}
          role={CFRole.Primary}
        />
      </form>
    </div>
  );
};

export default UserPasswordLogin;
