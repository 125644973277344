import { CFRoutes } from 'routes';
import { AuthAction, isAllowedTo } from 'services/authorization.service';

export enum Tabs {
  Explore = 'explore',
  UserEngagement = 'user engagement',
  NonGdtExplorer = 'dynamic explorer',
}

export const allowedTabs = () => {
  const tabs = [Tabs.Explore];

  if (isAllowedTo(AuthAction.SeeAnalyticsUserEngagement)) {
    tabs.push(Tabs.UserEngagement);
  }

  if (isAllowedTo(AuthAction.SeeDynamicExplorer)) {
    tabs.push(Tabs.NonGdtExplorer);
  }

  return tabs;
};

export const tabNameToCode = (tabName: string) => tabName.split(' ').join('-');

export const monitoringByTab: Record<string, CFRoutes> = {
  [tabNameToCode(Tabs.Explore)]: CFRoutes.analytics_explore,
  [tabNameToCode(Tabs.NonGdtExplorer)]: CFRoutes.analytics_non_gdt_explorer,
  [tabNameToCode(Tabs.UserEngagement)]: CFRoutes.analytics_engagement_per_role,
};
