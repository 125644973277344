import React, { useMemo } from 'react';
import { useState } from 'react';

import HexPatternImage from 'assets/cf-pattern-hexagon.png';
import CirclePatternImage from 'assets/cf-pattern-circle.png';
import Logo from './assets/logo.png';

import { AuthType, getProfile, validate } from '../../auth';
import { UserDetailedInfo } from '../../types';

import UserPasswordLogin from './UserPasswordLogin';
import MicrosoftLogin from './MicrosoftLogin';
import GoogleLogin from './GoogleLogin';

import './login.scss';

/**
 * See https://developers.google.com/identity/gsi/web/guides/
 */
interface Props {
  serverBaseUrl: string;
  onError: () => void;
  onLogin: (userInfo: UserDetailedInfo, token: string) => void;
}

const Login = ({ serverBaseUrl, onLogin, onError }: Props) => {
  const [error, setError] = useState(false);

  const showSeparator = useMemo(() => {
    return (
      process.env.REACT_APP_LOGIN_WITH_PASSWORD &&
      (process.env.REACT_APP_LOGIN_GOOGLE || process.env.REACT_APP_LOGIN_MICROSOFT)
    );
  }, []);

  const handleError = () => {
    setError(true);
    onError();
  };

  const handleTokenAndLogin = async (jwt: string) => {
    try {
      await validate(serverBaseUrl, jwt);
      const userInfo = await getProfile(serverBaseUrl);
      handleLogin(userInfo, jwt);
    } catch {
      handleError();
    }
  };

  const handleLogin = (userInfo: UserDetailedInfo, token: string) => {
    onLogin(userInfo, token);
  };

  const handleSignInWithUsername = async (token: string) => {
    try {
      await validate(serverBaseUrl, token, AuthType.Basic);

      const userInfo = await getProfile(serverBaseUrl);
      handleLogin(userInfo, token);
    } catch (error) {
      handleError();
    }
  };

  return (
    <div className="login-panel">
      <div className="hex-pattern">
        <img src={HexPatternImage} alt="causal foundry pattern" />
      </div>
      <div className="top-left-gradient"></div>

      <div className="circle-pattern">
        <img src={CirclePatternImage} alt="causal foundry pattern" />
      </div>

      <div className="bottom-right-gradient"></div>

      <div className="login-button">
        <img className="logo" src={Logo} alt="Causal Foundry Logo" />

        {error && (
          <div className="error">
            Your account does not exist. Sign in with a different one or contact{' '}
            <a href="mailto:hello@causalfoundry.ai">hello@causalfoundry.ai</a>
          </div>
        )}

        {process.env.REACT_APP_LOGIN_WITH_PASSWORD && <UserPasswordLogin onReady={handleSignInWithUsername} />}

        {showSeparator && <div className="or-login-with">or login with</div>}

        {process.env.REACT_APP_LOGIN_GOOGLE && <GoogleLogin onReady={handleTokenAndLogin} onError={onError} />}

        {process.env.REACT_APP_LOGIN_MICROSOFT && <MicrosoftLogin onReady={handleTokenAndLogin} onError={onError} />}
      </div>
    </div>
  );
};

export default Login;
