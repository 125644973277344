import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CFStackedChart from 'components/charts/CFStackedChart';
import { InterventionId, RestlessCounts } from 'services/intervention/intervention.types';
import { useServicesContext } from 'hooks/useServicesContext';
import CFSelect, { Option } from 'components/CFSelect';

import CFTitledComponent from 'components/CFTitledComponent';
import { GroupName } from 'services/markov/markov.types';

import './restless-group-proportions.scss';

interface Props {
  counts: RestlessCounts;
  interventionId: InterventionId;
}

const RestlessStateProportions = ({ counts, interventionId }: Props) => {
  const { interventionService } = useServicesContext();
  const [selectedGroups, setSelectedGroups] = useState<GroupName[]>([]);

  const handleSelectGroup = useCallback((clickedState: Option) => {
    setSelectedGroups((selected) => {
      const index = selected.indexOf(clickedState.value);

      if (index === -1) {
        return [...selected, clickedState.value];
      } else {
        const newSelected = [...selected];

        newSelected.splice(index, 1);

        return newSelected;
      }
    });
  }, []);

  useEffect(() => {
    setSelectedGroups(interventionService.getGroupNames(interventionId));
  }, []);

  const xTics = useMemo(() => {
    return Object.keys(counts);
  }, [counts]);

  const states = useMemo(() => {
    return Array(interventionService.getMarkovStatesCount(interventionId))
      .fill('')
      .map((_, i) => i);
  }, []);

  const totals = xTics.map((tic) =>
    counts[tic].filter((count) => selectedGroups.indexOf(count.group) !== -1).reduce((acc, cur) => acc + cur.count, 0)
  );

  const series = states.map((state) => ({
    name: `s${state}`,
    values: xTics.map((tic, i) => {
      const normalizedFactor = totals[i] ? totals[i] : 1;

      return {
        time: tic,
        value:
          counts[tic]
            .filter((count) => count.state === state)
            .filter((count) => selectedGroups.indexOf(count.group) !== -1)
            .reduce((acc, cur) => acc + cur.count, 0) / normalizedFactor, // TODO: NORMALIZAR -> dividir por el valor total en este punto
      };
    }),
  }));

  const groupOptions = useMemo(() => {
    return interventionService.getGroupNames(interventionId).map((group) => ({ label: group, value: group }));
  }, []);

  return (
    <div className="restless-group-proportions">
      <div className="controls">
        <CFTitledComponent title="Actions">
          <CFSelect
            options={groupOptions}
            isMulti={true}
            value={selectedGroups.map((groupName) => ({ value: groupName, label: groupName }))}
            onSelected={handleSelectGroup}
          />
        </CFTitledComponent>
      </div>

      <CFStackedChart
        key="restless-group-proportions"
        title=""
        xLabel="Decision point"
        xAxis={xTics}
        series={series}
        isLoading={false}
      />
    </div>
  );
};

export default RestlessStateProportions;
