import { AggLevel } from 'domain/stats.types';
import colors from 'common.scss';
import dayjs from 'dayjs';
import { Units } from 'helpers/unitconversion';
import { RGBColor } from 'domain/general.types';
import { findMaxRoundedValue } from './helper';

export const TOOLTIP_DATE_FORMAT = {
  [AggLevel.Hour]: 'ddd MMM DD YYYY HH:mm',
  [AggLevel.Day]: 'ddd MMM DD YYYY',
  [AggLevel.Week]: 'ddd MMM DD YYYY',
  [AggLevel.Month]: 'MMM YYYY',
  [AggLevel.NA]: 'MMM YYYY',
};

export const X_AXIS_DATE_FORMAT = {
  [AggLevel.Hour]: 'MMM DD HH:mm',
  [AggLevel.Day]: 'MMM D',
  [AggLevel.Week]: 'MMM D',
  [AggLevel.Month]: 'MMM \nYYYY',
  [AggLevel.NA]: 'MMM \nYYYY',
};

export const X_AXIS_LABEL = {
  [AggLevel.Hour]: 'Hours',
  [AggLevel.Week]: 'Weeks',
  [AggLevel.Day]: 'Days',
  [AggLevel.Month]: 'Months',
  [AggLevel.NA]: '',
};

export interface DataItem {
  time: string;
  value: number | null;
  upper?: number;
  lower?: number;
}

export enum LineStyle {
  DOTTED = 'dotted',
  SOLID = 'solid',
}

export interface DataSeries {
  name: string;
  data: DataItem[];
  lineStyle?: LineStyle;
  color?: RGBColor;
}

export const tooltip = () => ({
  trigger: 'axis',
  axisPointer: {
    type: 'shadow',
  },
  backgroundColor: colors.dark80,
  borderColor: colors.dark80,
  display: 'flex',
  flexDirection: 'column',
});

export const binTooltip = () => ({
  trigger: 'axis',
  axisPointer: {
    type: 'shadow',
  },
  backgroundColor: colors.dark80,
  borderColor: colors.dark80,
  display: 'flex',
  flexDirection: 'column',
  formatter: (params: any) => {
    const paramsText = params
      .map((param: any) => {
        if (param.data === null) {
          return '';
        }

        return `${param.seriesName} : ${param.data[param.seriesName]}`;
      })
      .join('<br />');

    return `<div class="cf-tooltip-text">${paramsText}</div>`;
  },
});

export const tooltipFormatterDaysAhead = (params: any, aggregationLevel: AggLevel): string => {
  const text = `<span style="font-size: 12px; font-weight: bold">${dayjs(new Date())
    .add(params[0].axisValue, 'day')
    .format(TOOLTIP_DATE_FORMAT[aggregationLevel])}</span><br/>`;

  const values = params.map((param: any) => param.data);
  const formattedValues = values.map((value: any) => `<span>${value.toFixed(3)}</span>`).join('<br />');

  return `<div class="cf-tooltip-text">
      ${text}
      ${formattedValues}
  </div>`;
};

export const tooltipNumericFormatter = (params: any, decimalsPlaces = 2): string => {
  const paramsText = params
    .map((param: any) => {
      if (param.data === null) {
        return '';
      }

      const numericValue = findMaxRoundedValue(param.data, decimalsPlaces);

      const styles = `display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param.color};`;

      return `<span style=${styles}></span>${param.seriesName}: ${numericValue}`;
    })
    .join('<br />');

  return `<div class="cf-tooltip-text">${paramsText}</div>`;
};

export const tooltipFormatter = (params: any, aggregationLevel: AggLevel, units: Units, decimalsPlaces = 2): string => {
  const text = `<span style="font-size: 12px; font-weight: bold">${dayjs(params[0].axisValue).format(
    TOOLTIP_DATE_FORMAT[aggregationLevel]
  )}</span><br/>`;

  const paramsText = params
    .map((param: any) => {
      if (param.data === null) {
        return '';
      }

      // remove less significant decimals
      const numericValue = findMaxRoundedValue(param.data, decimalsPlaces);

      let value = `${numericValue.toLocaleString(undefined, { useGrouping: true })} ${units}`;

      if (param.data !== undefined && units === Units.MinuteIS) {
        if (param.data > 60) {
          value = `${(dayjs as any).duration(param.data, 'minutes').format('HH:mm:ss')} hours`;
        } else {
          value = `${(dayjs as any).duration(param.data, 'minutes').format('mm:ss')} min`;
        }
      }

      const styles = `display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param.color};`;

      return `<span style=${styles}></span>${param.seriesName}: ${value}`;
    })
    .join('<br />');

  return `<div class="cf-tooltip-text">${text}${paramsText}</div>`;
};

export const tooltipWithConfidenceIntervalFormatter = (params: any, data: DataSeries[]) => {
  // For some reasons, in some charts, the items in legend were duplicated.
  // I didnt find the root cause of this so to overcome, I am adding each item
  // as a key in an object. If one if duplicated it will be replaced.
  const tooltipItems: Record<string, string> = {};

  params.forEach((param: any) => {
    const dataItem = data.find((item) => item.name === param.seriesName);
    const chartData = dataItem ? dataItem.data[param.dataIndex] : null;

    const formatValue = (value: number) =>
      value.toLocaleString('en', {
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

    if (!chartData) {
      return;
    }

    const item = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
      param.color
    }"></span>
        ${param.seriesName}: ${
      chartData.value !== undefined && chartData.value !== null ? formatValue(chartData.value) : '-'
    } 
        ${
          chartData.lower && chartData.upper
            ? `(${formatValue(chartData.lower)} ; ${formatValue(chartData.upper)})`
            : ``
        }<br/>`;

    tooltipItems[param.seriesName] = item;
  });

  const dateText = `<span style="font-size: 12px; font-weight: bold">${dayjs(params[0].axisValue).format(
    TOOLTIP_DATE_FORMAT[AggLevel.Hour]
  )}</span><br/>`;

  return `<div class="cf-tooltip-text">${dateText} ${Object.values(tooltipItems).join('')}</div>`;
};

export const tooltipBarFormatter = (params: any) => {
  const text = `<span style="font-size: 12px; font-weight: bold">${dayjs(params[0].axisValue).format(
    TOOLTIP_DATE_FORMAT[AggLevel.Hour]
  )}</span><br/>`;
  const paramsText = params
    .map((param: any) => {
      const value = param.data[1];
      let formattedValue;
      if (value === parseInt(value)) {
        formattedValue = value;
      } else {
        formattedValue = value.toFixed(3);
      }
      const styles = `display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param.color};`;
      return `<span style=${styles}></span>${param.seriesName}: ${formattedValue}`;
    })
    .join('<br />');

  return `<div class="cf-tooltip-text">${text}${paramsText}</div>`;
};

export const xAxisTimestampFormatter = (value: any, aggregationLevel: AggLevel) => {
  return dayjs(value).format(X_AXIS_DATE_FORMAT[aggregationLevel]);
};

export const xAxisValueFormatter = (value: any) => {
  return Number(value).toFixed(4);
};
