import React, { useCallback, useEffect, useRef, useState } from 'react';

import CFInput from 'components/CFInput';
import CFButton from 'components/buttons/CFButton';
import ScaledSubjectGraph from './ScaledSubjectGraph';

import { GraphPerGroup, GroupName, MarkovProcessId, NodeUpdate } from 'services/markov/markov.types';

import { SubjectId } from 'domain/general.types';

import { getGraphPerSubject } from 'services/markov/markov.service';

import './markov-subject-viewer.scss';

interface Props {
  processId: MarkovProcessId;
  updates: Record<GroupName, NodeUpdate[]>;
}

const MarkovSubjectViewer = React.memo(function MarkovSubjectViewer({ processId, updates }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [subjectId, setSubjectId] = useState<SubjectId>();

  const [graphs, setGraphs] = useState<GraphPerGroup>({});

  useEffect(() => {
    (async () => {
      if (!subjectId) {
        return;
      }

      // si hay updates, los usamos para obtener los grafos
      // si no hay updates, hacemos una sola petición sin updates, ya que en la respuesta
      // ya tenemos los valores separados por groupname (nudgeName)

      if (!Object.keys(updates).length) {
        const graphs = await getGraphPerSubject(subjectId, processId, '', []);
        setGraphs(graphs);
      } else {
        const treatmentName = Object.keys(updates).filter((nudgeName) => nudgeName !== 'control')[0];

        const graphs = await getGraphPerSubject(subjectId, processId, treatmentName, updates[treatmentName]);
        setGraphs(graphs);
      }
    })();
  }, [subjectId, updates]);

  const handleSeeSubjectGraph = useCallback(async () => {
    if (!inputRef || !inputRef.current) {
      return;
    }

    const subjectId = inputRef.current.value;
    setSubjectId(subjectId);
  }, [inputRef, inputRef.current, processId]);

  return (
    <div className="markov-subject-viewer">
      <div className="input">
        <CFInput ref={inputRef} />
        <CFButton value={'See subject graphs'} onClick={handleSeeSubjectGraph} />
      </div>

      {subjectId && (
        <div className="graphs">
          {Object.keys(graphs).map((nudgeName) => (
            <ScaledSubjectGraph key={`nudge-${nudgeName}`} groupName={nudgeName} graphSpec={graphs[nudgeName]} />
          ))}
        </div>
      )}
    </div>
  );
});

export default MarkovSubjectViewer;
