import React from 'react';

import NonGdtExplorerInternal from './nonGdtInternalExplorer';
import AnalyticsContext from '../explore/context/useAnalyticsContext';

const NonGdtExplorer = () => {
  return (
    <AnalyticsContext>
      <NonGdtExplorerInternal />
    </AnalyticsContext>
  );
};

export default NonGdtExplorer;
