import EventDispatcher, { EventCallback } from 'helpers/EventDispatcher';

import { SubjectId, TimeRFC3999 } from 'domain/general.types';
import { ColAddr } from 'domain/traits.types';

import { CFInterventionDraftRepository } from 'repositories/interventionDraft';

import {
  CreatingIntervention,
  Intervention,
  InterventionDraft,
  InterventionFailedStatus,
  InterventionId,
  InterventionViewExtended,
  ListMode,
} from 'services/intervention/intervention.types';
import { AuthAction, CFAuthService } from 'services/authorization.service';
import CohortService from 'services/cohort/cohort.service';
import Cohort from 'services/cohort/domain/Cohort';
import { GroupName } from 'services/markov/markov.types';
import { CFInterventionRepository, getMetricsBySubject } from 'services/intervention/intervention.repo';

import CFService from '../cfservice';
import { Schedule } from 'services/scheduling/schedulting.types.api';

export enum InterventionEvent {
  VIEW,
}

export default class InterventionService extends CFService {
  private views: Record<InterventionId, InterventionViewExtended> = {};
  private authService: CFAuthService;
  private cohortService: CohortService;
  private interventionRepository: CFInterventionRepository;
  private interventionDraftRepository: CFInterventionDraftRepository;
  private draftId: number | undefined = undefined;
  private eventDispatcher = new EventDispatcher<InterventionViewExtended, InterventionEvent>();

  constructor(
    interventionRepository: CFInterventionRepository,
    interventionDraftRepository: CFInterventionDraftRepository,
    authService: CFAuthService,
    cohortService: CohortService
  ) {
    super();
    this.authService = authService;
    this.cohortService = cohortService;

    this.interventionRepository = interventionRepository;
    this.interventionDraftRepository = interventionDraftRepository;

    this._name = 'interventionService';
  }

  async init() {
    //
  }

  async getView(interventionId: InterventionId): Promise<InterventionViewExtended> {
    let view = this.views[interventionId];

    if (!view) {
      const intvViewAPI = await this.interventionRepository.getView(interventionId);

      const intvView: Partial<InterventionViewExtended> = { ...intvViewAPI, cohort: new Cohort(intvViewAPI.cohort) };

      view = intvView as InterventionViewExtended;

      this.views[interventionId] = intvView as InterventionViewExtended;
      this.views[interventionId].failed = this.views[interventionId].latest_slot.status === 'failed';
      this.views[interventionId].isTest = this.views[interventionId].intervention.tags.includes('test');
      this.views[interventionId].isNew = Object.keys(this.views[interventionId].schedule.slots).length === 0;

      if (this.views[interventionId].failed) {
        try {
          if (this.authService.isAllowedTo(AuthAction.SeeExtendedInterventionError)) {
            this.views[interventionId].failedMessage = this.views[interventionId].latest_slot.detail;
          } else {
            const jsonMessage: InterventionFailedStatus = JSON.parse(this.views[interventionId].latest_slot.detail);
            this.views[interventionId].failedMessage = jsonMessage.detail.msg;
          }
        } catch (err) {
          this.views[interventionId].failedMessage = this.views[interventionId].latest_slot.detail;
        }
      }
    }

    if (!this.views[interventionId].sampleSize) {
      this.views[interventionId].sampleSize = this.views[interventionId].sample_info.count;
    }

    if (!this.views[interventionId].controlGroup) {
      const availableGroups = Object.keys(this.views[interventionId].intervention.nudge_policy.group_allocation);

      availableGroups.forEach((groupName) => {
        if (!view.nudge_group_allocation[groupName]) {
          this.views[interventionId].controlGroup = groupName;
        }
      });
    }

    if (this.views[interventionId].cohort.id === 0) {
      this.views[interventionId].cohort.name = 'manual';
    }

    this.notify(InterventionEvent.VIEW, this.views[interventionId]);

    return view;
  }

  async list(page: number, page_size: number, mode: ListMode) {
    const list = await this.interventionRepository.get(page, page_size, mode);

    // const drafts = await this.interventionDraftRepository.get();

    /*
    list.data.forEach((view: InterventionView) => {
      (view.cohort.tree.nodes[0].leaf as Leaf).filters?.forEach((filter) => {
        const trait = this.traitService.getTraitFromAddr(filter.col);

        (filter as AppFilter).trait = trait;
      });

      this.views[view.intervention.id] = view as InterventionViewExtended;
    });
*/
    return list;
  }

  async pause(interventionId: InterventionId) {
    await this.interventionRepository.pause(interventionId, true);
  }

  async resume(interventionId: InterventionId) {
    await this.interventionRepository.pause(interventionId, false);
  }

  async terminate(interventionId: InterventionId) {
    await this.interventionRepository.terminate(interventionId);
  }

  async create(intervention: CreatingIntervention, schedule: Schedule) {
    await this.interventionRepository.create(intervention, schedule, this.draftId);
    this.draftId = undefined;
  }

  async downloadParticipants(interventionId: InterventionId) {
    const participants = await this.interventionRepository.participants(interventionId);

    return participants;
  }

  async getDraft(draftId: number): Promise<Partial<InterventionViewExtended>> {
    const drafts = await this.interventionDraftRepository.get();

    const foundDraft = drafts.find((draft) => draft.ID === draftId);

    if (!foundDraft) {
      throw new Error('draft-not-found');
    }

    const cohort = await this.cohortService.getRemoteCohort(foundDraft.Draft.intervention.participant_policy.cohort_id);

    const appInterventionView = {
      intervention: foundDraft.Draft.intervention as Intervention,
      fixed_nudge_schedule: foundDraft.Draft.schedule as Schedule,
      cohort,
    };

    return appInterventionView;
  }

  async getDrafts(): Promise<InterventionDraft[]> {
    const drafts = await this.interventionDraftRepository.get();
    return drafts;
  }

  async deleteDraft(draftId: number) {
    // deleting draft
    await this.interventionDraftRepository.remove(draftId);
  }

  async saveDraft(intervention: CreatingIntervention, schedule: Schedule) {
    if (!this.draftId) {
      const draftId = await this.interventionDraftRepository.save(intervention, schedule);
      this.draftId = draftId;
    } else {
      await this.interventionDraftRepository.update(intervention, schedule, this.draftId);
    }
  }

  startDraft(id: number) {
    this.draftId = id;
  }

  stopDraft() {
    this.draftId = undefined;
  }

  async getMetricsForSubject(interventionId: InterventionId, subjects: SubjectId[], offset: number) {
    return getMetricsBySubject(interventionId, subjects, offset);
  }

  async getDecisionsPoints(interventionId: InterventionId): Promise<TimeRFC3999[]> {
    return this.views[interventionId].schedule.definition.time_points?.pts || [];
  }

  getGroupNames(interventionId: InterventionId): GroupName[] {
    return Object.keys(this.views[interventionId].intervention.nudge_policy.group_allocation);
  }

  getMarkovStatesCount(interventionId: InterventionId) {
    return this.views[interventionId].intervention.algo_policy.spec?.markov?.nodes.length || 0;
  }

  getConfiguredMetrics(interventionId: InterventionId): ColAddr[] {
    const metrics = [
      // ab
      ...(this.views[interventionId].intervention.metric_policy.ab?.metrics || []),

      // bandit
      ...(this.views[interventionId].intervention.metric_policy.bandit?.dynamic_ctx || []),
      ...(this.views[interventionId].intervention.metric_policy.bandit?.supporting || []),
      this.views[interventionId].intervention.metric_policy.bandit?.objective,

      // restless
      ...(this.views[interventionId].intervention.metric_policy.restless?.metrics || []),
    ].filter((trait) => !!trait);

    return metrics as ColAddr[];
  }

  notify = (type: InterventionEvent, view: InterventionViewExtended) => {
    this.eventDispatcher.notify(type, view);
  };

  subscribe = (type: InterventionEvent, callback: EventCallback<InterventionViewExtended>) => {
    this.eventDispatcher.subscribe(type, callback);
  };

  unsubscribe = (type: InterventionEvent, callback: EventCallback<InterventionViewExtended>) => {
    this.eventDispatcher.unsubscribe(type, callback);
  };
}
