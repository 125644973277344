import React, { useMemo } from 'react';

import dayjs from 'dayjs';

import {
  AlgorithmSpec,
  AlgorithmType,
  BanditMetricsCommon,
  InterventionId,
} from 'services/intervention/intervention.types';
import useIntervention from 'services/intervention/hooks/useIntervention';

import KeyValue from '../KeyValue';

import './intervention-detail.scss';
import { timeWindowToHuman } from 'helpers/misc';

interface Props {
  id: InterventionId;
}

const InterventionDetail = ({ id }: Props) => {
  const intervention = useIntervention(id);

  const isBandit = useMemo(() => {
    return (
      intervention?.intervention.algo_policy?.type === AlgorithmType.Bandit ||
      intervention?.intervention.algo_policy?.type === AlgorithmType.BanditV2
    );
  }, [intervention]);

  const banditMetrics: BanditMetricsCommon = useMemo(() => {
    if (intervention?.intervention.algo_policy?.type === AlgorithmType.Bandit) {
      return intervention.intervention.metric_policy.bandit as BanditMetricsCommon;
    } else if (intervention?.intervention.algo_policy?.type === AlgorithmType.BanditV2) {
      return intervention.intervention.metric_policy.banditv2 as unknown as BanditMetricsCommon;
    } else {
      return {} as BanditMetricsCommon;
    }
  }, [intervention]);

  const modelId = useMemo(() => {
    return intervention?.intervention.algo_policy?.model_def_id;
  }, [intervention]);

  const [timeWindowValue, timeWindowUnit] = useMemo(() => {
    if (!intervention || !banditMetrics) {
      return ['', ''];
    }

    return timeWindowToHuman(banditMetrics.decision_time_window);
  }, [intervention, banditMetrics]);

  const [vacationPeriodValue, vacationPeriodUnit] = useMemo(() => {
    if (!intervention || !banditMetrics) {
      return ['', ''];
    }

    return timeWindowToHuman(banditMetrics.vacation_period);
  }, [intervention, banditMetrics]);

  return (
    <div className="intervention-detail">
      <div className="header"></div>

      <div className="content">
        <div className="description">
          <KeyValue
            name="Hypothesis description"
            value={intervention?.intervention.description || 'No description available'}
            forceBreakline={true}
          />
        </div>

        {modelId && (
          <>
            <KeyValue name="Model ID" value={modelId} />
          </>
        )}

        <KeyValue
          name="Algorithm"
          value={(intervention?.intervention.algo_policy?.spec as AlgorithmSpec)?.algo_name || ''}
        />
        {isBandit && (
          <>
            <KeyValue name="Monitoring window" value={`${timeWindowValue} ${timeWindowUnit}` || '-'} />
            <KeyValue name="Vacation period" value={`${vacationPeriodValue} ${vacationPeriodUnit}` || '-'} />
          </>
        )}
        <KeyValue name="Created by" value={intervention?.intervention.created_by || ''} />
        <KeyValue name="Created at" value={dayjs(intervention?.intervention.created_at).format('YYYY-MM-DD') || ''} />

        <KeyValue name="Cohort" value={intervention?.cohort.name || ''} />
        <KeyValue name="Sample size" value={intervention?.sampleSize as number} />

        <KeyValue
          name="Groups"
          value={Object.keys(intervention?.intervention.nudge_policy?.group_allocation || {}).length}
        />
        <KeyValue name="Status" value={intervention?.status || ''} />
      </div>
    </div>
  );
};

export default InterventionDetail;
