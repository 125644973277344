import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AnalyticsLayout from '../layout';
import { Tabs } from '../tabs';

import { useServicesContext } from 'hooks/useServicesContext';
import useToast from 'hooks/useToast';
import { CHART_COLORS, chartLineColor1 } from 'styles/colors';

import { daysAgo, pruneHoursNoTimezone } from 'helpers/dates';

import { AggLevel } from 'domain/stats.types';
import { Bin, PerCohortResp, Trait } from 'domain/traits.types';
import { CFRole, TimeRFC3999 } from 'domain/general.types';

import { CohortID } from 'services/cohort/cohort.types';
import { Ptr } from 'services/cohort/cohort.types.api';
import { cohortSearch } from 'services/traits/traits.repo';

import CFButton from 'components/buttons/CFButton';
import CFSpinner from 'components/CFSpinner';
import TraitItem from 'components/CFSelect/common/TraitItem';
import { ToastType } from 'components/CFToast/types';
import DatetimePicker from 'components/DateTime/DatetimePicker';
import CFLineChart, { AxisType } from 'components/charts/CFLineChart';
import CFBinHistogram from 'components/charts/CFBinHistogram';
import CFTitledSection, { SectionAction } from 'components/CFTitledSection';
import CFSelect, { Option } from 'components/CFSelect';
import TraitInputContainer from 'components/CFSelect/common/TraitInputContainer';
import ScatterTrait from './components/ScatterTrait';
import FilterControls from './components/FilterControls';
import SelectedTags, { TagValue } from 'components/SelectedTags';

import { useAnalyticsContext } from '../explore/context/useAnalyticsContext';

import { createCohortTagName } from '../explore/helpers/cohortTagName';
import { removeCohortByName } from '../explore/services/cohort';

import colors from 'common.scss';

import './non-gdt-explorer.scss';

export type PTRByCohort = string;

const NonGdtExplorerInternal = () => {
  const { traitSessionService: traitService, cohortService } = useServicesContext();
  const [datetime, setDatetime] = useState<TimeRFC3999>(pruneHoursNoTimezone(new Date(daysAgo(1))));
  const [cohortData, setCohortData] = useState<Record<CohortID, Record<Ptr, PerCohortResp>>>({});

  const { selectedCohortIDs, traitsState, availableCohorts, setSelectedCohortIDs } = useAnalyticsContext();
  const [selectedTrait, setSelectedTrait] = useState<Ptr>('');
  const [loadingTrait, setLoadingTrait] = useState<Record<Ptr, boolean>>({});
  const [traitInfo, setTraitInfo] = useState<Record<Ptr, Trait>>({});

  const [scatterCount, setScatterCount] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const { addToast } = useToast();

  const updateCohortData = async (cohortId: CohortID, trait: Ptr, datetime: TimeRFC3999) => {
    try {
      setLoadingTrait((loadingTrait) => ({ ...loadingTrait, [trait]: true }));

      const resp = await cohortSearch(Number(cohortId), trait, datetime);

      setCohortData((cohortData) => ({
        ...cohortData,
        [cohortId]: { ...cohortData[Number(cohortId)], [trait]: resp },
      }));
      setLoadingTrait((loadingTrait) => ({ ...loadingTrait, [trait]: false }));
    } catch (err) {
      setLoadingTrait((loadingTrait) => ({ ...loadingTrait, [trait]: false }));
      addToast(`Error fetching data ${(err as any).message}`, ToastType.ERROR);
    }
  };

  useEffect(() => {
    (async () => {
      if (!selectedCohortIDs.length || !datetime || !traitsState.traits['day']?.[0]) {
        return;
      }

      // assuming that new one is the first one
      const traitId = traitsState.traits['day'][0];

      if (!traitId) {
        return;
      }

      selectedCohortIDs.forEach(async (cohortId) => {
        updateCohortData(Number(cohortId), traitId, datetime);
      });
    })();
  }, [traitsState, traitsState.traits['day']]);

  useEffect(
    function updateDataOnDatetimeChange() {
      selectedCohortIDs.forEach(async (cohortId) => {
        traitsState.traits['day']?.forEach(async (trait) => {
          updateCohortData(Number(cohortId), trait, datetime);
        });
      });
    },
    [datetime]
  );

  useEffect(
    function getDataOnNewCohort() {
      selectedCohortIDs.forEach(async (cohortId) => {
        if (cohortData[Number(cohortId)]) {
          return;
        }

        traitsState.traits['day']?.forEach(async (trait) => {
          updateCohortData(Number(cohortId), trait, datetime);
        });
      });
    },
    [selectedCohortIDs]
  );

  useEffect(
    function getFullTraitInfo() {
      (async () => {
        if (!traitsState.traits['day']?.length) {
          return;
        }

        const traitId = traitsState.traits['day'][0];

        const trait = traitService.getTraitDefinition(traitId);

        setTraitInfo((traitInfo) => ({ ...traitInfo, [traitId]: trait }));
      })();
    },

    [traitsState, traitsState.traits['day']]
  );

  // initialize KDE
  useEffect(
    function initializeKDE() {
      if (selectedTrait) {
        return;
      }

      const traits = Object.keys(traitInfo);

      if (!traits.length) {
        return;
      }

      setSelectedTrait(traits[0]);
    },

    [selectedTrait, traitInfo]
  );

  const handleDatetimeChange = (date: Date) => {
    setDatetime(pruneHoursNoTimezone(date));
  };

  const handleSelectTrait = useCallback((option: Option) => {
    setSelectedTrait(option.value);
  }, []);

  const handleAddScatter = useCallback(() => {
    setScatterCount((scatterCount) => scatterCount + 1);
  }, []);

  const handleRemoveCohortFromTag = useCallback(
    (value: TagValue) => {
      const filteredIds = removeCohortByName(
        cohortService,
        value.value,
        selectedCohortIDs.map((i) => parseInt(i)),
        availableCohorts ?? []
      );

      setSelectedCohortIDs(filteredIds.map((i) => i.toString()));
    },
    [availableCohorts, selectedCohortIDs, cohortService]
  );

  const traitPairs = useMemo(() => {
    // end goal: all possible pairs of traits
    return Object.keys(traitInfo).map((trait) => trait);
  }, [traitInfo]);

  const loading = Object.values(loadingTrait).some((loading) => loading);

  return (
    <AnalyticsLayout tab={Tabs.NonGdtExplorer} className="non-gdt-analytics">
      <FilterControls />

      <SelectedTags
        ref={containerRef}
        label="Selected cohorts"
        values={
          availableCohorts?.length
            ? selectedCohortIDs.map((cid, idx) => ({
                value: createCohortTagName(cohortService.getCohort(cid)),
                color: CHART_COLORS[idx] || chartLineColor1,
              }))
            : []
        }
        onRemove={handleRemoveCohortFromTag}
      />

      <div className="non-gdt-analytics__toolbox">
        {loading && (
          <div className="non-gdt-analytics__toolbox__spinner">
            <CFSpinner size={20} color={colors.cfCyan} stroke={4} />
            <span>Loading...</span>
          </div>
        )}
        <DatetimePicker onChange={handleDatetimeChange} initialDate={new Date(daysAgo(1))} />
      </div>

      <CFTitledSection title="Kernel Density Estimation">
        <SectionAction className="non-gdt-analytics__kde__select">
          <CFSelect
            options={
              traitsState.traits['day']?.map((ptr: string) => ({
                label: ptr,
                value: ptr,
                meta: { trait: traitInfo[ptr] },
              })) || []
            }
            value={{ label: selectedTrait, value: selectedTrait, meta: { trait: traitInfo[selectedTrait] } }}
            onSelected={handleSelectTrait}
            isMulti={false}
            InputContainer={TraitInputContainer}
            Item={TraitItem}
          />
        </SectionAction>

        <CFLineChart
          nested
          data={selectedCohortIDs.map((cohortId, index) => ({
            items:
              cohortData[Number(cohortId)]?.[selectedTrait]?.kde?.x?.map((xValue, index) => ({
                x: xValue,
                value: cohortData[Number(cohortId)]?.[selectedTrait]?.kde?.y?.[index],
              })) || [],
            name: createCohortTagName(cohortService.getCohort(cohortId)),
            color: CHART_COLORS[index],
          }))}
          isLoading={false}
          aggregationLevel={AggLevel.NA}
          axisType={AxisType.Value}
          showLegend={false}
        />

        <CFBinHistogram
          series={selectedCohortIDs.map((cohortId, index) => ({
            name: createCohortTagName(cohortService.getCohort(cohortId)),
            color: CHART_COLORS[index],
            data: (cohortData[Number(cohortId)]?.[selectedTrait]?.numerical_hist || []).map((bin: Bin) => ({
              min: bin.min,
              max: bin.max,
              count: bin.cnt,
            })),
          }))}
          title=""
          isLoading={false}
        />
      </CFTitledSection>

      <div className="non-gdt-analytics__scatter">
        {Array.from({ length: scatterCount }).map((_, index) => (
          <div key={index} className="non-gdt-analytics__scatter__item">
            <ScatterTrait data={cohortData} traitInfo={traitInfo} />
          </div>
        ))}
      </div>

      {traitPairs.length > 1 && <CFButton value="Add scatter" onClick={handleAddScatter} role={CFRole.Primary} />}
    </AnalyticsLayout>
  );
};

export default NonGdtExplorerInternal;
