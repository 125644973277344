import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { useServicesContext } from 'hooks/useServicesContext';

import { Steps } from '..';
import { useToast } from 'hooks';

import { CFRole } from 'domain/general.types';
import { Trait } from 'domain/traits.types';

import { useInterventionContext } from 'views/intervention/useContext';

import { GroupName, MarkovNode, NodeName, NodeUpdate, SimpleGraph, ThresholdTuple } from 'services/markov/markov.types';
import { MarkovPolicy } from 'services/intervention/intervention.types';
import { domainUpdatesToAPIEdits } from 'services/markov/helpers.markov';
import { generateStateGraph } from 'services/markov/markov.service';
import { getDisplayName } from 'services/traits/helpers.traits';

import CFInput from 'components/CFInput';
import { CFGraph } from 'components/charts/CFGraph';
import CFModal from 'components/CFModal';
import CFButton from 'components/buttons/CFButton';
import CFTitledSection, { TitleSize } from 'components/CFTitledSection';
import CFTitledComponent from 'components/CFTitledComponent';
import CFTrashButton from 'components/buttons/CFTrashButton';
import { ToastType } from 'components/CFToast/types';

import MarkovSubjectViewer from './MarkovSubjectViewer';
import NodesInformation from './NodesInformation';
import TupleCreator, { TupleCreatorRef } from './TupleCreator';
import NodeEditor, { EdgeUpdate } from './NodeEditor';
import BoxplotLoader, { BoxplotType } from '../metricsPolicy/BoxplotLoader';
import InterventionSection from '../interventionSection';

import useSampleSize from '../hooks/useSampleSize';
import useReadyForGeneration from './hooks/useReadyForGeneration';

const ADD_TUPLE_TEXT = '+ Add filter';
const GENERATE_GRAPHS_BUTTON = 'Generate graphs';

import './states.scss';

interface Props {
  onReady: (ready: boolean) => void;
}

export interface MarkovStatesRef {
  value: () => MarkovPolicy;
}

const MarkovStates = forwardRef<MarkovStatesRef, Props>(function InterventionParticipants({ onReady }: Props, ref) {
  const { sampleId, offset, selectedNudges, tuplesRef, tupleItems, setTupleItemsList } = useInterventionContext();
  const { traitSessionService, cohortService } = useServicesContext();
  const { addToast } = useToast();

  const budgetInputRef = useRef<HTMLInputElement>();
  const populationBlendInputRef = useRef<HTMLInputElement>();
  const gapInputRef = useRef<HTMLInputElement>();

  const [editingNode, setEditingNodeName] = useState<string>();
  const [nodes, setNodes] = useState<MarkovNode[]>([]);
  const [markovProcessId, setMarkovProcessId] = useState('');

  const [graphDefinition, setGraphDefinition] = useState<SimpleGraph>();

  const [lastChange, setLastChange] = useState(0);
  const [isGeneratingModel, setIsGeneratingModel] = useState(false);

  const allowGeneration = useReadyForGeneration({ budgetInputRef, populationBlendInputRef, tuplesRef, lastChange });

  const [selectedTraits, setSelectedTraits] = useState<Trait[]>([]);

  const sampleSize = useSampleSize();

  useImperativeHandle(ref, () => ({
    value() {
      // TODO: filter in a service to not assume what is the control id
      const groupNames = selectedNudges.filter((nudge) => nudge.id !== 0).map((nudge) => nudge.name);
      const populationBlend = parseFloat((populationBlendInputRef.current as HTMLInputElement).value);

      return {
        id: markovProcessId,
        nodes,
        budget: parseInt(budgetInputRef.current?.value || '0'),
        edits: { [groupNames[0]]: domainUpdatesToAPIEdits(updates[groupNames[0]]) },
        offset,
        population_blend: populationBlend,
        gap: parseInt(gapInputRef.current?.value || '0'),
      };
    },
  }));

  const handleAddNewTuple = useCallback(() => {
    setTupleItemsList((tupleItems) => [...tupleItems, new Date().valueOf()]); // save the new position

    setTimeout(async () => {
      const ptr = tuplesRef.current[tupleItems.length].value().trait;
      const trait = traitSessionService.getTraitDefinition(ptr);

      setSelectedTraits((traits) => [...traits, trait]);
    }, 10);
  }, [tupleItems]);

  const checkGenerationReady = useCallback(() => {
    setLastChange(Date.now());
    onReady(false);
  }, []);

  const handleGenerateGraphs = useCallback(async () => {
    const tuples = tupleItems.map((_, i) => {
      return tuplesRef.current[i].value();
    });

    const groupNames = selectedNudges.map((nudge) => nudge.name);
    const populationBlend = (populationBlendInputRef.current as HTMLInputElement).value;
    const gap = (gapInputRef.current as HTMLInputElement).value;

    setIsGeneratingModel(true);

    try {
      const [markovProcessId, nodes, graphDefinition] = await generateStateGraph(
        sampleId.value,
        groupNames,
        tuples,
        parseFloat(populationBlend),
        offset,
        parseInt(gap)
      );
      setMarkovProcessId(markovProcessId);
      setNodes(nodes);
      setGraphDefinition(graphDefinition);

      onReady(true);
    } catch {
      addToast('Error generating model', ToastType.ERROR);
    } finally {
      setIsGeneratingModel(false);
    }
  }, [tuplesRef, tuplesRef.current, tupleItems, selectedNudges, sampleId, offset]);

  const handleClickOnNode = useCallback((name: NodeName) => {
    setEditingNodeName(name);
  }, []);

  const handleNodeUpdate = useCallback((update: EdgeUpdate) => {
    setGraphDefinition((graphDefinition) => {
      const newGraph = { ...graphDefinition } as SimpleGraph;

      newGraph[update.from][update.to] = [update.factor];
      return newGraph;
    });

    setEditingNodeName(undefined);
  }, []);

  const nudgeNames = useMemo(() => {
    return selectedNudges.map((nudge) => nudge.name);
  }, [selectedNudges]);

  const updates: Record<GroupName, NodeUpdate[]> = useMemo(() => {
    if (!graphDefinition) {
      return {};
    }

    const groupName = nudgeNames.find((name) => name !== 'control');

    if (!groupName) {
      return {};
    }

    return {
      [groupName]: Object.keys(graphDefinition)
        .map((source) => {
          return Object.keys(graphDefinition[source]).map((target) => ({
            source,
            target,
            value: graphDefinition[source][target][0],
          }));
        })
        .flat()
        .filter((item) => !!item.value) as NodeUpdate[],
    };
  }, [graphDefinition, nudgeNames]);

  const handleDeleteTuple = useCallback(
    (index: number) => {
      tupleItems.splice(index, 1);
      setTupleItemsList([...tupleItems]);

      setSelectedTraits((traits) => {
        traits.splice(index, 1);
        return traits;
      });
    },
    [tupleItems]
  );

  const handleTupleChange = useCallback(
    async (index: number, tuple: ThresholdTuple) => {
      const trait = traitSessionService.getTraitDefinition(tuple.trait);

      if (trait) {
        setSelectedTraits((traits) => {
          traits[index] = trait;
          return traits;
        });
      }
      checkGenerationReady();
    },
    [tuplesRef]
  );

  return (
    <InterventionSection name={Steps.States} title={'States'} className="markov-states">
      <>
        {editingNode && (
          <CFModal
            onClose={() => {
              setEditingNodeName(undefined);
            }}
          >
            <NodeEditor name={editingNode} values={graphDefinition?.[editingNode] || {}} onChange={handleNodeUpdate} />
          </CFModal>
        )}

        <CFTitledSection title="Markov nodes definition" nested className="markov-definition">
          <CFTitledComponent
            title={'Budget'}
            subtitle={`Between 0 and sample size (${sampleSize}), inclusive`}
            className="budget-container"
          >
            <CFInput ref={(el) => (budgetInputRef.current = el as HTMLInputElement)} onChange={checkGenerationReady} />
          </CFTitledComponent>

          <CFTitledComponent
            title={'Population blend'}
            className="budget-container"
            subtitle="Between 0 and 1, inclusive"
          >
            <CFInput
              ref={(el) => (populationBlendInputRef.current = el as HTMLInputElement)}
              onChange={checkGenerationReady}
            />
          </CFTitledComponent>

          <CFTitledComponent title={'Gap'} className="budget-container" subtitle="">
            <CFInput ref={(el) => (gapInputRef.current = el as HTMLInputElement)} onChange={checkGenerationReady} />
          </CFTitledComponent>

          <div className="tuples">
            <>
              {Array(tupleItems.length)
                .fill('')
                .map((_, i) => {
                  return (
                    <div key={tupleItems[i]} className="removable-tuple">
                      <TupleCreator
                        notifyChange={(tuple: ThresholdTuple) => handleTupleChange(i, tuple)}
                        showTitles={i === 0}
                        ref={(el) => (tuplesRef.current[i] = el as TupleCreatorRef)}
                      />
                      <CFTrashButton onClick={() => handleDeleteTuple(i)} />
                    </div>
                  );
                })}

              <CFButton role={CFRole.Borderless} value={ADD_TUPLE_TEXT} onClick={handleAddNewTuple} />
            </>
          </div>

          <div className="boxplots">
            {selectedTraits.map((trait, traitIndex) => (
              <CFTitledSection key={traitIndex} title={getDisplayName(trait)} size={TitleSize.Small}>
                <BoxplotLoader
                  groupId={sampleId.value}
                  addr={trait.addr}
                  cohortService={cohortService}
                  type={BoxplotType.Sample}
                />
              </CFTitledSection>
            ))}
          </div>

          <CFButton
            role={CFRole.Primary}
            value={GENERATE_GRAPHS_BUTTON}
            onClick={handleGenerateGraphs}
            disabled={!allowGeneration}
            isLoading={isGeneratingModel}
          />
        </CFTitledSection>

        <CFTitledSection title="Model" nested>
          <div className="graph-definition">
            <CFGraph
              title={'Graph definition'}
              prefix="x "
              data={{ ...(graphDefinition || {}) }}
              onNodeClicked={(name) => {
                handleClickOnNode(name);
              }}
            />
            <NodesInformation nodes={nodes} />
          </div>
        </CFTitledSection>

        <CFTitledSection title="Subjects graphs" nested>
          <MarkovSubjectViewer processId={markovProcessId} updates={updates} />
        </CFTitledSection>
      </>
    </InterventionSection>
  );
});

export default MarkovStates;
