import { ModelType, QuestionType } from 'services/assistant/assistant.types';

export const modelsByType = {
  [QuestionType.Analytics]: [ModelType.OpenAI, ModelType.Deepseek, ModelType.Anthropic],
  [QuestionType.Docs]: [ModelType.OpenAI, ModelType.Anthropic],
};

export const modelTypeLabels = {
  [ModelType.OpenAI]: 'OpenAI',
  [ModelType.Deepseek]: 'DeepSeek',
  [ModelType.Anthropic]: 'Anthropic',
};
