import React, { useCallback, useState, Children } from 'react';

import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import './collapsible-content.scss';

interface Props {
  expandedSwitched?: (expanded: boolean) => void;
  children: JSX.Element | JSX.Element[];
  sameStyling?: boolean;
  nested?: boolean;
}

const Header = (props: any) => {
  return props.children;
};

const ExpandedHeader = (props: any) => {
  return props.children;
};

const CollapsedHeader = (props: any) => {
  return props.children;
};

const Content = (props: any) => {
  return props.children;
};

export const Collapsible = ({ children, expandedSwitched, nested = true }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const arrayChildren = Children.toArray(children);

  let expandedHeader = arrayChildren.find((child) => (child as any).type.name === ExpandedHeader.name);
  const collapsedHeader = arrayChildren.find((child) => (child as any).type.name === CollapsedHeader.name);

  if (!expandedHeader) {
    expandedHeader = collapsedHeader;
  }

  const content = arrayChildren.find((child) => (child as any).type.name === Content.name);

  const handleExpand = useCallback(() => {
    expandedSwitched && expandedSwitched(!expanded);
    setExpanded(!expanded);
  }, [expandedSwitched, expanded]);

  return (
    <div className={classNames('collapsible-content', { expanded, collapsed: !expanded, nested })}>
      <div className={classNames('summary', { selected: expanded, nested })} onClick={handleExpand}>
        <div className="content">{expanded ? expandedHeader : collapsedHeader}</div>

        <div className="arrow">
          <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} />
        </div>
      </div>

      {expanded && <div className={classNames('content', { selected: expanded, nested })}>{content}</div>}
    </div>
  );
};

Collapsible.ExpandedHeader = ExpandedHeader;
Collapsible.CollapsedHeader = CollapsedHeader;
Collapsible.Header = Header;
Collapsible.Content = Content;
