import React, { useCallback } from 'react';

import { faEraser } from '@fortawesome/free-solid-svg-icons';

import { useToast } from 'hooks';
import { useServicesContext } from 'hooks/useServicesContext';

import KeyValue from 'views/intervention/components/KeyValue';

import { BackfillReference, RunRecord } from 'services/traits/backfill.types.api';

import CFButton from 'components/buttons/CFButton';
import CFConfirmableButton from 'components/CFConfirmableButton';
import { ToastType } from 'components/CFToast/types';

import './reference-detail.scss';

interface Props {
  backfillRef: BackfillReference;
  runRecord: RunRecord;
}

const ReferenceDetail = ({ backfillRef, runRecord }: Props) => {
  const { backfillService } = useServicesContext();

  const { addToast } = useToast();

  const handleCancelBackfill = useCallback(async () => {
    try {
      await backfillService.cancel(backfillRef);
    } catch {
      addToast('Error canceling backfill', ToastType.ERROR);
    }
  }, [backfillService, backfillRef]);

  return (
    <div className="reference-detail">
      <div className="reference-detail__controls">
        <CFConfirmableButton title={'Cancel backfill'} question={'Are you sure to cancel this backfill?'}>
          <CFButton value="Cancel backfill" iconName={faEraser} onClick={handleCancelBackfill} />
        </CFConfirmableButton>
      </div>

      <div className="reference-detail__data">
        <KeyValue value={runRecord.run_ref} name={'Run Ref'} />
        <KeyValue value={runRecord.status} name={'Status'} />
        <KeyValue value={runRecord.ts_start} name={'Start'} />
        <KeyValue value={runRecord.ts_end} name={'End'} />

        <KeyValue value={<>{runRecord.cohort_ids.join(', ')}</>} name={'Cohorts'} />
        <KeyValue value={<>{runRecord.ptrs.join(', ')}</>} name={'Traits'} />
        <KeyValue value={<>{runRecord.n_ptrs}</>} name={'Trait counter  '} />
      </div>
    </div>
  );
};

export default ReferenceDetail;
