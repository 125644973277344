import { AxiosResponse } from 'axios';
import { get as httpGet, post as httpPost, remove as httpRemove, put as httpPut } from './drivers/http';

import { ProjectDefinition } from '../types';
import { TraitSubject } from 'domain/traits.types';
import { Module, Modules } from 'domain/general.types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/orgproj/proj';

const projectConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export interface CFProjectRepository {
  get: () => Promise<ProjectDefinition[]>;
  remove: (pid: number) => void;
  create: (
    name: string,
    description: string,
    orgId: number,
    subjects: TraitSubject[],
    modules: Module[]
  ) => Promise<any>;
  edit: (projectId: number, description: string, subjects: TraitSubject[], modules: Module[]) => Promise<any>;
}

interface ProjectRepoConfig {
  token: string;
  oid: number;
  pid: number;
}

export const init = ({ token, oid, pid }: ProjectRepoConfig) => {
  projectConfig.token = token;
  projectConfig.oid = oid;
  projectConfig.pid = pid;
};

export const get = async (): Promise<ProjectDefinition[]> => {
  const config = {};

  const projects = (await httpGet(
    `${serverBaseUrl}${path}?oid=${projectConfig.oid}&pid=${projectConfig.pid}`,
    config
  )) as AxiosResponse;

  return projects.data;
};

export const remove = async (pid: number) => {
  const config = {};

  try {
    await httpRemove(`${serverBaseUrl}${path}?oid=${projectConfig.oid}&pid=${pid}`, config);
  } catch (err) {
    console.error('error creating project: ', err);
    throw new Error('error-deleting-project');
  }
};

export const create = async (
  name: string,
  description: string,
  orgId: number,
  subjects: TraitSubject[],
  modules: Modules[]
): Promise<any> => {
  const config = {};

  const body = {
    name,
    description,
    oid: orgId,
    pid: projectConfig.pid,
    subjects,
    modules,
  };

  try {
    await httpPost(`${serverBaseUrl}${path}?oid=${orgId}&pid=${projectConfig.pid}`, body, config);
  } catch (err) {
    console.error('error creating project: ', err);
    throw new Error('error-creating-project');
  }
};

export const edit = async (
  projectId: number,
  description: string,
  subjects: TraitSubject[],
  modules: Module[],
  analyticsPrompt: string,
  docsPrompt: string,
  telegramToken: string,
  telegramBotType: string,
  churnDefinition: number
): Promise<any> => {
  const config = {};

  const body = {
    oid: projectConfig.oid,
    pid: projectId,
    description,
    subjects,
    modules,
    analytics_prompt: analyticsPrompt,
    docs_prompt: docsPrompt,
    telegram_bot_token: telegramToken,
    telegram_bot_type: telegramBotType,
    churn_definition: churnDefinition,
  };

  try {
    await httpPut(`${serverBaseUrl}${path}?oid=${projectConfig.oid}&pid=${projectId}`, body, config);
  } catch (err) {
    throw new Error((err as any).response.data.message);
  }
};

export default {
  get,
  remove,
  create,
  edit,
};
