import React, { useState } from 'react';

import { Collapsible } from 'components/CollapsibleContent';
import ChunkItem from 'connected-components/ChunkItem';
import { AssistantMessage } from 'services/assistant/assistant.types';

import './chunk-renderer.scss';
import CFPaginatedList from 'components/CFPaginatedList';

interface Props {
  message: AssistantMessage;
}

const DEFAULT_PAGE_SIZE = 5;

const ChunkRenderer = ({ message }: Props) => {
  if (!message.payload?.ref_chunks) {
    return null;
  }

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentPageSize, setCurrentPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page - 1);
    setCurrentPageSize(pageSize);
  };

  return (
    <div>
      <CFPaginatedList
        total={message.payload.ref_chunks.length}
        pageSizeList={[5, 10, 20]}
        onPageChange={handlePageChange}
        includeSeparator={true}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        className="chunk-renderer"
      >
        {message.payload.ref_chunks
          .slice(currentPage * currentPageSize, (currentPage + 1) * currentPageSize)
          .map((chunk, i) => (
            <Collapsible sameStyling={true} key={i}>
              <Collapsible.CollapsedHeader>
                <div className="chunk-title">
                  <span>
                    {chunk.doc_name}, page {chunk.page_num} (score: {chunk.score})
                  </span>

                  <span>{chunk.chunk_type}</span>
                </div>
              </Collapsible.CollapsedHeader>

              <Collapsible.Content>
                <ChunkItem key={i} chunk={chunk} showHeader={false} />
              </Collapsible.Content>
            </Collapsible>
          ))}
      </CFPaginatedList>
    </div>
  );
};

export default ChunkRenderer;
