import React, { FC } from 'react';

import './cf-spinner.scss';
interface ICFSpinner {
  size?: number;
  color?: 'string';
  stroke?: number;
}

const CFSpinner: FC<ICFSpinner> = ({ size = 10, color = '#818a91', stroke = 2 }) => {
  return (
    <div
      className={'cf-spinner'}
      style={{ width: `${size}px`, height: `${size}px`, borderLeftColor: color, borderWidth: `${stroke}px` }}
    ></div>
  );
};

export default CFSpinner;
