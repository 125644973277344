import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Trait, TraitCategory, TraitSubject } from 'domain/traits.types';
import { Modules } from 'domain/general.types';

import TraitExplorerModal from 'connected-components/traits/TraitExplorer';

import { getCurrentProject } from 'services/session/session.service';
import { createTraitCode, getDisplayName, subjectMatches } from 'services/traits/helpers.traits';

import CFSelect, { Option } from 'components/CFSelect';
import TraitItem from 'components/CFSelect/common/TraitItem';

import { useAnalyticsContext } from 'views/analytics/explore/context/useAnalyticsContext';
import { useServicesContext } from 'hooks/useServicesContext';

import { TraitAction } from 'views/analytics/explore/hooks/useInitializedTraits';

import './filter-controls.scss';
import { selectCohort } from 'views/analytics/explore/services/cohort';

const FilterControls = () => {
  const { cohortService, traitSessionService: traitService } = useServicesContext();

  const {
    subject,
    module,
    aggLevel,
    setModule,
    setSubject,
    dispatchTraitAction,
    traitsState,
    setSelectedCohortIDs,
    selectedCohortIDs,
    availableCohorts,
    explorationMode,
  } = useAnalyticsContext();
  const project = getCurrentProject();

  const [showTraitSelector, setShowTraitSelector] = useState(false);
  const [traitSearch, setTraitSearch] = useState('');
  const [cohortSearch, setCohortSearch] = useState('');
  const [traits, setTraits] = useState<Trait[]>([]);

  useEffect(() => {
    (async () => {
      if (!module || !subject) {
        return;
      }
      // TODO: mover a context??

      const allTraits = await traitService.getTraits({
        subject,
        module: module as Modules,
        aggLevel,
        category: TraitCategory.Dynamic,
        visibility: false,
      });
      setTraits(allTraits);
    })();
  }, [subject, module, aggLevel]);

  const selectableCohorts = (availableCohorts ?? []).map((item) => ({
    label: `${item.name} (${item.size})`,
    value: item.id.toString(),
  }));

  const selectableSubjects = (project?.subjects || []).map((subject_type) => ({
    label: subject_type,
    value: subject_type,
  }));

  const traitOptions = useMemo(() => {
    return traits
      .map((trait) => {
        return {
          label: getDisplayName(trait),
          value: createTraitCode(trait),
          meta: {
            trait: trait,
            simple: true,
          },
        };
      })
      .filter((option) => {
        return !traitSearch.trim() || option.label.toLowerCase().includes(traitSearch.toLowerCase());
      });
  }, [traits, traitSearch]);

  const handleSelectCohort = useCallback(
    (item: Option) => {
      //  setSelectedCohortIDs([item.value]);

      setSelectedCohortIDs((prev) => {
        const newIDs = selectCohort(
          parseInt(item.value),
          prev.map((item) => parseInt(item)),
          availableCohorts ?? []
        );

        return newIDs.map((item) => `${item}`);
      });
    },
    [availableCohorts]
  );

  const handleModuleChange = useCallback((item: Option) => {
    setModule(item.value);
  }, []);

  const handleSelectSubject = useCallback((item: Option) => {
    setSubject(item.value as TraitSubject);
  }, []);

  const traitValues = useMemo(() => {
    return (traitsState.traits[aggLevel] || [])
      .filter((ptr) => {
        return subjectMatches(ptr, subject);
      })
      .map((traitCode) => {
        const trait = traitService.getTraitDefinition(traitCode);
        if (!trait) {
          return { label: '', value: '' };
        }

        return {
          label: getDisplayName(trait),
          value: traitCode,
        };
      });
  }, [traitsState, aggLevel, traitService]);

  const handleSelectTrait = useCallback(
    (option: Option) => {
      const previousSelected = traitValues.find((selectedOption) => option.value === selectedOption.value);

      const trait = traitService.getTraitDefinition(option.value);
      dispatchTraitAction({ action: previousSelected ? TraitAction.REMOVE : TraitAction.ADD, ptr: trait.addr.ptr });
    },
    [traitValues]
  );

  const handleClearTraits = useCallback(() => {
    dispatchTraitAction({ action: TraitAction.CLEAR, aggLevel });
  }, []);

  const handleOpenExplorer = useCallback(() => {
    setShowTraitSelector(true);
  }, []);

  const handleCancelExplorer = useCallback(() => {
    setShowTraitSelector(false);
  }, []);

  const handleExplorerTraitSelected = useCallback((traits: Trait[]) => {
    dispatchTraitAction({ action: TraitAction.LOAD, ptrs: traits.map((trait) => trait.addr.ptr) });

    setShowTraitSelector(false);
  }, []);

  return (
    <>
      {showTraitSelector && (
        <TraitExplorerModal
          traits={traits}
          onCancel={handleCancelExplorer}
          onTraitSelected={handleExplorerTraitSelected}
          isMulti={true}
          defaultSelectedTraits={traitsState.traits[aggLevel]?.map((ptr) => traitService.getTraitDefinition(ptr))}
        />
      )}

      <div className={classNames('control-container', { 'exploration-mode': explorationMode })}>
        <div className="control-container__form">
          <div className="form-group">
            <label>Subject</label>
            <CFSelect
              value={{
                label: subject,
                value: subject,
              }}
              options={selectableSubjects}
              onSelected={handleSelectSubject}
              disabled={explorationMode}
            />
          </div>
          <div className="form-group">
            <label>Module</label>
            <CFSelect
              options={project?.modules?.map((module) => ({ label: module, value: module })) || []}
              value={{ label: module, value: module }}
              onSelected={handleModuleChange}
              disabled={explorationMode}
            />
          </div>
          <div className="form-group">
            <label>Cohort</label>
            <CFSelect
              options={selectableCohorts.sort().filter((option) => {
                return !cohortSearch.trim() || option.label.toLowerCase().includes(cohortSearch.toLowerCase());
              })}
              value={
                selectedCohortIDs.map((cid) => ({
                  label: cohortService.getCohort(cid)?.name || 'unknown',
                  value: cid,
                })) || [{ label: '', value: '' }]
              }
              isMulti={true}
              onSelected={handleSelectCohort}
              searchable
              onSearch={setCohortSearch}
            />
          </div>

          <div className="form-group">
            <label>Trait</label>
            <CFSelect
              options={traitOptions}
              value={traitValues.length > 0 ? traitValues : []}
              onSelected={handleSelectTrait}
              onClear={handleClearTraits}
              isMulti={true}
              searchable
              onSearch={setTraitSearch}
              Item={TraitItem}
              onExpandRequest={handleOpenExplorer}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterControls;
