import React, { useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment';

import { useServicesContext } from 'hooks/useServicesContext';

import { Tabs } from '../tabs';
import { toString } from 'helpers/dates';
import { useToast } from 'hooks';

import { Trait } from 'domain/traits.types';
import { CFRole, TimeRFC3999 } from 'domain/general.types';

import { getCurrentProject } from 'services/session/session.service';

import { TraitExplorerRef } from 'connected-components/traits/TraitExplorer/traitExplorer';
import { TraitExplorer } from 'connected-components/traits/TraitExplorer';

import CFTitledSection, { SectionAction } from 'components/CFTitledSection';
import CFButton from 'components/buttons/CFButton';
import DatetimeRangePicker from 'components/DateTime/DatetimeRangePicker';
import { DateRange, DefinedRange } from 'components/DateTime/DatetimeRangePicker/types';
import { defaultRanges } from 'components/DateTime/DatetimeRangePicker/default';
import { ToastType } from 'components/CFToast/types';

import TraitsLayout from '../layout';

const TraitExplorerView = () => {
  const project = getCurrentProject();
  const { addToast } = useToast();

  const { backfillService, traitSessionService: traitService } = useServicesContext();
  const [traits, setTraits] = useState<Trait[] | null>(null);
  const traitExplorerRef = useRef<TraitExplorerRef>(null);

  const [startDate, setStartDate] = useState<TimeRFC3999>(project?.created_at || '');
  const [endDate, setEndDate] = useState<TimeRFC3999>(toString(new Date()));

  useEffect(() => {
    // hack to avoid UI blocking
    setTimeout(() => {
      traitService.getTraits({}).then((traits) => setTraits(traits));
    }, 0);
  }, []);

  const handleBackfill = useCallback(async () => {
    const traits = traitExplorerRef.current?.value();
    try {
      await backfillService.backfillTrait(
        startDate,
        endDate,
        [],
        true,
        (traits || []).map((trait) => trait.addr.ptr)
      );
    } catch {
      addToast('Error backfilling', ToastType.ERROR);
    }
  }, [traitExplorerRef, traits]);

  const handleRangeChange = useCallback((dateRange: DateRange) => {
    if (dateRange.startDate && dateRange.endDate) {
      setStartDate(toString(dateRange.startDate));
      setEndDate(toString(dateRange.endDate));
    }
  }, []);

  const defaultDateRanges: DefinedRange[] = [
    ...defaultRanges,
    {
      label: 'Full history',
      startDate: moment(project?.created_at ?? 0).toDate(),
      endDate: new Date(),
    },
  ];

  if (!traits) {
    // if TraitsExplorer is initialized directly with empty traits,
    // some tags to select in the lateral form are not available yet
    return (
      <TraitsLayout className="trait-definitions" tab={Tabs.Explorer}>
        <></>
      </TraitsLayout>
    );
  }

  return (
    <TraitsLayout className="trait-definitions" tab={Tabs.Explorer}>
      <CFTitledSection title="Trait explorer">
        <SectionAction>
          <DatetimeRangePicker
            onChange={handleRangeChange}
            definedRanges={defaultDateRanges}
            initialDateRange={{
              startDate: new Date(startDate),
              endDate: new Date(endDate),
            }}
            maxDate={new Date()}
            showTime={false}
          />
        </SectionAction>

        <SectionAction>
          <CFButton value="Backfill" onClick={handleBackfill} role={CFRole.Primary} />
        </SectionAction>

        <TraitExplorer
          ref={traitExplorerRef}
          traits={traits}
          isMulti={true}
          fullWidth={true}
          onCancel={function (): void {
            throw new Error('Function not implemented.');
          }}
          onTraitSelected={() => ({})}
        />
      </CFTitledSection>
    </TraitsLayout>
  );
};

export default TraitExplorerView;
