import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { get as getProjects, edit as editProject } from 'repositories/projects';

import { ProjectDefinition } from 'types';
import { CFRoutes } from 'routes';

import useToast from 'hooks/useToast';
import useCFNavigation from 'hooks/useCFNavigation';

import { CFRole, Modules } from 'domain/general.types';
import { TraitSubject } from 'domain/traits.types';

import useSubjectTypes from 'services/traits/hooks/useSubjectTypes';

import AdminLayout from 'views/admin/layout';
import { Tabs } from 'views/admin/tabs';

import CFTextarea from 'components/textarea/CFTextarea';
import CFButton from 'components/buttons/CFButton';
import CFInput from 'components/CFInput';
import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';
import CFTitledComponent from 'components/CFTitledComponent';
import { ToastType } from 'components/CFToast/types';
import { CFReferencedSelect, CFReferencedSelectRef } from 'components/CFSelect';

enum TelegramBotType {
  Analytics = 'analytics',
  Docs = 'docs',
}

import './edit-project.scss';

const EditProject = () => {
  const navigate = useCFNavigation();
  const subjectTypes = useSubjectTypes();

  const params = useParams();
  const { addToast } = useToast();
  const subjectsRef = React.createRef<any>();
  const modulesRef = React.createRef<any>();
  const telegramBotTypeRef = React.createRef<CFReferencedSelectRef>();

  const [project, setProject] = useState<ProjectDefinition>();

  useEffect(() => {
    (async () => {
      try {
        const projects = await getProjects();

        const project = projects.find((project) => `${project.id}` === params.project);
        setProject(project);
      } catch (err) {
        console.log('error getting projects to edit');
      }
    })();
  }, []);

  const handleEditProject = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const telegramBotType = (telegramBotTypeRef.current?.value() as string) || '';

    try {
      await editProject(
        parseInt(params.project as string),
        formData.get('description') as string,
        (subjectsRef.current as any).getValue().map((item: SelectableItem) => item.value.toLowerCase()),
        (modulesRef.current as any).getValue().map((item: SelectableItem) => item.value.toLowerCase()),
        formData.get('analytics-prompt') as string,
        formData.get('docs-prompt') as string,
        formData.get('telegram-bot-token') as string,
        telegramBotType,
        Number(formData.get('churn-definition')) || 0
      );
    } catch (err) {
      addToast((err as any).message, ToastType.ERROR, 5000);
      return;
    }

    navigate(CFRoutes.admin_project);
  };

  const handleCancelUpdate = async () => {
    navigate(CFRoutes.admin_project);
  };

  if (!project?.subjects) {
    return <div></div>;
  }

  return (
    <AdminLayout tab={Tabs.Projects}>
      <form onSubmit={handleEditProject}>
        <div className="edit-project-container">
          <div className="input">
            <CFTitledComponent title={'Description'}>
              <CFInput defaultValue={project?.description} name="description" />
            </CFTitledComponent>

            <CFTitledComponent title={'Subjects'}>
              <CFSelectLegacy
                ref={subjectsRef}
                options={subjectTypes.map((subject) => ({
                  value: subject,
                  label: subject,
                  selected: project?.subjects?.find((projectSubject) => projectSubject === subject) !== undefined,
                }))}
                mandatoryValueOptions={[TraitSubject.User]}
                isMulti={true}
                defaultOption={project?.subjects?.map((subject) => ({
                  value: subject,
                  label: subject,
                }))}
              />
            </CFTitledComponent>
          </div>

          <CFTitledComponent title={'Modules'}>
            <CFSelectLegacy
              ref={modulesRef}
              options={Object.values(Modules).map((module) => ({
                value: module,
                label: module,
                selected: project?.modules?.find((projectModule) => projectModule === module) !== undefined,
              }))}
              mandatoryValueOptions={[Modules.Core]}
              isMulti={true}
              defaultOption={project?.modules?.map((subject) => ({
                value: subject,
                label: subject,
              }))}
            />
          </CFTitledComponent>

          <CFTitledComponent title="Churn definition">
            <CFInput defaultValue={project.churn_definition} name="churn-definition" />
          </CFTitledComponent>

          <CFTitledComponent title="Telegram Bot Token">
            <CFInput defaultValue={project.telegram_bot_token} name="telegram-bot-token" />
          </CFTitledComponent>

          <CFTitledComponent title="Telegram Bot Type">
            <CFReferencedSelect
              defaultValue={project.telegram_bot_type}
              ref={telegramBotTypeRef}
              options={Object.values(TelegramBotType).map((type) => ({
                value: type,
                label: type,
              }))}
              value={{ label: project.telegram_bot_type, value: project.telegram_bot_type }}
              isMulti={false}
            />
          </CFTitledComponent>

          <CFTitledComponent title="Analytics prompt">
            <CFTextarea defaultValue={project.analytics_prompt} name="analytics-prompt" />
          </CFTitledComponent>

          <CFTitledComponent title="Docs prompt">
            <CFTextarea defaultValue={project.docs_prompt} name="docs-prompt" />
          </CFTitledComponent>

          <div className="controls">
            <CFButton value={'Cancel'} onClick={handleCancelUpdate} />
            <CFButton value={'Update'} onClick={() => ({})} role={CFRole.Primary} />
          </div>
        </div>
      </form>
    </AdminLayout>
  );
};

export default EditProject;
