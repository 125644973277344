import { Trait } from 'domain/traits.types';
import TraitExplorer from './traitExplorer';
import TraitExplorerModal from './traitExplorerModal';

import './trait-explorer.scss';

export interface TraitExplorerProps {
  traits: Trait[];
  defaultSelectedTraits?: Trait[];
  isMulti?: boolean;
  fullWidth?: boolean;
  onCancel: () => void;
  onTraitSelected: (traits: Trait[]) => void;
}

export { TraitExplorer, TraitExplorerModal };

export default TraitExplorerModal;
