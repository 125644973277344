import React, { useMemo } from 'react';

import { InterventionViewExtended } from 'services/intervention/intervention.types';
import NudgeDetail from 'connected-components/nudges/NudgeDetail';

import CFTable, { ColumnType } from 'components/CFTable';
import { NudgeType } from 'services/nudging/nudge.types';
import CFTitledSection from 'components/CFTitledSection';
import { Steps } from 'views/intervention/interventions/NewIntervention';

interface Props {
  intervention: InterventionViewExtended;
  className: string;
  step: Steps;
}

const GroupsDefinition = ({ intervention, step, className = '' }: Props) => {
  const columns = [
    {
      title: 'Group',
      field: 'group',
      type: ColumnType.STRING,
    },
    {
      title: 'Nudge',
      field: 'nudge',
      type: ColumnType.STRING,
    },
    {
      title: 'Title',
      field: 'title',
      type: ColumnType.STRING,
    },
    {
      title: 'Type',
      field: 'type',
      type: ColumnType.STRING,
    },
    {
      title: '',
      field: '',
      type: ColumnType.OBJECT,
      expandable: true,
      renderCell: (row: any) => {
        if (!row.raw) {
          return <div>No nudge</div>;
        }

        return <NudgeDetail nudge={row.raw} />;
      },
    },
  ];

  const data = useMemo(() => {
    return Object.keys(intervention.intervention.nudge_policy.group_allocation).map((groupKey) => {
      const group = intervention.nudge_group_allocation[groupKey];

      return {
        group: groupKey,
        nudge: group?.name || '<no name>',
        type: group?.definition.type,
        raw: group,
        title:
          group?.definition.type === NudgeType.Message
            ? group?.definition.message.title
            : group?.definition.recommend?.model_id,
      };
    });
  }, []);

  return (
    <CFTitledSection title="Groups" className={className} id={step}>
      <CFTable headers={columns} data={data} indexCol="group" />
    </CFTitledSection>
  );
};

export default GroupsDefinition;
