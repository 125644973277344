import { AlgorithmClass } from 'services/intervention/intervention.types';

export const cohortSelectorDescription = {
  [AlgorithmClass.Bandit]: 'Select the users for your model',
  [AlgorithmClass.BanditV2]: 'Select the users for your model',
  [AlgorithmClass.Censoring]: 'Select the users for your model',
  [AlgorithmClass.RestlessBandit]: 'Select the users for your model',
  [AlgorithmClass.Forecasting]: 'Select the users for your model',
  [AlgorithmClass.Recommender]: 'Select the users who interact with the items for your recommender model',
  [AlgorithmClass.Embedding]: 'Select the users for your model',
};
