import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import ModelService from 'services/model/model.service';

import { useInterventionContext } from 'views/intervention/useContext';

import CFInput from 'components/CFInput';
import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';
import CFTextarea from 'components/textarea/CFTextarea';
import CFTitledComponent from 'components/CFTitledComponent';
import CFSelect, { Option } from 'components/CFSelect';

import InterventionSection from '../interventionSection';
import {
  AlgorithmClass,
  AlgorithmName,
  AlgorithmPolicy,
  AlgorithmType,
} from 'services/intervention/intervention.types';
import Select from 'react-select/dist/declarations/src/Select';
import { Steps } from '..';

import './intervention-definition.scss';
import CFSwitch from 'components/CFSwitch';

interface Props {
  onReady: (ready: boolean) => void;
  modelService: ModelService;
  defaultValue?: InterventionDefinitionParams;
}

const NullInterventionDefinitionParams: InterventionDefinitionParams = {
  name: '',
  description: '',
  tags: [],
  algorithm: {
    type: AlgorithmType.Bandit,
    spec: {
      class_name: AlgorithmClass.Bandit,
      algo_name: AlgorithmName.ExploreThenCommit,
    },
  },
};

export interface InterventionDefinitionParams {
  name: string;
  description: string;
  algorithm: AlgorithmPolicy;
  tags: string[];
}

export interface InterventionDefinitionRef {
  value: () => InterventionDefinitionParams;
}

const InterventionDefinition = forwardRef<InterventionDefinitionRef, Props>(function InterventionDefinition(
  { defaultValue = NullInterventionDefinitionParams, modelService, onReady },
  ref
) {
  const nameInputRef = useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
  const descriptionInputRef = React.createRef<HTMLTextAreaElement>();

  const { algorithmType, setAlgorithmType, isCloning, isDraft, setAlgorithmName } = useInterventionContext();

  const [algorithmNameOptions, setAlgorithmNameOptions] = useState<Option[]>([]);
  const algorithmTypeRef = useRef<Select>() as React.MutableRefObject<Select>;
  const [algorithm, setAlgorithm] = useState<Option>({ label: '', value: '' });
  const [isTest, setIsTest] = useState(false);

  useImperativeHandle(ref, () => ({
    value() {
      const algorithmType = (algorithmTypeRef.current?.getValue()[0] as SelectableItem).value;

      let algorithmConfig: AlgorithmPolicy = {
        type: AlgorithmType.ABTest,
      };

      if (algorithmType !== AlgorithmType.ABTest) {
        algorithmConfig = {
          type: algorithmType as AlgorithmType,
          spec: {
            class_name: algorithmType as AlgorithmClass,
            algo_name: algorithm.value as AlgorithmName,
            class_property: {},
          },
        };
      }

      return {
        name: nameInputRef.current?.value || '',
        description: descriptionInputRef.current?.value || '',
        algorithm: algorithmConfig,
        tags: isTest ? ['test'] : [],
      };
    },
  }));

  useEffect(() => {
    (async () => {
      if (algorithmType === AlgorithmType.ABTest) {
        return;
      }

      // TODO: Review when to use class and when type
      const algorithmNames = await modelService.getAlgorithmNames(algorithmType as unknown as AlgorithmClass);

      const algorithmNameOptions = algorithmNames.map((algoMeta) => ({
        label: algoMeta.alias,
        value: algoMeta.algo_name,
      }));

      setAlgorithmNameOptions(algorithmNameOptions);
      setAlgorithm(algorithmNameOptions[0]);
      setAlgorithmName(algorithmNameOptions[0].value);
    })();
  }, [algorithmType]);

  useEffect(() => {
    const algorithm = algorithmNameOptions.find((option) => option.value === defaultValue.algorithm.spec?.algo_name);

    if (!algorithm) {
      return;
    }

    setAlgorithm({ value: algorithm.value, label: algorithm.label });
    setAlgorithmName(algorithm.value);
  }, [algorithmNameOptions, defaultValue.algorithm.spec]);

  useEffect(() => {
    checkReadiness();
  }, [algorithmType, algorithm]);

  const handleSelectedAlgorithm = (option: Option) => {
    setAlgorithm(option);

    setTimeout(() => {
      setAlgorithmName(option.value);

      checkReadiness();
    }, 0);
  };

  const checkReadiness = useCallback(() => {
    const isAlgorithmConfigured = algorithmType === AlgorithmType.ABTest || algorithm !== undefined;

    onReady(nameInputRef.current?.value !== '' && isAlgorithmConfigured);
  }, [algorithmType, algorithm]);

  const handleAlgorithmTypeSelected = useCallback((selectedAlgorithm: SelectableItem[]) => {
    setAlgorithmType(selectedAlgorithm[0].value as AlgorithmType);
  }, []);

  const algorithmNameDropdown = useMemo(() => {
    if (algorithmType === AlgorithmType.ABTest) {
      return <></>;
    }

    return (
      <CFTitledComponent title={'Algorithm'} className="algorithm">
        <CFSelect onSelected={handleSelectedAlgorithm} options={algorithmNameOptions} value={algorithm} />
      </CFTitledComponent>
    );
  }, [algorithmType, algorithm]);

  const defaultInterventionName = useMemo(() => {
    if (isDraft) {
      return defaultValue.name;
    }

    if (isCloning) {
      return `${defaultValue.name} (cloned)`;
    }

    return '';
  }, [isDraft, isCloning]);

  const handleSetTest = useCallback(() => {
    setIsTest((value) => !value);
  }, []);

  return (
    <InterventionSection name={Steps.Definition} title={'Definition'}>
      <div className="intervention-definition intervention-section">
        <CFTitledComponent title={'Name'} className="name">
          <CFInput ref={nameInputRef} onChange={checkReadiness} defaultValue={`${defaultInterventionName}`} />
        </CFTitledComponent>

        <CFTitledComponent title={'Algorithm type'} className="type">
          <CFSelectLegacy
            ref={algorithmTypeRef}
            onSelected={handleAlgorithmTypeSelected}
            //            defaultOption={[{ label: defaultValue.algorithm.type, value: defaultValue.algorithm.type }]}
            defaultOption={[{ label: algorithmType, value: algorithmType }]}
            options={Object.values(AlgorithmType).map((type) => ({ label: type, value: type }))}
          />
        </CFTitledComponent>

        {algorithmNameDropdown}

        <CFTitledComponent title={'Description'} className="description">
          <CFTextarea ref={descriptionInputRef} defaultValue={defaultValue.description} />
        </CFTitledComponent>

        <div className="test-definition">
          <span>Test Intervention</span>
          <CFSwitch checked={isTest} onChange={handleSetTest} />
        </div>
      </div>
    </InterventionSection>
  );
});

export default InterventionDefinition;
