import React, { useEffect, useState } from 'react';

import CFTraitItem from 'connected-components/traits/CFTraitItem';
import CFTitledComponent from 'components/CFTitledComponent';
import Box from 'components/Box';

import { AlgorithmClass } from 'services/intervention/intervention.types';
import { CensoringTarget, Model, eventTypeStrings, originTypeStrings } from 'domain/model.types';
import { Trait } from 'domain/traits.types';

import { useServicesContext } from 'hooks/useServicesContext';

import './target.scss';

interface Props {
  model: Model;
}

const ModelTarget = ({ model }: Props) => {
  const { traitSessionService: traitService } = useServicesContext();
  const [rewardTrait, setRewardTrait] = useState<Trait>();
  const [eventDescription, setEventDescription] = useState<CensoringTarget['event_description']>();
  const [originDescription, setOriginDescription] = useState<CensoringTarget['origin_description']>();

  const isBandit = model.definition?.algo_spec.class_name === AlgorithmClass.Bandit;
  const isCensoring = model.definition?.algo_spec.class_name === AlgorithmClass.Censoring;
  const isRecommender = model.definition?.algo_spec.class_name === AlgorithmClass.Recommender;

  useEffect(() => {
    if (isBandit && model.definition.data_defn.bandit?.reward) {
      setRewardTrait(traitService.getTraitFromAddr(model.definition.data_defn.bandit?.reward));
    }

    if (isCensoring && model.definition.data_defn.censoring) {
      setEventDescription(model.definition.data_defn.censoring.event_description);
      setOriginDescription(model.definition.data_defn.censoring.origin_description);
    }
  }, [traitService.traitDefinitions]);

  return (
    <div className="model-target">
      {isCensoring && (
        <>
          {eventDescription && (
            <CFTitledComponent title={'Event'}>
              <div className="model-target__row">
                <Box className="field">{eventTypeStrings[eventDescription.type]}</Box>
                <Box className="field">{eventDescription.definition} days</Box>
              </div>
            </CFTitledComponent>
          )}

          {originDescription && (
            <CFTitledComponent title={'Origin'}>
              <div className="model-target__row">
                <Box className="field">{originTypeStrings[originDescription.type]}</Box>
                <Box className="field">{originDescription.definition} days</Box>
              </div>
            </CFTitledComponent>
          )}

          {model.definition.data_defn.censoring?.validation_delta !== undefined && (
            <CFTitledComponent title={'Validation delta'}>
              <Box className="field">{model.definition.data_defn.censoring?.validation_delta}</Box>
            </CFTitledComponent>
          )}

          {model.definition.data_defn.censoring?.look_back_time_in_days !== undefined && (
            <CFTitledComponent title={'Look back time in days'}>
              <Box className="field">{model.definition.data_defn.censoring?.look_back_time_in_days}</Box>
            </CFTitledComponent>
          )}
        </>
      )}
      {isBandit && rewardTrait && (
        <CFTitledComponent title={'Reward'}>
          <Box className="field">
            <CFTraitItem addr={rewardTrait?.addr} />
          </Box>
        </CFTitledComponent>
      )}

      {isRecommender && (
        <CFTitledComponent title={'Subject'}>
          <Box className="field">
            <span>{model.cohort.subject_type} </span>
          </Box>
        </CFTitledComponent>
      )}
    </div>
  );
};

export default ModelTarget;
