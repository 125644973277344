import React, { useMemo, forwardRef, useEffect, useState, useCallback, useImperativeHandle, useRef } from 'react';

import InterventionSection from '../interventionSection';

import { ColAddr, Trait, TraitCategory, TraitSubject, TraitUsage } from 'domain/traits.types';
import { CFRole } from 'domain/general.types';

import { areEqual, createTraitCode, getTraitCategory } from 'services/traits/helpers.traits';
import { MetricPolicy } from 'services/intervention/intervention.types';
import VOSampleId from 'services/intervention/domain/VOSampleId';

import { useInterventionContext } from 'views/intervention/useContext';

import CFTitledComponent, { TitleSize } from 'components/CFTitledComponent';
import CFTitledSection, { SectionAction } from 'components/CFTitledSection';
import CFTabs from 'components/CFTabs';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import CFButton from 'components/buttons/CFButton';
import CFSelect, { Option } from 'components/CFSelect';
import TagInputContainer from 'components/CFSelect/common/TagInputContainer';
import TimeWindowPicker, { TimeWindowPickerRef } from 'components/TimeWindowPicker';

import TraitsTable from 'connected-components/TraitsTable';
import TraitListItem from './TraitsListItem';

import { Steps } from '..';

import { capitalize } from 'helpers/misc';

import { InterventionMetricsPolicyRef } from './metrics-policy.typings';

import { useServicesContext } from 'hooks/useServicesContext';

import './metrics-policy.scss';
import { Ptr } from 'services/cohort/cohort.types.api';

export interface SelectedTraits {
  objective: Trait[];
  supporting: Trait[];
  contextual: Trait[];
}

interface Props {
  defaultValue?: MetricPolicy;
  subject: TraitSubject | undefined;
  sampleId?: VOSampleId;
  onReady: (ready: boolean) => void;
}

export enum MetricsType {
  objective = 'objective',
  supporting = 'supporting',
  contextual = 'contextual',
}

const availableContextualCategories = [
  { label: 'Static', value: TraitCategory.Static },
  { label: 'Dynamic', value: TraitCategory.Dynamic },
  { label: 'Catalog', value: TraitCategory.Catalogue },
];

const NullSelectedTraits = {
  objective: [],
  supporting: [],
  contextual: [],
};

const InterventionBanditMetricsPolicyV2 = forwardRef<InterventionMetricsPolicyRef, Props>(
  function InterventionBanditMetricsPolicy({ sampleId, defaultValue, subject, onReady }: Props, ref) {
    const { cohortService, traitSessionService: traitService } = useServicesContext();
    const [totalTraits, setTotalTraits] = useState<Trait[]>([]);
    const [selected, setSelected] = useState<SelectedTraits>(NullSelectedTraits);

    const [contextTraits, setContextTraits] = useState<Trait[]>([]);
    const { regenerateSample, sampling, setIsMicrobatching } = useInterventionContext();
    const monitoringWindowRef = useRef<TimeWindowPickerRef | null>();
    const coolDownPeriodRef = useRef<TimeWindowPickerRef | null>();

    const [weights, setWeights] = useState<Record<Ptr, number>>({});

    const [categoriesForContextual, setCategoriesForContextual] = useState<Option[]>(availableContextualCategories);

    useEffect(() => {
      if (!subject) {
        return;
      }

      Promise.all([
        traitService.getTraits({ subject, usage: TraitUsage.BanditMetric }),
        traitService.getBanditContextStaticTraits(subject),
        traitService.getContextDynamicTraits(subject, TraitUsage.BanditContext),
      ])
        .then(([traits, contextStaticTraits, contextDynamicTraits]) => {
          setTotalTraits(traits);
          setContextTraits([...contextStaticTraits, ...contextDynamicTraits]);
        })
        .catch((err) => {
          console.warn('Error getting traits for intervention: ', err);
        });
    }, [subject]);

    useEffect(() => {
      if (!defaultValue) {
        return;
      }

      if (!defaultValue.bandit) {
        return;
      }

      const objectiveTrait = traitService.getTraitFromAddr(defaultValue.bandit.objective);

      const supporting = defaultValue.bandit.supporting.map((addr) => traitService.getTraitFromAddr(addr));

      const dynamicContextual = defaultValue.bandit.dynamic_ctx.map((addr) => traitService.getTraitFromAddr(addr));
      const staticContextual = defaultValue.bandit.static_ctx.map((addr) => traitService.getTraitFromAddr(addr));

      setSelected({
        objective: objectiveTrait ? [objectiveTrait] : [],
        supporting,
        contextual: [...dynamicContextual, ...staticContextual],
      });
    }, [defaultValue]);

    useEffect(() => {
      /*
        Do not add onReady as dependency to avoid multiple re-renderers
      */
      const primaryIsReady = selected.objective.length > 1;

      const weightsReady = selected.objective
        .map((trait) => weights[trait.addr.ptr] !== undefined)
        .every((ready) => ready === true);

      const isReady = primaryIsReady && weightsReady;
      onReady(isReady);
    }, [selected, weights]);

    useImperativeHandle(ref, () => ({
      value() {
        const objectiveMetricsValue: ColAddr[] = selected.objective
          .filter((trait) => !!trait)
          .map((trait) => trait.addr);
        const supportingMetricsValues: ColAddr[] = selected.supporting
          .filter((trait) => !!trait)
          .map((trait) => trait.addr);
        const contextMetricsValues: ColAddr[] = selected.contextual
          .filter((trait) => !!trait)
          .map((trait) => trait.addr);

        const isCatalogOrStatic = (addr: ColAddr) => {
          const category = getTraitCategory(addr);

          return category === TraitCategory.Catalogue || category === TraitCategory.Static;
        };

        return {
          banditv2: {
            objective: objectiveMetricsValue,
            supporting: supportingMetricsValues,
            decision_time_window: monitoringWindowRef.current?.value() as number,
            vacation_period: coolDownPeriodRef.current?.value() as number,
            static_ctx: contextMetricsValues.filter(isCatalogOrStatic),
            dynamic_ctx: contextMetricsValues.filter((addr) => getTraitCategory(addr) === TraitCategory.Dynamic),
            weights: objectiveMetricsValue.map((addr) => weights[addr.ptr]),
          },
        };
      },
    }));

    // filtering primaryTrait and supportingTrait to be mutually exclusive could be optimized
    // but at this stage we assume that performance here is not an issue
    const primaryTrait = useMemo(
      () => [...totalTraits].filter((trait) => !selected.supporting.find((supporting) => areEqual(supporting, trait))),
      [totalTraits, selected.supporting]
    );

    const supportingTrait = useMemo(
      () => [...totalTraits].filter((trait) => !selected.objective.find((objective) => areEqual(objective, trait))),
      [totalTraits, selected.objective]
    );

    const handleSelectedContextualMetric = useCallback(
      (item: Option) => {
        const found = categoriesForContextual.find((option) => option.value === item.value);

        if (found !== undefined) {
          setCategoriesForContextual((categories) => categories.filter((option) => option.value !== item.value));
        } else {
          setCategoriesForContextual((categories) => [...categories, item]);
        }
      },
      [categoriesForContextual]
    );

    const handleSelectAll = useCallback(
      (metricsType: MetricsType, selected: boolean) => {
        if (metricsType === MetricsType.supporting) {
          selectAllSupportingMetrics(selected);
        } else if (metricsType === MetricsType.contextual) {
          selectAllContextualMetrics(selected);
        }
      },
      [contextTraits, supportingTrait]
    );

    const selectAllSupportingMetrics = (isClicked: boolean) => {
      if (!isClicked) {
        setSelected((prevSelected) => ({ ...prevSelected, supporting: [] }));
      } else {
        setSelected((prevSelected) => ({
          ...prevSelected,
          supporting: supportingTrait,
        }));
      }
    };

    const selectAllContextualMetrics = (isClicked: boolean) => {
      if (!isClicked) {
        setSelected((prevSelected) => ({ ...prevSelected, contextual: [] }));
      } else {
        setSelected((prevSelected) => ({
          ...prevSelected,
          contextual: contextTraits,
        }));
      }
    };

    const handleSelectTrait = useCallback(
      (row: Record<string, any>, field: MetricsType, selected: boolean, weights: number[]) => {
        if (!field) {
          return;
        }

        // setWeights
        setWeights((prevWeights) => ({ ...prevWeights, [(row as Trait).addr.ptr]: weights[0] }));

        const currentTrait = row as Trait;

        if (selected) {
          setSelected((prevSelected) => {
            const isExist = prevSelected[field].some(
              (trait) => createTraitCode(trait) === createTraitCode(currentTrait)
            );

            if (isExist) {
              return prevSelected;
            }

            return { ...prevSelected, [field]: [...prevSelected[field], currentTrait] };
          });
        } else {
          setSelected((prevSelected) => ({
            ...prevSelected,
            [field]: prevSelected[field].filter((trait) => createTraitCode(trait) !== createTraitCode(currentTrait)),
          }));
        }
      },
      [selected]
    );

    const handleVacationPeriodChange = useCallback((value: any) => {
      setIsMicrobatching(value !== 0);
    }, []);

    const metricsTableData = {
      objective: {
        data: primaryTrait,
        subTitle: 'Choose the primary metric for the intervention algorithm to optimize.',
        title: 'Objective Metrics',
      },
      supporting: {
        data: supportingTrait,
        subTitle: 'Select additional metrics to help with visualizing the results.',
        title: 'Supporting Metrics',
      },
      contextual: {
        data: contextTraits,
        subTitle: 'Pick the input metrics for the intervention algorithm.',
        title: 'Contextual Metrics',
      },
    };

    const selectedMetricsCounter = useMemo(() => {
      return selected.objective.length + selected.contextual.length + selected.supporting.length;
    }, [selected.objective, selected.contextual, selected.supporting]);

    const availableContextualMetrics = useMemo(() => {
      let metrics: Trait[] = [];

      for (const category of categoriesForContextual) {
        const categoryMetrics = metricsTableData.contextual.data.filter(
          (metric) => getTraitCategory(metric.addr) === category.value
        );

        metrics = [...metrics, ...categoryMetrics];
      }

      return metrics;
    }, [categoriesForContextual, metricsTableData.contextual.data]);

    const handleGenerateSample = useCallback(() => {
      regenerateSample();
    }, []);

    return (
      <InterventionSection name={Steps.Metrics} title={'Metrics (v2)'}>
        <SectionAction>
          <CFButton
            value="Regenerate Sample"
            iconName={faRotate}
            role={CFRole.Primary}
            onClick={handleGenerateSample}
            isLoading={sampling}
            disabled={!sampleId}
          />
        </SectionAction>
        <div className="metrics-policy intervention-section">
          <CFTabs.TabContext value={metricsTableData.objective.title}>
            <CFTabs.Tabs>
              {Object.entries(metricsTableData).map(([key, { title }]) => (
                <CFTabs.Tab key={title} value={title} data-testid={`test-metric-table-${key}`}>
                  {title}
                </CFTabs.Tab>
              ))}
            </CFTabs.Tabs>

            {Object.keys(metricsTableData).map((key) => {
              const metricType = key as keyof typeof metricsTableData;
              return (
                <CFTabs.TabPanel
                  key={key}
                  value={metricsTableData[metricType].title}
                  className="metrics-policy-tabpanel"
                >
                  <div className="metrics-policy-subtitle">{metricsTableData[metricType].subTitle}</div>

                  <TraitsTable
                    actionSection={
                      metricType === 'contextual' ? (
                        <div className="metrics-policy-tabpanel-filters">
                          <CFSelect
                            options={availableContextualCategories}
                            onSelected={handleSelectedContextualMetric}
                            isMulti={true}
                            InputContainer={TagInputContainer}
                            value={categoriesForContextual}
                          />
                        </div>
                      ) : (
                        <></>
                      )
                    }
                    allowToSelectAll={true}
                    selected={selected[metricType as keyof typeof selected]}
                    showWindow={metricType === MetricsType.objective}
                    windowTitle={'Weights'}
                    onSelectAll={(value) => handleSelectAll(metricType as MetricsType, value)}
                    handleSelectTrait={(row, selected, weights) =>
                      handleSelectTrait(row, MetricsType[key as keyof typeof MetricsType], selected, weights)
                    }
                    traits={
                      metricType !== 'contextual' ? metricsTableData[metricType].data : availableContextualMetrics
                    }
                  />
                </CFTabs.TabPanel>
              );
            })}
          </CFTabs.TabContext>
          <CFTitledSection nested={true} title={`Selected Data (${selectedMetricsCounter})`}>
            <div className="selected-metrics-section">
              {Object.keys(selected).map((metricType) => (
                <CFTitledComponent title={`${capitalize(metricType)} Metrics`} size={TitleSize.Big} key={metricType}>
                  <div className="trait-list">
                    {selected[metricType as keyof typeof selected].length ? (
                      (selected[metricType as keyof typeof selected] as Trait[])
                        .filter((trait) => trait !== undefined)
                        .map((trait) => (
                          <TraitListItem
                            cohortService={cohortService}
                            data={[weights[trait.addr.ptr]]}
                            sampleId={sampleId || new VOSampleId('')}
                            trait={trait}
                            onDelete={(trait) =>
                              handleSelectTrait(trait, MetricsType[metricType as keyof typeof MetricsType], false, [])
                            }
                            key={trait ? createTraitCode(trait) : '1234'}
                          />
                        ))
                    ) : (
                      <div className="empty-label">{`No Selected ${capitalize(metricType)} Metric`}</div>
                    )}
                  </div>
                </CFTitledComponent>
              ))}
            </div>
          </CFTitledSection>

          <CFTitledComponent title="Monitoring window">
            <TimeWindowPicker ref={(el) => (monitoringWindowRef.current = el)} />
          </CFTitledComponent>

          <CFTitledComponent title="Vacation period" subtitle="(non-zero for microbatching)">
            <TimeWindowPicker
              ref={(el) => (coolDownPeriodRef.current = el)}
              onChange={handleVacationPeriodChange}
              defaultValue={0}
            />
          </CFTitledComponent>
        </div>
      </InterventionSection>
    );
  }
);

export default InterventionBanditMetricsPolicyV2;
