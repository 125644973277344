import React, { useRef } from 'react';
import { useState } from 'react';
import useCFNavigation from 'hooks/useCFNavigation';

import { create as createKey } from 'repositories/apikeys';

import { OrgInfo, UserDetailedInfo } from 'types';

import CFText from 'components/CFText';
import CFTitledComponent from 'components/CFTitledComponent';
import CFCheckbox from 'components/CFCheckbox';
import CFButton from 'components/buttons/CFButton';

import ApiKeyViewer from './apiKeyViewer';

import { Scopes } from 'domain/apikey.types';
import { CFRole } from 'domain/general.types';

import { CFRoutes } from 'routes';

import './api-key-creator.scss';

const mandatoryScopes = [Scopes.SDK];

interface Props {
  userDetailedInfo: UserDetailedInfo;
  projectId: number;
}

const NewKey = ({ userDetailedInfo, projectId }: Props) => {
  const navigate = useCFNavigation();
  const [apiKey, setApiKey] = useState<string>('');
  const scopesRef = useRef<Record<string, HTMLInputElement>>({});

  const findOrgFromProjectId = (orgproj: OrgInfo) => orgproj.projs?.find((project) => project.id === projectId);

  const orgproj = userDetailedInfo.available_orgprojs.find(findOrgFromProjectId);

  const handleCreateNewApiKey = async () => {
    const scopes: Scopes[] = Object.values(Scopes).filter((scope) => scopesRef.current[scope].checked);

    const key = await createKey(scopes);
    setApiKey(key);
  };

  const handleCancel = async () => {
    navigate(CFRoutes.integration_keys);
  };

  return (
    <React.Fragment>
      {apiKey !== '' && <ApiKeyViewer apiKey={apiKey} />}

      {apiKey === '' && (
        <div className="api-key-creator">
          <div className="subhead"> New API key </div>

          <div className="user-info">
            <CFTitledComponent title={'Organization'}>
              <CFText text={orgproj?.org.name || ''} />
            </CFTitledComponent>

            <CFTitledComponent title={'Creator'}>
              <CFText text={userDetailedInfo.user.username} />
            </CFTitledComponent>
          </div>

          <div className="subhead"> Scopes </div>

          <div className="scopes-info">
            {Object.values(Scopes).map((scope) => (
              <label key={`scope-${scope}`}>
                <CFCheckbox
                  data-scope={scope}
                  checked={true}
                  label={scope}
                  disabled={mandatoryScopes.includes(scope)}
                  ref={(el) => (scopesRef.current[scope] = el as HTMLInputElement)}
                />
              </label>
            ))}
          </div>

          <div className="controls">
            <CFButton value={'Cancel'} onClick={handleCancel} />
            <CFButton value={'Create'} onClick={handleCreateNewApiKey} role={CFRole.Primary} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default NewKey;
