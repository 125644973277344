import React, { useCallback, useState } from 'react';

import { PerCohortResp, Trait } from 'domain/traits.types';

import { Ptr } from 'services/cohort/cohort.types.api';
import { CohortID } from 'services/cohort/cohort.types';

import { useAnalyticsContext } from 'views/analytics/explore/context/useAnalyticsContext';

import CFSelect, { Option } from 'components/CFSelect';
import { CFScatterPlotChart } from 'components/charts/CFScatterPlotChart';
import TraitInputContainer from 'components/CFSelect/common/TraitInputContainer';
import TraitItem from 'components/CFSelect/common/TraitItem';

import './scatter-traits.scss';
import { CHART_COLORS } from 'styles/colors';

interface ScatterTraitProps {
  data: Record<CohortID, Record<Ptr, PerCohortResp>>;
  traitInfo: Record<Ptr, Trait>;
}

const ScatterTrait = ({ data, traitInfo }: ScatterTraitProps) => {
  const { traitsState } = useAnalyticsContext();
  const [selectedHorizontalTrait, setSelectedHorizontalTrait] = useState<Ptr>(() => {
    const firstCohort = Object.keys(data)[0];
    const traits = Object.keys(data[Number(firstCohort)]);
    return traits[0];
  });
  const [selectedVerticalTrait, setSelectedVerticalTrait] = useState<Ptr>(() => {
    const firstCohort = Object.keys(data)[0];
    const traits = Object.keys(data[Number(firstCohort)]);
    return traits[1];
  });

  const handleSelectHorizontalTrait = useCallback((option: Option) => {
    setSelectedHorizontalTrait(option.value);
  }, []);

  const handleSelectVerticalTrait = useCallback((option: Option) => {
    setSelectedVerticalTrait(option.value);
  }, []);

  const cohorts = Object.keys(data);

  return (
    <CFScatterPlotChart
      series={cohorts.map((cohortId, index) => ({
        values: (data[Number(cohortId)][selectedHorizontalTrait]?.val || []).map((val: number, index: number) => ({
          x: val,
          y: data[Number(cohortId)][selectedVerticalTrait]?.val[index],
        })),
        name: 'Scatter Plot',
        color: CHART_COLORS[index],
      }))}
      title={''}
      xLabel={selectedHorizontalTrait}
      yLabel={selectedVerticalTrait}
    >
      <div className="scatter-trait-header">
        <CFSelect
          options={
            traitsState.traits['day']?.map((trait) => ({
              label: trait,
              value: trait,
              meta: { trait: traitInfo[trait] },
            })) || []
          }
          value={{
            label: selectedVerticalTrait,
            value: selectedVerticalTrait,
            meta: { trait: traitInfo[selectedVerticalTrait] },
          }}
          onSelected={handleSelectVerticalTrait}
          isMulti={false}
          InputContainer={TraitInputContainer}
          Item={TraitItem}
        />

        <CFSelect
          options={
            traitsState.traits['day']?.map((trait) => ({
              label: trait,
              value: trait,
              meta: { trait: traitInfo[trait] },
            })) || []
          }
          value={{
            label: selectedHorizontalTrait,
            value: selectedHorizontalTrait,
            meta: { trait: traitInfo[selectedHorizontalTrait] },
          }}
          onSelected={handleSelectHorizontalTrait}
          isMulti={false}
          InputContainer={TraitInputContainer}
          Item={TraitItem}
        />
      </div>
    </CFScatterPlotChart>
  );
};

export default ScatterTrait;
