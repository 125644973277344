import React from 'react';

import MarkovSubjectViewer from 'views/intervention/interventions/NewIntervention/states/MarkovSubjectViewer';
import { MarkovPolicy } from 'services/intervention/intervention.types';

import CFTitledComponent from 'components/CFTitledComponent';
import Box from 'components/Box';
import CFTitledSection from 'components/CFTitledSection';

import './markov.scss';

interface Props {
  markovPolicy: MarkovPolicy;
}

const MarkovStatesDefinition = ({ markovPolicy }: Props) => {
  // edits are already computed at backend so no need to send then back

  return (
    <CFTitledSection title="Markov states" className={`metrics-definition metrics markov-monitoring`}>
      <div className="markov-monitoring__params">
        <CFTitledComponent title={'Budget'}>
          <Box className="field">{markovPolicy.budget}</Box>
        </CFTitledComponent>

        <CFTitledComponent title={'Population blend'}>
          <Box className="field">{markovPolicy.population_blend}</Box>
        </CFTitledComponent>

        <CFTitledComponent title={'Gap'}>
          <Box className="field">{markovPolicy.gap}</Box>
        </CFTitledComponent>

        <CFTitledComponent title={'Offset'}>
          <Box className="field">{markovPolicy.offset}</Box>
        </CFTitledComponent>
      </div>

      <MarkovSubjectViewer processId={markovPolicy.id} updates={{}} />
    </CFTitledSection>
  );
};

export default MarkovStatesDefinition;
