import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CFRole } from 'domain/general.types';

import { GroupName } from 'services/intervention/intervention.types';
import CohortService from 'services/cohort/cohort.service';
import { getSampleInfo } from 'services/cohort/cohort.repo';
import { Leaf } from 'services/cohort/cohort.types.api';

import { useInterventionContext } from 'views/intervention/useContext';

import CFButton from 'components/buttons/CFButton';
import CFInput, { CFInputType } from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';

import { SamplingType } from '../types';

import './ab-sampling.scss';

interface Props {
  cohortService: CohortService;
  extraFilter: Leaf | undefined;
  totalSubjects: number;
  isLoading: boolean;
}

interface InputRatioProps {
  name: string;
  value: number;
}

const InputRatio = React.forwardRef<HTMLInputElement, InputRatioProps>(function InputRatio(
  { name, value }: InputRatioProps,
  ref
) {
  return (
    <CFTitledComponent title={name} className="input-ratio">
      <CFInput type={CFInputType.Number} defaultValue={value} ref={ref} />
    </CFTitledComponent>
  );
});

const ABSampling = ({ extraFilter, totalSubjects, isLoading }: Props) => {
  const groupRatioRefs = useRef<Record<GroupName, HTMLInputElement>>({});
  const sampleSizeInputRef = React.createRef<HTMLInputElement>();

  const [groupCounter, setGroupCounter] = useState<Record<GroupName, number>>({});

  const [errorRatio, setErrorRatio] = useState(false);
  const { generateSample, cohort, sampling, selectedNudges, sampleId } = useInterventionContext();

  const [sampleSizeValid, setSampleSizeValid] = useState(false);

  const sampleButtonEnable = useMemo(() => {
    const isEnable = cohort !== undefined && sampleSizeValid && selectedNudges.length > 1;

    return isEnable;
  }, [sampleSizeValid, cohort, selectedNudges]);

  const updateSampleSizeValidity = (sampleSize: string) => {
    const minSampleThreshold = 0;

    const nonEmpty = sampleSize.trim().length > 0;
    const inRange = parseInt(sampleSize) > minSampleThreshold && parseInt(sampleSize) < totalSubjects + 1;

    setSampleSizeValid(nonEmpty && inRange);
  };

  useEffect(() => {
    if (!sampleSizeInputRef || !sampleSizeInputRef.current) {
      // may be null if allowSampling is not set
      return;
    }
    updateSampleSizeValidity((sampleSizeInputRef as any).current.value);
  }, [totalSubjects]);

  const handleSampleSizeChange = useCallback(
    (newValue: any) => {
      updateSampleSizeValidity(newValue.target.value);
    },
    [totalSubjects]
  );

  const handleGenerateSample = useCallback(async () => {
    if (!sampleSizeInputRef.current) {
      return;
    }

    const groupNames = selectedNudges.map((nudge) => nudge.name);

    const groupRatios: Record<GroupName, number> = groupNames.reduce(
      (acc, groupName) => ({ ...acc, [groupName]: parseFloat(groupRatioRefs.current[groupName].value) }),
      {}
    );

    const ratios = Object.values(groupRatios);
    const totalRatio = ratios.reduce((acc, cur) => acc + cur, 0);

    const errorRatio = totalRatio !== 1 || ratios.some((item) => item === 0) || ratios.some((item) => item === 1);

    setErrorRatio(errorRatio);

    setGroupCounter({});

    if (errorRatio || !cohort) {
      return;
    }

    generateSample(SamplingType.Normal, parseInt(sampleSizeInputRef.current.value), groupRatios);
  }, [selectedNudges, sampleSizeInputRef, extraFilter]);

  useEffect(() => {
    (async () => {
      if (!sampleId.value) {
        return;
      }
      const sampleInfo = await getSampleInfo(sampleId.value);
      setGroupCounter(sampleInfo.group_cnt);
    })();
  }, [sampleId]);

  return (
    <div className="ab-testing">
      <CFTitledComponent title="Sample size" className="reduced">
        <CFInput
          disabled={cohort === undefined}
          type={CFInputType.Number}
          ref={sampleSizeInputRef}
          onChange={handleSampleSizeChange}
          message={cohort !== undefined ? `No more than ${totalSubjects}` : ''}
        />
      </CFTitledComponent>

      <div className="ratios">
        {selectedNudges.map((nudge) => (
          <InputRatio
            key={`${nudge.name}-${selectedNudges.length}`}
            name={nudge.name}
            value={1 / selectedNudges.length}
            ref={(el) => (groupRatioRefs.current[nudge.name] = el as HTMLInputElement)}
          />
        ))}
      </div>
      <CFButton
        value="Generate sample"
        isLoading={sampling}
        role={CFRole.Primary}
        disabled={!sampleButtonEnable || isLoading}
        onClick={handleGenerateSample}
      />
      {errorRatio ? <div className="error-message">Wrong ratio distribution</div> : ''}

      {Object.keys(groupCounter).map((groupName) => (
        <div key={groupName} className="extendable-viewer">
          {groupName} ({groupCounter[groupName]})
        </div>
      ))}
    </div>
  );
};

export default ABSampling;
