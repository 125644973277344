import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faExpand } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { Option } from './defaults';
import DefaultItem, { Props as ItemProps } from './defaults/Item';
import DefaultInputContainer, { Props as InputContainerProps } from './defaults/InputContainer';
import DefaultSearchField, { Props as SearchFieldProps } from './defaults/SearchField';

import './cf-select.scss';

interface CommonSelectProps {
  options: Option[];
  searchable?: boolean;
  direction?: 'bottom' | 'top';
  Item?: React.ForwardRefExoticComponent<ItemProps & React.RefAttributes<HTMLDivElement>>;
  InputContainer?: (props: InputContainerProps) => JSX.Element;
  SearchField?: (props: SearchFieldProps) => JSX.Element;
  onSelected?: (option: Option) => void;
  onSearch?: (value: string) => void;
  onClear?: () => void;
  onExpandRequest?: () => void;
  testId?: string;
  disabled?: boolean;
}

interface SingleSelectProps extends CommonSelectProps {
  isMulti?: false;
  value: Option;
}

interface MultiSelectProps extends CommonSelectProps {
  isMulti: true;
  value: Option[];
}

type Props = SingleSelectProps | MultiSelectProps;

export interface CFReferencedSelectRef {
  value: () => string | string[];
}

type CustomReferencedProps = {
  defaultValue: string;
};

type ReferencedProps = Props & CustomReferencedProps;

export const CFReferencedSelect = React.forwardRef<CFReferencedSelectRef, ReferencedProps>(function CFReferencedSelect(
  props: ReferencedProps,
  ref
) {
  const [selectedItem, setSelectedItem] = useState<string>(props.defaultValue);

  const handleSelectItem = useCallback((option: Option) => {
    setSelectedItem(option.value);

    props.onSelected && props.onSelected(option);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      value: () => selectedItem,
    }),
    [selectedItem]
  );

  const selectedOption = useMemo(() => {
    const foundItem = props.options.find((option) => option.value === selectedItem);

    if (props.isMulti) {
      return foundItem ? [foundItem] : [];
    } else {
      return foundItem ? foundItem : { label: '', value: '' };
    }
  }, [props, props.isMulti, selectedItem]);

  return (
    <CFSelect {...(props as Props)} onSelected={handleSelectItem} value={selectedOption as Option} isMulti={false} />
  );
});

const CFSelect = ({
  options,
  value,
  isMulti = false,
  searchable = false,
  direction = 'bottom',
  onSelected,
  onSearch,
  onClear,
  onExpandRequest,
  Item = DefaultItem,
  InputContainer = DefaultInputContainer,
  SearchField = DefaultSearchField,
  testId,
  disabled = false,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocuseIndex] = useState(-1);
  const itemRefs = useRef<HTMLDivElement[]>([]);

  const valueMap = Array.isArray(value)
    ? value?.reduce<Record<string, boolean>>((acc, current) => {
        if (options.some((option) => option.value === current.value)) {
          acc[current.value] = true;
        }

        return acc;
      }, {})
    : { [value.value]: true };

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = useCallback(() => {
    if (disabled) {
      return;
    }
    setIsOpen((prev) => !prev);
    setFocuseIndex(-1);
  }, [disabled]);

  const handleSelect = useCallback(
    (option: Option) => {
      if (option.disabled) {
        return;
      }

      if (!isMulti) {
        setIsOpen(false);
        onSearch?.('');
      }

      onSelected?.(option as Option);
    },
    [onSelected]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        onSearch?.('');
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSearch = (str: string) => {
    onSearch?.(str);
    setFocuseIndex(-1);
  };

  const handleClear = useCallback(() => {
    onClear?.();
  }, []);

  const handleKeydown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if ([' ', 'Enter'].includes(e.key)) {
        if (isOpen && focusedIndex > -1 && focusedIndex < options.length) {
          onSelected?.(options[focusedIndex]);

          if (!isMulti) {
            setIsOpen(false);
          }
        } else {
          setIsOpen(true);
          setFocuseIndex(-1);
        }

        e.preventDefault();
        return;
      }

      if (['Escape', 'Tab'].includes(e.key)) {
        setIsOpen(false);
        return;
      }

      if (e.key === 'ArrowDown' && focusedIndex < options.length - 1) {
        const nextIndex = focusedIndex + 1;
        setFocuseIndex(nextIndex);
        itemRefs.current[nextIndex].scrollIntoView({ behavior: 'auto', block: 'nearest' });
        e.preventDefault();
      }

      if (e.key === 'ArrowUp' && focusedIndex > 0) {
        const nextIndex = focusedIndex - 1;
        setFocuseIndex(nextIndex);
        itemRefs.current[nextIndex].scrollIntoView({ behavior: 'auto', block: 'nearest' });
        e.preventDefault();
      }
    },
    [focusedIndex, isOpen]
  );

  const handleExpandRequest = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onExpandRequest?.();
  }, []);

  return (
    <div className={classNames('cf-select', { disabled })} ref={dropdownRef} tabIndex={0} onKeyDown={handleKeydown}>
      <div
        className={classNames('cf-select-input', { focused: isOpen, disabled })}
        onClick={handleToggle}
        data-testid={testId}
      >
        {onExpandRequest && (
          <span onClick={handleExpandRequest} className="cf-select-input-expand">
            {' '}
            <FontAwesomeIcon icon={faExpand} size="lg" />
          </span>
        )}
        <InputContainer selected={value} />
        <FontAwesomeIcon className="cf-select-chevron" icon={faChevronDown} />
      </div>
      {isOpen && !disabled && (
        <div className={classNames('cf-select-dropdown', direction, { searchable })}>
          {searchable && <SearchField onChange={handleSearch} />}
          <div className="cf-select-items" role="listbox">
            {options.map((option, i) => (
              <Item
                ref={(ref) => ref && (itemRefs.current[i] = ref)}
                key={option.value}
                option={option}
                active={!!valueMap[option.value] && !option.disabled}
                onClick={handleSelect}
                isMulti={isMulti}
                focused={focusedIndex === i}
                disabled={option.disabled}
              />
            ))}
            {options.length === 0 && <div className="cf-select-noOptions">No options</div>}
          </div>
          {onClear && (
            <div className="cf-select-dropdown-actions">
              <span onClick={handleClear}> Clear</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CFSelect;

export type { Option } from './defaults';
