import React, { useEffect, useState } from 'react';

import { CFGraph } from 'components/charts/CFGraph';

import { Graph, GroupName, SimpleGraph } from 'services/markov/markov.types';
import { indexToNodeName } from 'services/markov/helpers.markov';

interface Props {
  groupName: GroupName;
  graphSpec: Graph;
}

const ScaledSubjectGraph = ({ groupName, graphSpec }: Props) => {
  const [graph, setGraph] = useState<SimpleGraph>();

  useEffect(() => {
    const simpleGraph: SimpleGraph = {};

    for (let i = 0; i < graphSpec.probs.length; i++) {
      const currentNodeName = indexToNodeName(i);
      simpleGraph[currentNodeName] = {};

      for (let target = 0; target < graphSpec.probs[i].length; target++) {
        simpleGraph[currentNodeName][indexToNodeName(target)] = [
          parseFloat(graphSpec.probs[i][target].toFixed(3)),
          parseFloat(graphSpec.rewards[i][target].toFixed(2)),
        ];
      }
    }

    setGraph(simpleGraph);
  }, [graphSpec]);

  if (!graph) {
    return <div></div>;
  }

  return (
    <div>
      <CFGraph
        title={groupName}
        prefix=""
        data={graph}
        onNodeClicked={() => {
          /* */
        }}
      />
    </div>
  );
};

export default ScaledSubjectGraph;
