import React, { Children, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faRefresh, faShare } from '@fortawesome/free-solid-svg-icons';

import classNames from 'classnames';

import { AssistantMessage, AssistantMessageType, QuestionType } from 'services/assistant/assistant.types';
import { AuthAction, isAllowedTo } from 'services/authorization.service';

import { useMessagesContext } from 'views/assistant/context/useMessagesContext';

import CFTooltip, { CFTooltipPositions } from 'components/CFTooltip';
import CFSwitch from 'components/CFSwitch';
import CFChip from 'components/chips/CFChip';

import ProfileIcon, { IconType } from './ProfileIcon';
import PayloadViewer from '../PayloadViewer';
import PayloadErrorBoundary from '../PayloadViewer/payloadFallback';
import FollowUpMessage from './FollowUpMessage';
import Timestamp from './Timestamp';

import { useServicesContext } from 'hooks/useServicesContext';

import './message.scss';

const FROM_YOU = 'You';
const FROM_ASSISTANT = 'Assistant';

interface MessageProps extends PropsWithChildren {
  message: AssistantMessage;
  error?: boolean;
}

const Chart = (props: any) => {
  return props.children;
};

const Table = (props: any) => {
  return props.children;
};

const Message = ({ message, children }: MessageProps) => {
  const [followUpMessage, setFollowUpMessage] = useState<AssistantMessage>();
  const { embedded, setSelectedParentId, addQuestion, modelType } = useMessagesContext();
  const { assistantService } = useServicesContext();
  const [isChart, setIsChart] = useState(false);

  const arrayChildren = Children.toArray(children);

  const tableMessage = arrayChildren.find((child) => (child as any).type.name === Table.name);
  const chartMessage = arrayChildren.find((child) => (child as any).type.name === Chart.name);

  useEffect(() => {
    (async () => {
      if (!message.parentId) {
        return;
      }

      const thread = await assistantService.getThread();

      const followUpMessage = await thread.getMessage(message.parentId);

      setFollowUpMessage(followUpMessage);
    })();
  }, [message.parentId]);

  const isTimeserie = useMemo(() => {
    if (!message.structuredText || !message.structuredText[0]) {
      return false;
    }

    const columnNames = Object.keys(message.structuredText[0]).filter((item) => !!item);

    const timeField = columnNames.find((item) => {
      if (!message.structuredText) {
        return false;
      } else {
        return (
          !isNaN(new Date(message.structuredText[0][item]).getTime()) && isNaN(Number(message.structuredText[0][item]))
        );
      }
    });

    return timeField !== undefined;
  }, [message]);

  const isFromAssistant = useMemo(() => {
    return message.type === AssistantMessageType.Answer || message.type === AssistantMessageType.ValidationRequest;
  }, [message]);

  const handleFollowUpThisMessage = useCallback(() => {
    setSelectedParentId(message.id);
  }, [message]);

  const handleRetry = useCallback(() => {
    addQuestion(modelType, QuestionType.Analytics, message.text, message.parentId);
  }, [message, modelType]);

  const messageIdClass = `id-${message.id}`;

  return (
    <div
      key={Math.random()}
      className={classNames('assistant-message', message.type, messageIdClass, { error: message.error })}
    >
      {isFromAssistant || message.type === AssistantMessageType.Error ? (
        <div className="title">
          <ProfileIcon type={IconType.System} /> <span>{FROM_ASSISTANT}</span>
          {message.payload && isAllowedTo(AuthAction.SeeAssistantMetadata) && (
            <CFTooltip
              forceMaxWidth={false}
              description={
                <PayloadErrorBoundary payload={message.payload}>
                  <PayloadViewer payload={message.payload} />
                </PayloadErrorBoundary>
              }
              delayed={true}
              position={embedded ? CFTooltipPositions.VerticalAuto : CFTooltipPositions.VerticalRightAuto}
            >
              <FontAwesomeIcon icon={faCircleInfo} size="sm" />
            </CFTooltip>
          )}
          {message.reason && isAllowedTo(AuthAction.SeeAssistantMetadata) && (
            <CFTooltip
              description={JSON.stringify(message.reason, undefined, 2)}
              delayed={true}
              position={embedded ? CFTooltipPositions.VerticalAuto : CFTooltipPositions.RightBottom}
            >
              <FontAwesomeIcon icon={faCircleInfo} size="sm" />
            </CFTooltip>
          )}
          {message.structuredText && message.structuredText.length > 1 && isTimeserie && (
            <div className="title__chart-enabler">
              <span>Timeseries</span>
              <CFSwitch checked={isChart} onChange={(value) => setIsChart(value)} />
            </div>
          )}
        </div>
      ) : (
        <div className="title">
          <ProfileIcon type={IconType.User} />
          <span>{FROM_YOU}</span>

          <div className="title__actions">
            <div className="title__action">
              <CFChip value={message.mode as QuestionType} removable={false} />
            </div>

            <div className="title__action" onClick={handleRetry}>
              <FontAwesomeIcon icon={faRefresh} size="sm" />
            </div>
          </div>
        </div>
      )}

      <div className="content">
        {followUpMessage && (
          <FollowUpMessage
            message={followUpMessage}
            onDiscard={function (): void {
              throw new Error('Function not implemented.');
            }}
          />
        )}

        {isChart ? chartMessage : tableMessage || children}

        <div className="content__footer">
          {!isFromAssistant && (
            <>
              <span>{message.mode}</span>
              <div className="follow-up" onClick={handleFollowUpThisMessage}>
                <span> Follow up </span>

                <span>
                  <FontAwesomeIcon icon={faShare} size="sm" />
                </span>
              </div>
            </>
          )}

          <div className="timestamp">
            <Timestamp message={message} />
          </div>
        </div>
      </div>
    </div>
  );
};

Message.Table = Table;
Message.Chart = Chart;
export default Message;
