import React, { useRef } from 'react';

import { CFRole } from 'domain/general.types';

import CFButton from 'components/buttons/CFButton';
import CFDialogPanel, { CFDialogPanelAction } from 'components/CFDialogPanel';

import TraitExplorer, { TraitExplorerRef } from './traitExplorer';

import { TraitExplorerProps } from '.';

const TraitExplorerModal = (props: TraitExplorerProps) => {
  const { onCancel, onTraitSelected } = props;
  const traitExplorerRef = useRef<TraitExplorerRef>(null);

  const handleOnSave = () => {
    const traits = traitExplorerRef.current?.value();
    onTraitSelected(traits || []);
  };

  return (
    <CFDialogPanel title="Trait explorer" onCancel={onCancel}>
      <CFDialogPanelAction>
        <CFButton value={'Save'} onClick={handleOnSave} role={CFRole.Cyan} /* disabled={!selectedTraits} */ />
      </CFDialogPanelAction>

      <TraitExplorer ref={traitExplorerRef} {...props} />
    </CFDialogPanel>
  );
};

export default TraitExplorerModal;
