import React, { useEffect, useMemo, useState } from 'react';

import { useServicesContext } from 'hooks/useServicesContext';

import { RunRecord } from 'services/traits/backfill.types.api';

import TraitsLayout from '../layout';
import { Tabs } from '../tabs';

import ReferenceDetail from './components/ReferenceDetail';
import CFTable, { Column, ColumnType } from 'components/CFTable';
import StatusTag, { StatusTagVariant } from 'components/StatusTag';

const StatusMap = {
  done: StatusTagVariant.Success,
  running: StatusTagVariant.InProgress,
  pending: StatusTagVariant.Pending,
};

const BackfillStatus = () => {
  const { backfillService } = useServicesContext();
  const [backfills, setBackfills] = useState<RunRecord[]>([]);

  useEffect(() => {
    (async () => {
      const backfills = await backfillService.list();

      setBackfills(backfills);
    })();
  }, []);

  const headers: Column[] = [
    {
      title: 'Type',
      type: ColumnType.STRING,
      field: '',
      renderCell: (row) => row.run_ref.split('-')[0],
      style: {
        textAlign: 'left',
      },
    },
    {
      title: 'Ref',
      type: ColumnType.STRING,
      field: 'run_ref',
      style: {
        textAlign: 'left',
      },
    },
    {
      title: 'Order',
      type: ColumnType.NUMBER,
      field: 'graph_order',
      style: {
        textAlign: 'left',
      },
    },

    {
      title: 'Started',
      type: ColumnType.NUMBER,
      field: 'started',
      style: {
        textAlign: 'left',
      },
    },
    {
      title: 'Start',
      type: ColumnType.DATE,
      field: 'ts_start',
      style: {
        textAlign: 'left',
      },
    },
    {
      title: 'End',
      type: ColumnType.DATE,
      field: 'ts_end',
      style: {
        textAlign: 'left',
      },
    },
    {
      title: 'Status',
      type: ColumnType.STRING,
      field: 'status',
      renderCell: (row) => <StatusTag label={row.status} variant={StatusMap[row.status as keyof typeof StatusMap]} />,
      style: {
        textAlign: 'left',
      },
    },
    {
      title: '',
      type: ColumnType.STRING,
      field: 'expanded',
      expandable: true,
      renderCell: (row) => {
        return <ReferenceDetail backfillRef={row.run_ref} runRecord={row as RunRecord} />;
      },
      style: {
        width: '15px',
      },
    },
  ];

  const backfillsIndexed = useMemo(() => {
    return backfills.map((backfill) => ({
      ...backfill,
      id: `${backfill.run_ref}-${backfill.status}-${backfill.graph_order}`,
    }));
  }, [backfills]);

  return (
    <TraitsLayout className="trait-definitions" tab={Tabs.BackfillStatus}>
      <CFTable headers={headers} data={backfillsIndexed} indexCol="id" />
    </TraitsLayout>
  );
};

export default BackfillStatus;
