import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';

import { CFRole } from 'domain/general.types';

import { ModelType, QuestionType } from 'services/assistant/assistant.types';

import CFConfirmableButton from 'components/CFConfirmableButton';
import CFButtonGroup, { ButtonGroupOption } from 'components/CFButtonGroup';
import CFButton from 'components/buttons/CFButton';
import CFSelect from 'components/CFSelect';

import AssistantWall from './AssistantWall';
import AssistantSplash from './AssistantSplash';
import PromptHints from './PromptHints';
import AssistantInput from './AssistantInput';

import { useMessagesContext } from './context/useMessagesContext';

import { modelsByType, modelTypeLabels } from './constants';

import './assistant.scss';

interface Props {}

const AssistantInternal = ({}: Props) => {
  const textareaRef = React.createRef<HTMLTextAreaElement>();
  const [questionType, setQuestionType] = useState(QuestionType.Analytics);

  const { modelType, messages, reset, embedded, currentMessage, setModelType } = useMessagesContext();

  useEffect(() => {
    textareaRef.current?.focus();
  }, [currentMessage]);

  useEffect(() => {
    textareaRef.current?.focus();
  }, [messages]);

  useEffect(
    function disableDeepSeek() {
      if (questionType !== QuestionType.Docs || modelType !== ModelType.Deepseek) {
        return;
      }
      setModelType(ModelType.OpenAI);
    },
    [questionType]
  );

  const handleReset = useCallback(async () => {
    reset();
  }, []);

  const questionTypeOptions = [
    { label: 'Analytics', value: QuestionType.Analytics },
    { label: 'Docs', value: QuestionType.Docs },
  ];

  const questionTypeSelect = useMemo(() => {
    return questionTypeOptions.find((item) => item.value === questionType) as ButtonGroupOption;
  }, [questionType]);

  return (
    <div className={classnames('assistant', { embedded: embedded })}>
      {messages.length ? <AssistantWall /> : <AssistantSplash embedded={embedded} />}

      <PromptHints />

      <div className={classnames('assistant__footer', { embedded: embedded })}>
        <AssistantInput type={questionType} />

        <div className="controls-container">
          <CFConfirmableButton question={`Are you sure you want to reset chat history?`} title={'Reset chat'}>
            <CFButton value="Reset" role={CFRole.Borderless} iconName={faArrowRotateRight} onClick={handleReset} />
          </CFConfirmableButton>

          <CFButtonGroup
            options={questionTypeOptions}
            value={questionTypeSelect}
            onSelect={(option) => setQuestionType(option.value)}
          />
          <CFSelect
            options={modelsByType[questionType].map((model) => ({
              label: modelTypeLabels[model] ?? model,
              value: model,
            }))}
            value={{ label: modelTypeLabels[modelType] ?? modelType, value: modelType }}
            onSelected={(option) => setModelType(option.value as ModelType)}
            direction="top"
          />
        </div>
      </div>
    </div>
  );
};

export default AssistantInternal;
