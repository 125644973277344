import React, { Children } from 'react';

import CFPortal, { ContentPosition } from 'components/CFPortal';
import CFTitledSection, { SectionAction } from 'components/CFTitledSection';

interface CFDialogPanelProps {
  title: string;
  children: React.ReactNode;
  onCancel: () => void;
}

export const CFDialogPanelAction = (props: any) => {
  return <div> {props.children} </div>;
};

const CFDialogPanel = ({ title, children, onCancel }: CFDialogPanelProps) => {
  const arrayChildren = Children.toArray(children);
  const actions = arrayChildren.filter((child) => (child as any).type.name === CFDialogPanelAction.name);

  const content = arrayChildren.filter((child) => {
    const childName = (child as any).type.name;

    return childName !== CFDialogPanelAction.name;
  });

  return (
    <CFPortal onClickOutside={onCancel} mode={ContentPosition.Center}>
      <CFTitledSection title={title} className="cf-dialog-panel" onClose={onCancel} underlined>
        <SectionAction>{actions}</SectionAction>

        <div className="cf-dialog-panel__content">{content}</div>
      </CFTitledSection>
    </CFPortal>
  );
};

CFDialogPanel.Action = CFDialogPanelAction;
export default CFDialogPanel;
