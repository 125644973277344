import { Ptr } from 'services/cohort/cohort.types.api';
import { Module, TimeRFC3999 } from './general.types';
import { ModelId } from './model.types';
import { CohortID } from 'services/cohort/cohort.types';

export type TraitCode = string; // <schema>.<table>.<name>

export enum TraitType {
  All = 'all',
  Static = 'st',
  Dynamic = 'dt',
  GroupDynamic = 'gdt',
  CatalogTrait = 'ct',
}

export enum TraitUsage {
  Cohort = 'cohort',
  Feature = 'feature',
  InvContext = 'inv_context',
  Filter = 'filter',
  InvMetric = 'inv_metric',
  Event = 'event',
  CensoringMetric = 'censoring_metric',
  TimeToEvent = 'time_to_event',
  BanditMetric = 'bandit_metric',
  ABMetric = 'ab_metric',
  BanditContext = 'bandit_context',
  Restless = 'restless',
}

export enum TraitCategory {
  Catalogue = 'ct',
  Dynamic = 'dt',
  Grouped = 'gdt',
  Static = 'st',
  MLT = 'mlt',
}

export enum TraitSubject {
  User = 'user',
  Device = 'device',

  // E-commerce
  Drug = 'drug',
  Grocery = 'grocery',
  Blood = 'blood',
  Oxygen = 'oxygen',
  MedicalEquipment = 'medical_equipment',

  // CHW MGMT
  Chwsite = 'chwsite',
  Patient = 'patient',

  // Loyalty
  Survey = 'survey',
  Reward = 'reward',

  Facility = 'facility',
}

export enum TraitDataType {
  Bool = 'bool',
  Boolean = 'boolean',
  Varchar = 'varchar',
  Int4 = 'int4',
  Int2 = 'int2',
  Float4 = 'float4',
  Number = 'number',
  Timestamp = 'timestamp',
}

export interface Trait {
  addr: ColAddr;
  meta: Meta;
}

export interface AppTrait extends Trait {
  models?: ModelId[];
}

export interface TraitDefinition {
  name: string;
  display_name: string;
  description: string;
  unit: string;
  category: TraitCategory;
  ptr: Ptr;
}

export interface ColAddr {
  ptr: Ptr;
  name?: string;
  table?: string;
  table_class?: string;
  schema?: string;
  dtype: string;
  pks?: string[];
  display_dtype?: string;
}

/*
type ColAddr struct {
	Ptr   Ptr    `yaml:"ptr" json:"ptr"`
	Dtype string `yaml:"dtype" json:"dtype" validate:"required,oneof=int2 int4 float4 bool varchar _varchar date timestamptz timestamp"`

	Deltas       Deltas       `yaml:"deltas" json:"-"`
	AvailableAgg []u.AggLevel `json:"available_agg,omitempty"`

	// cache
	Schema     string     `json:"schema"`
	Table      string     `json:"table"`
	Name       string     `json:"name"`
	TableClass TableClass `json:"-"` // without exact agg level
}
*/

export type TimeseriesItem = [string, string, number];

export interface TraitTimeseries {
  header: string[];
  data_type: string[];
  rows: TimeseriesItem[];
}

export enum TraitMetricsNames {
  AppRenderingTime = 'gdt_user_general.screen_rendering_time__mean#1d',
  AppStartTime = 'gdt_user_general.app_start_time__mean#1d',
  ConnectionInterval = 'gdt_user_general.connection_interval__mean#60d',
  ConnectionTime = 'gdt_user_general.connection_time__mean#1d',
  PushOpenRate = 'gdt_user_general.push_open_rate#1d',
  SpeedDown = 'gdt_user_general.log_download_speed__mean#1d',
  SpeedUp = 'gdt_user_general.log_upload_speed__mean#1d',

  ActiveUserCountDay = 'gdt_user_general.active_user_count#1d',
  ActiveUserCountMonth = 'gdt_user_general.active_user_count#1m',
  NewUserCountDay = 'gdt_user_general.new_user_count#1d',
  ChurnRatio = 'gdt_user_general.churn_ratio',
}

export enum TraitTimeseriesTypesDaily {
  ActiveUserCount = 'gdt_user_general.active_user_count#1d',
  ConnectionTimeMean = 'gdt_user_general.connection_time__mean#1d',
  ChurnedUserCount = 'gdt_user_general.churned_user_count#1d',
  NewUserCount = 'gdt_user_general.new_user_count#1d',
}

export enum TraitTimeseriesTypesMonthly {
  ActiveUserCount = 'gdt_user_general.active_user_count#1m',
  ConnectionTimeMean = 'gdt_user_general.connection_time__mean#1m',
  ChurnedUserCount = 'gdt_user_general.churned_user_count#1m',
}

export interface Meta {
  description: string;
  display_name: string;
  display_unit: string;
  subject: string;
  unit: string;
  usage: TraitUsage[];
  template: string;
  template_params: Record<string, string[]> | null;
  modules: Module[];
  Show?: boolean; // pick one when backend ready
  show?: boolean; // pick one when backend ready
  extra_pks?: string[];
  mltf?: string;
}

export type TraitName = string;

export interface CohortSearchRequest {
  cohort_id: CohortID;
  ptr: Ptr;
  time: TimeRFC3999;
}

export interface Plot2D {
  x: number[];
  y: number[];
}

export interface Bin {
  min: number;
  max: number;
  cnt: number;
}

export interface PerCohortResp {
  val: any; // array
  numerical_hist: Bin[];
  categorical_hist: Record<string, number>;
  kde: Plot2D;
}
