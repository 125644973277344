import React, { useMemo, useRef } from 'react';

import { EChartsOption, ReactECharts } from '..';

import { binTooltip, xAxisValueFormatter } from '../chartCommon';

import './cf-histogram.scss';

export interface HistogramBin {
  min: number;
  max: number;
  count: number;
}

interface Props {
  series: {
    name?: string;
    color?: string;
    data: HistogramBin[];
  }[];
  xLabel?: string;
  title: string;
  barWidth?: number;
  isLoading: boolean;
}

const CFBinHistogram = ({ series, title, isLoading = true }: Props) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const chartContainerStyle = {
    width: '100%',
    height: '200px',
  };

  const options: EChartsOption = useMemo(() => {
    const option: EChartsOption = {
      tooltip: {
        ...binTooltip(),
      },
      legend: {},
      dataset: {
        dimensions: ['bin', ...series.map((s, index) => s.name || `series-${index}`)],
        source: (series?.[0]?.data || []).map((s: HistogramBin, indexValue: number) => ({
          bin: s.max,
          ...series.reduce((acc: any, serie, index) => {
            acc[serie.name || `series-${index}`] = serie.data[indexValue]?.count || 0;
            return acc;
          }, {}),
        })),
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '10%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          formatter: function (value: any) {
            return xAxisValueFormatter(value);
          },
        },
      },
      yAxis: {},
      series: series.map((s) => ({
        type: 'bar',
        animation: false,
        color: s.color,
      })),
    };

    return option;
  }, [series, title]);

  return (
    <div className="cf-histogram">
      {title}
      <ReactECharts ref={chartRef} option={options} style={chartContainerStyle} loading={isLoading} />
    </div>
  );
};

export default CFBinHistogram;
