import React, { useCallback, useEffect, useRef, useState } from 'react';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';

import { useToast } from 'hooks';

import { CFRole } from 'domain/general.types';

import { upload, list, remove, processDoc } from 'services/upload/upload.service';
import { DocAPI, UploadId } from 'services/upload/upload.types';

import CFButton from 'components/buttons/CFButton';
import CFInput from 'components/CFInput';
import { ToastType } from 'components/CFToast/types';
import CFTrashButton from 'components/buttons/CFTrashButton';
import CFConfirmableButton from 'components/CFConfirmableButton';
import CFDataTable from 'components/CFDataTable';
import { ColumnType } from 'components/CFTable';

import SearchEmbeddings from './components/search';

import './editable-file-input.scss';

const Upload = () => {
  const { addToast } = useToast();
  const [total, setTotal] = useState(0);
  const [index, setIndex] = useState(0);
  const [name, setName] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [docs, setDocs] = useState<DocAPI[]>([]);
  const [totalDocs, setTotalDocs] = useState(-1);
  const [curPage, setCurPage] = useState<number>(0);
  const [curPageSize, setCurPageSize] = useState<number>(10);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const file = (event.target as any).fileInput.files[0];

    try {
      await upload(file, name, (index: number, total: number) => {
        setIndex(index);
        setTotal(total);
      });

      downloadPage(curPage);
    } catch (e) {
      addToast('' + e, ToastType.ERROR);
      return;
    }

    addToast('File uploaded correctly', ToastType.SUCCESS);
  };

  const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  }, []);

  const handleOpenFileDialog = useCallback(() => {
    if (!fileInputRef || !fileInputRef.current) {
      return;
    }

    fileInputRef.current.click();
  }, [fileInputRef]);

  const handleDeleteDocument = useCallback(async (id: UploadId) => {
    try {
      await remove(id);
    } catch (e) {
      setIndex(0);
      setTotal(0);
      addToast(e + '', ToastType.ERROR);
      return;
    }

    setDocs((docs) => docs.filter((doc) => doc.id !== id));
  }, []);

  const handleProcessDocument = useCallback(async (id: UploadId) => {
    try {
      await processDoc(id);
    } catch (e) {
      addToast(e + '', ToastType.ERROR);
      return;
    }

    setDocs((docs) => {
      const newDocs = [...docs];

      const doc = newDocs.find((doc) => doc.id === id);

      if (doc) {
        // this should be moved to a domain object
        doc.processed = false;
      }

      return newDocs;
    });
  }, []);

  const headers = [
    {
      title: 'ID',
      field: 'id',
      type: ColumnType.NUMBER,
    },
    {
      title: 'Title',
      field: 'title',
      type: ColumnType.STRING,
    },
    {
      title: 'Type',
      field: 'doc_type',
      type: ColumnType.STRING,
    },
    {
      title: 'Processed',
      field: 'processed',
      type: ColumnType.BOOLEAN,
    },
    {
      title: 'Uploaded',
      field: 'uploaded',
      type: ColumnType.BOOLEAN,
    },
    {
      title: 'Created at',
      field: 'created_at',
      type: ColumnType.DATE,
    },
    {
      title: 'Created by',
      field: 'created_by',
      type: ColumnType.STRING,
    },
    {
      title: '',
      field: '',
      type: ColumnType.OBJECT,
      renderCell: (row: any) => {
        return (
          <div className="document-actions">
            <CFConfirmableButton question="Are you sure to remove this document" title="Remove document">
              <CFTrashButton onClick={() => handleDeleteDocument((row as DocAPI).id)} />
            </CFConfirmableButton>

            <CFConfirmableButton question="Are you sure to reprocess this document" title="Reprocess document">
              <CFButton
                iconName={faRefresh}
                role={CFRole.OnlyIcon}
                onClick={() => handleProcessDocument((row as DocAPI).id)}
                value={''}
                description={'Re-process'}
              />
            </CFConfirmableButton>
          </div>
        );
      },
    },
  ];

  const downloadPage = useCallback(
    async (page: number) => {
      try {
        //setIsloading(true);

        const docs = await list(page, curPageSize);

        setTotalDocs(docs.total);
        setDocs(docs.data);
      } finally {
        //setIsloading(false);
      }
    },
    [curPageSize]
  );

  const handleNewPageRequest = (page: number, size: number) => {
    setCurPage(page - 1);
    setCurPageSize(size);
  };

  useEffect(() => {
    downloadPage(curPage);
  }, [curPage]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    if (file.name.endsWith('json') && !file.name.endsWith('unstructured.pdf.json')) {
      addToast('JSON files must be unstructured PDF only', ToastType.ERROR);
      return;
    }

    setName(file.name);
  };

  return (
    <div className="upload-file-panel">
      <div className="upload-file-panel__controls">
        <form onSubmit={handleSubmit}>
          <input
            id="fileInput"
            ref={fileInputRef}
            type="file"
            accept=".pdf,.txt,application/pdf,.json"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />

          <div className="editable-file-input">
            <CFInput placeholder="pick a file" value={name} onChange={handleNameChange} />
            <CFButton onClick={handleOpenFileDialog} value={'Select file'} type="button" />

            <CFButton
              type="submit"
              role={CFRole.Primary}
              value={`Upload (${index}/${total})`}
              onClick={() => ({})}
              disabled={index !== total && total !== 0}
              isLoading={index < total}
            />
          </div>
        </form>

        <SearchEmbeddings />
      </div>
      <div className="upload-file-panel__list">
        <CFDataTable headers={headers} total={totalDocs} data={docs} onPaginated={handleNewPageRequest} />
      </div>
    </div>
  );
};

export default Upload;
