import React, { useCallback, useEffect, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import debounce from 'debounce';
import intersection from 'lodash.intersection';
import classNames from 'classnames';

import useMultiSelect from './hooks/useOptionState';

import { CFRole, Module } from 'domain/general.types';
import { AggLevel } from 'domain/stats.types';
import { TraitCategory, Trait, TraitSubject } from 'domain/traits.types';

import {
  parsePTR,
  getSourceDataType,
  getTraitCategory,
  getDataType,
  getDisplayName,
  PtrFields,
} from 'services/traits/helpers.traits';
import { WindowsValue } from 'services/traits/valueObjects/WindowsValue';

import CFButton from 'components/buttons/CFButton';
import CFTrashButton from 'components/buttons/CFTrashButton';
import CFInput from 'components/CFInput';
import CFTable, { Column, ColumnType, TableType } from 'components/CFTable';
import CFTitledComponent from 'components/CFTitledComponent';
import CFTitledSection, { SectionAction, TitleSize } from 'components/CFTitledSection';
import CloudPicker from 'components/CloudPicker';

import CFTraitItem from '../CFTraitItem';
import { TraitExplorerProps } from '.';
import { Collapsible } from 'components/CollapsibleContent';

interface ExtendedTrait extends Trait {
  parsedPtr: PtrFields;
}

export interface TraitExplorerRef {
  value: () => Trait[];
}

const TraitExplorer = forwardRef<TraitExplorerRef, TraitExplorerProps>(function TraitExplorer(
  { isMulti = false, traits, fullWidth, defaultSelectedTraits = [] },
  ref
) {
  const [filteredTraits, setFilteredTraits] = useState(traits);

  const { selectedItems: aggLevel, setItems: setAggLevel } = useMultiSelect<string>({});
  const { selectedItems: dataType, setItems: setDataType } = useMultiSelect<string>({});
  const { selectedItems: categories, setItems: setCategories } = useMultiSelect<TraitCategory>({});
  const { selectedItems: units, setItems: setUnits } = useMultiSelect<string>({});
  const { selectedItems: module, setItems: setModule } = useMultiSelect<Module>({});
  const { selectedItems: subject, setItems: setSubject } = useMultiSelect<TraitSubject>({});
  const { selectedItems: window, setItems: setWindow } = useMultiSelect<WindowsValue>({});

  const { selectedItems: selectedTraits, setItems: setSelectedTraits } = useMultiSelect<Trait>({
    isMulti,
    defaultSelected: defaultSelectedTraits,
  });

  const [search, setSearch] = useState('');

  useImperativeHandle(ref, () => ({
    value: () => selectedTraits,
  }));

  const extendedTraits = useMemo(() => {
    const parsedTraits = traits.map((trait) => ({ ...trait, parsedPtr: parsePTR(trait.addr.ptr) }));
    return parsedTraits;
  }, [traits]);

  const [availableDataTypes] = useState(() => {
    const availableTypes = traits.map((trait) => getSourceDataType(trait.addr));
    return Array.from(new Set(availableTypes));
  });

  const [availableUnits] = useState(() => {
    const allUnits = traits.map((trait) => trait.meta.unit);

    return Array.from(new Set(allUnits));
  });

  const [availableSubjects] = useState(() => {
    const allSubjects = traits.map((trait) => trait.meta.subject);
    return Array.from(new Set(allSubjects));
  });

  const [availableCategories] = useState(() => {
    const allCategories = traits.map((trait) => getTraitCategory(trait.addr));
    return Array.from(new Set(allCategories.flat().filter((item) => !!item)));
  });

  const [availableModules] = useState(() => {
    const allModules = traits.map((trait) => trait.meta.modules);

    return Array.from(new Set(allModules.flat().filter((item) => !!item)));
  });

  const availableWindows = useMemo(() => {
    const uniqueMap = new Map<string, WindowsValue>();

    extendedTraits.forEach((trait) => {
      const window = trait.parsedPtr.window;

      uniqueMap.set(window.toString(), window);
    });

    return uniqueMap;
  }, [extendedTraits]);

  const hiddenSelectedTags = useMemo(() => {
    return [
      Array.from(availableWindows.values()),
      availableModules,
      availableSubjects,
      availableUnits,
      availableDataTypes,
    ]
      .filter((item) => item.length === 1)
      .flat();
  }, [availableWindows, availableModules, availableSubjects, availableUnits, availableDataTypes]);

  useEffect(() => {
    let filteredTraits: ExtendedTrait[] = [...extendedTraits];

    if (subject.length) {
      filteredTraits = filteredTraits.filter((trait) => {
        return subject.includes(trait.parsedPtr.subject);
      });
    }

    if (aggLevel.length) {
      filteredTraits = filteredTraits.filter((trait) => {
        return aggLevel.includes(trait.parsedPtr.aggLevel);
      });
    }

    if (dataType.length) {
      filteredTraits = filteredTraits.filter((trait) => {
        return dataType.includes(getDataType(trait.addr));
      });
    }

    if (categories.length) {
      filteredTraits = filteredTraits.filter((trait) => {
        return categories.includes(trait.parsedPtr.category);
      });
    }

    if (units.length) {
      filteredTraits = filteredTraits.filter((trait) => {
        return units.includes(trait.meta.unit);
      });
    }

    if (search.length) {
      filteredTraits = filteredTraits.filter(
        (trait) =>
          getDisplayName(trait).toLowerCase().includes(search) || trait.meta.description.toLowerCase().includes(search)
      );
    }

    if (module.length) {
      filteredTraits = filteredTraits.filter((trait) => {
        return intersection(trait.meta.modules, module).length !== 0;
      });
    }

    if (window.length) {
      filteredTraits = filteredTraits.filter((trait) => {
        return window.some((window) => window.toString() === trait.parsedPtr.window.toString());
      });
    }

    setFilteredTraits(filteredTraits);
  }, [extendedTraits, aggLevel, dataType, categories, search, units, module, window, subject]);

  const handleSelectUnit = useCallback((unit: string) => {
    setUnits(unit as string);
  }, []);

  const handleSelectModule = useCallback((module: string) => {
    setModule(module);
  }, []);

  const handleSelectCategory = useCallback((category: string) => {
    setCategories(category as TraitCategory);
  }, []);

  const handleSelectAggLevelCloud = useCallback((level: string) => {
    setAggLevel(level);
  }, []);

  const handleSelectSubject = useCallback((subject: string) => {
    setSubject(subject as TraitSubject);
  }, []);

  const handleSelectType = useCallback((type: string) => {
    setDataType(type);
  }, []);

  const handleSelectWindow = useCallback((window: WindowsValue) => {
    setWindow(window);
  }, []);

  const handleSelectedTrait = useCallback(
    (row: any) => {
      setSelectedTraits(row);
    },
    [selectedTraits]
  );

  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(evt.target.value.trim().toLowerCase());
  };

  const debouncedhandleSearchChange = useCallback(debounce(handleSearchChange, 200), []);

  const headers: Column[] = [
    {
      title: 'Trait',
      type: ColumnType.STRING,
      field: 'name',
      renderCell: (row) => <CFTraitItem addr={(row as Trait).addr} showAggLevel omitDisplayName />,
    },
    {
      title: 'Name',
      type: ColumnType.STRING,
      field: '',
      renderCell: (row) => getDisplayName(row as Trait),
    },
    {
      title: 'Description',
      type: ColumnType.STRING,
      field: 'meta.description',
    },
  ];

  const selectedHeaders = useMemo(() => {
    const temporaryHeaders = [...headers];

    if (isMulti) {
      temporaryHeaders.push({
        title: '',
        type: ColumnType.OBJECT,
        field: '',
        renderCell: (row: any) => <CFTrashButton onClick={() => handleSelectedTrait(row as Trait)} />,
      });
    }

    return temporaryHeaders;
  }, [isMulti]);

  const handleClearSelection = useCallback(() => {
    setSelectedTraits(null);
  }, []);

  return (
    <div className={classNames('trait-explorer', { fullWidth })}>
      <div className="trait-explorer__controls">
        <CFTitledComponent title="Search" className="trait-explorer__controls__search">
          <CFInput placeholder="search" onChange={debouncedhandleSearchChange} />

          <CloudPicker
            tags={hiddenSelectedTags.map((value) => ({
              value: value.toString(),
              label: value.toString(),
              selected: true,
            }))}
          />
        </CFTitledComponent>

        <Collapsible nested={false}>
          <Collapsible.CollapsedHeader>
            <div>Subject</div>
            <CloudPicker
              tags={subject.map((value) => ({
                value,
                label: value,
                selected: true,
              }))}
            />
          </Collapsible.CollapsedHeader>

          <Collapsible.ExpandedHeader>Subject</Collapsible.ExpandedHeader>

          <Collapsible.Content>
            <CloudPicker
              tags={availableSubjects.map((value) => ({
                value,
                label: value,
                selected: subject.includes(value as TraitSubject),
              }))}
              onClick={handleSelectSubject}
            />
          </Collapsible.Content>
        </Collapsible>

        <Collapsible nested={false}>
          <Collapsible.CollapsedHeader>
            <div>Categories</div>
            <CloudPicker
              tags={categories.map((value) => ({
                value,
                label: value,
                selected: true,
              }))}
            />
          </Collapsible.CollapsedHeader>

          <Collapsible.ExpandedHeader>Categories</Collapsible.ExpandedHeader>

          <Collapsible.Content>
            <CloudPicker
              onClick={handleSelectCategory}
              tags={availableCategories.map((value) => ({
                value,
                label: value,
                selected: categories.includes(value),
              }))}
            />
          </Collapsible.Content>
        </Collapsible>

        <Collapsible nested={false}>
          <Collapsible.CollapsedHeader>
            <div>Aggregation level</div>
            <CloudPicker
              tags={aggLevel.map((value) => ({
                value,
                label: value,
                selected: true,
              }))}
            />
          </Collapsible.CollapsedHeader>

          <Collapsible.ExpandedHeader>Aggregation level</Collapsible.ExpandedHeader>

          <Collapsible.Content>
            <CloudPicker
              tags={Object.values(AggLevel).map((value) => ({
                value,
                label: value,
                selected: aggLevel.includes(value),
              }))}
              onClick={handleSelectAggLevelCloud}
            />
          </Collapsible.Content>
        </Collapsible>

        {Array.from(availableWindows).length > 1 && (
          <Collapsible nested={false}>
            <Collapsible.CollapsedHeader>
              <div>Monitoring window</div>
              <CloudPicker
                tags={window.map((value) => ({
                  value,
                  selected: true,
                }))}
              />
            </Collapsible.CollapsedHeader>

            <Collapsible.ExpandedHeader>Monitoring window</Collapsible.ExpandedHeader>

            <Collapsible.Content>
              <CloudPicker<WindowsValue>
                tags={Array.from(availableWindows.values()).map((value) => ({
                  value: value,
                  selected: window.find((window) => window.toString() === value.toString()) !== undefined,
                }))}
                onClick={handleSelectWindow}
              />
            </Collapsible.Content>
          </Collapsible>
        )}

        <Collapsible nested={false}>
          <Collapsible.CollapsedHeader>
            <div>Data type</div>
            <CloudPicker
              tags={dataType.map((value) => ({
                value,
                label: value,
                selected: true,
              }))}
            />
          </Collapsible.CollapsedHeader>

          <Collapsible.ExpandedHeader>Data type</Collapsible.ExpandedHeader>

          <Collapsible.Content>
            <CloudPicker
              tags={availableDataTypes.map((value) => ({
                value,
                label: value,
                selected: dataType.includes(value),
              }))}
              onClick={handleSelectType}
            />
          </Collapsible.Content>
        </Collapsible>

        <Collapsible nested={false}>
          <Collapsible.CollapsedHeader>
            <div>Unit</div>
            <CloudPicker
              tags={units.map((value) => ({
                value,
                label: value,
                selected: true,
              }))}
            />
          </Collapsible.CollapsedHeader>

          <Collapsible.ExpandedHeader>Unit</Collapsible.ExpandedHeader>

          <Collapsible.Content>
            <CloudPicker
              onClick={handleSelectUnit}
              tags={availableUnits.map((value) => ({
                value,
                label: value || '<empty>',
                selected: units.includes(value),
              }))}
            />
          </Collapsible.Content>
        </Collapsible>

        <Collapsible nested={false}>
          <Collapsible.CollapsedHeader>
            <div>Module</div>
            <CloudPicker
              tags={module.map((value) => ({
                value,
                label: value,
                selected: true,
              }))}
            />
          </Collapsible.CollapsedHeader>

          <Collapsible.ExpandedHeader>Module</Collapsible.ExpandedHeader>

          <Collapsible.Content>
            <CloudPicker
              onClick={handleSelectModule}
              tags={availableModules.map((value) => ({
                value,
                label: value,
                selected: module.includes(value),
              }))}
            />
          </Collapsible.Content>
        </Collapsible>
      </div>

      <CFTitledSection
        title={`Selected traits (${selectedTraits.length})`}
        className="trait-explorer__selected"
        size={TitleSize.Small}
        nested
      >
        <SectionAction>
          <CFButton role={CFRole.Borderless} value={'Clear'} onClick={handleClearSelection} />
        </SectionAction>

        <CFTable
          headers={selectedHeaders}
          data={selectedTraits}
          emptyLabel="No Selected Traits"
          skipHeader={true}
          variant={TableType.Secondary}
          // onSelectRow={handleSelectedTrait}
        />
      </CFTitledSection>

      <CFTitledComponent
        title={`Available traits (${filteredTraits.length} / ${traits.length})`}
        className="trait-explorer__data"
      >
        <CFTable
          headers={headers}
          data={filteredTraits}
          emptyLabel="No Available Traits"
          skipHeader={true}
          onSelectRow={handleSelectedTrait}
        />
      </CFTitledComponent>
    </div>
  );
});

export default TraitExplorer;
