import React from 'react';

import { Comparable } from 'types';

import CFChip, { CFChipRole, CFChipSize } from 'components/chips/CFChip';

import './cloud-picker.scss';

export interface CloudOption<T extends Comparable> {
  label?: string;
  value: T;
  selected: boolean;
}

interface Props<T extends Comparable> {
  tags: CloudOption<T>[];
  onClick?: (value: T) => void;
}

const CloudPicker = <T extends Comparable>({ onClick, tags }: Props<T>) => {
  return (
    <div className="cloud-picker">
      {tags
        .sort((a, b) => a.value.localeCompare(b.value))
        .map((tag) => (
          <CFChip
            key={tag.value.toString()}
            value={tag.label ?? tag.value.toString()}
            removable={false}
            size={CFChipSize.Small}
            role={tag.selected ? CFChipRole.Primary : CFChipRole.Secondary}
            onSelect={() => onClick?.(tag.value)}
          />
        ))}
    </div>
  );
};

export default CloudPicker;
