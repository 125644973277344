import { RGBColor, TimeRFC3999 } from './general.types';

export interface InputStatItem {
  log_type: string;
  cnt: number;
}

export interface StatItem {
  count: number;
  type: string;
}

export interface ExceptionListObject {
  title: string;
  event_type: string;
  exception_type: string;
  exception_source: string;
  total_cnt: string;
  latest: TimeRFC3999;
  recent_crashes: ExceptionObject[];
}

export interface ExceptionObject {
  id: number;
  u_id: string;
  d_info: DeviceInfo;
  app_info: AppInfo;
  ts: TimeRFC3999;
  title: string;
  event_type: string;
  exception_type: string;
  exception_source: string;
  stack_trace: string;
}

export interface DeviceInfo {
  id: string;
  brand: string;
  model: string;
  os: string;
  os_ver: string;
}

export interface AppInfo {
  id: string;
  min_sdk_version: number;
  target_sdk_version: number;
  version: string;
  version_code: number;
  version_name: string;
}

export enum AggLevel {
  NA = 'n/a',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export enum AggLevelPtr {
  Day = 'd',
  Week = 'w',
  Month = 'm',
}

export const AggLevelMapping = {
  [AggLevelPtr.Day]: AggLevel.Day,
  [AggLevelPtr.Week]: AggLevel.Week,
  [AggLevelPtr.Month]: AggLevel.Month,
};

export const AggLevelInverseMapping: Partial<Record<AggLevel, AggLevelPtr>> = {
  [AggLevel.Day]: AggLevelPtr.Day,
  [AggLevel.Week]: AggLevelPtr.Week,
  [AggLevel.Month]: AggLevelPtr.Month,
};

export interface InputTimeSeriesItem {
  t: TimeRFC3999;
  v: number;
}

export interface TimeSeriesItem {
  time: TimeRFC3999;
  value: number;
}

export interface ValueSeriesItem {
  x: number;
  value: number;
}

export type SeriesItem = TimeSeriesItem | ValueSeriesItem;

export interface Series<T extends SeriesItem = TimeSeriesItem> {
  items: T[];
  name: string;
  color?: RGBColor;
}
